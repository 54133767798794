import { FormError, TextField } from "@davo/portal-common";
import {
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
} from "@davo/types";
import { Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useMerchantPortalConfigContext } from "../context";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function HeartlandRetailBoarding() {
    const classes = useStyles();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<"initial" | "complete">("initial");
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();

    useEffect(() => {
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    const doCall = () => {
        if (email && phone && isEmailValid(email) && isPhoneValid(phone)) {
            setMessage(undefined);
            setStatus("complete");
            const passThrough = Buffer.from(`{"email": "${email}", "phone": "${phone}"}`).toString("base64");
            window.location.replace(
                `${configInfo.heartlandRetailBaseURL}?client_id=${configInfo.heartlandRetailClientId}${encodeURI(
                    `&redirect_uri=${configInfo.heartlandRetailRedirectURL}&scope=location.read payment.read customer.read sales.ticket.manage sales.tax.manage sales.invoice.manage&state=${passThrough}`
                )}`
            );
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"heartland-retail"}>
            {status === "initial" && (
                <>
                    <TextField
                        label="Phone Number"
                        inputProps={{
                            [`data-testid`]: "phoneInput",
                        }}
                        value={phone ?? ""}
                        onChange={setPhone}
                        validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                        onEnterPress={doCall}
                        isRequired
                        margin={"dense"}
                    />
                    <TextField
                        label="Email"
                        inputProps={{
                            [`data-testid`]: "emailInput",
                        }}
                        value={email ?? ""}
                        validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                        onChange={setEmail}
                        onEnterPress={doCall}
                        isRequired
                        margin={"dense"}
                    />
                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            type="submit"
                            data-testid={"submitBtn"}
                            variant="contained"
                            color="primary"
                            onClick={doCall}
                            disabled={!email || !phone}>
                            Connect with Heartland Retail
                        </Button>
                    </div>
                </>
            )}
        </BoardingContainer>
    );
}
