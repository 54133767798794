import { TaxProfile } from "@davo/types";
import Chip from "@mui/material/Chip";
import isEmpty from "lodash/isEmpty";
import React from "react";

export interface ISettingsSingleLocationTaxProfileContentProps {
    taxProfiles?: TaxProfile[];
    sortedTaxProfileStates?: string[];
}

export const SettingsSingleLocationTaxProfileCardContent: React.FC<ISettingsSingleLocationTaxProfileContentProps> = ({
    taxProfiles,
    sortedTaxProfileStates,
}: ISettingsSingleLocationTaxProfileContentProps) => {
    if (isEmpty(taxProfiles)) {
        return null;
    }
    return (
        <>
            <div className={"da-finance-item"}>
                <strong>Tax Profiles:</strong>
                {taxProfiles!.map((tp: TaxProfile) => (
                    <Chip style={{ margin: "3px" }} label={tp.name} key={tp.id} variant="outlined" size={"small"} />
                ))}
            </div>
            <div className={"da-finance-item"}>
                <strong>Tax Profile States:</strong>
                {sortedTaxProfileStates ? sortedTaxProfileStates.join(", ") : "None"}
            </div>
        </>
    );
};
