import { d30ToastError, FormError, getPublicJurisdictions, Select, TextField } from "@davo/portal-common";
import {
    IJurisdiction,
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
    validateZip,
} from "@davo/types";
import { Box, Button, Theme, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useMerchantPortalConfigContext } from "../context";
import { testSpotOnRestaurantCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { SpotOnRestaurantPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function SpotOnRestaurantBoarding() {
    const classes = useStyles();
    const [spotOnLocId, setSpotOnLocId] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<"initial" | "complete">("initial");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [address1, setAddress1] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [state, setState] = useState<IJurisdiction>();
    const [selectedStateOption, setSelectedStateOption] = useState<string>();
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [zip, setZip] = useState<string | undefined>(undefined);
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const completeFormRef = useRef<HTMLFormElement>();

    useAsyncEffect(async () => {
        setStates(await getPublicJurisdictions());
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    useEffect(() => {
        const selected = states.find((ba: IJurisdiction) => ba.abbreviatedName === selectedStateOption);
        setState(selected);
    }, [states, state, selectedStateOption]);

    const doValidate = (event?: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (isBusy) {
            return;
        }

        if (spotOnLocId) {
            let locationIdVal = spotOnLocId.trim().replace("location", "Location").replace(" ", "");
            if (!locationIdVal.includes("Location")) {
                locationIdVal = `Location${locationIdVal}`;
            }
            setSpotOnLocId(locationIdVal);
            setIsBusy(true);
            testSpotOnRestaurantCredentials(locationIdVal)
                .then((result) => {
                    setIsBusy(false);
                    if (result.success.valid) {
                        setStatus("complete");
                        setMessage(undefined);
                    } else {
                        setMessage(
                            "We do not yet have access to this SpotOn location ID.  Please email SpotOn to request activation for DAVO."
                        );
                    }
                })
                .catch((e) => {
                    d30ToastError("Problem testing credentials.", e);
                    setIsBusy(false);
                });
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    const doNext = (event?: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (isBusy) {
            return;
        }

        if (
            spotOnLocId &&
            address1 &&
            city &&
            state &&
            zip &&
            email &&
            phone &&
            isEmailValid(email) &&
            isPhoneValid(phone)
        ) {
            setIsBusy(true);
            const posData: SpotOnRestaurantPosInfo = {
                spotOnLocId,
                phone,
                email,
                address1,
                city,
                state: state.abbreviatedName,
                zip,
            };

            navigate(configInfo.spotOnRestaurantInvitationURL, {
                state: posData,
            });
        } else {
            setMessage("Please fill out required fields.");
            completeFormRef.current?.reportValidity();
        }
    };

    return (
        <BoardingContainer posType={"spoton-restaurant"}>
            {status === "complete" && (
                <Box
                    component={"form"}
                    onSubmit={doNext}
                    noValidate
                    autoComplete="off"
                    data-testid={"completeStep"}
                    ref={completeFormRef}
                    style={{ justifyContent: "stretch" }}>
                    <div style={{ paddingBottom: "10px" }}>Tell us a little about your business:</div>
                    <TextField
                        label="Phone"
                        validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                        value={phone ?? ""}
                        onChange={setPhone}
                        onEnterPress={doNext}
                        margin="dense"
                        isRequired
                        data-testid={"phoneField"}
                        inputProps={{
                            [`data-testid`]: "phoneInput",
                        }}
                    />
                    <TextField
                        label="Email"
                        validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                        value={email ?? ""}
                        onChange={setEmail}
                        onEnterPress={doNext}
                        margin="dense"
                        isRequired
                        data-testid={"emailField"}
                        inputProps={{
                            [`data-testid`]: "emailInput",
                        }}
                    />
                    <TextField
                        label="Address"
                        value={address1 ?? ""}
                        onChange={setAddress1}
                        onEnterPress={doNext}
                        margin="dense"
                        isRequired
                        validate={validateNotNull}
                        data-testid={"address1Field"}
                        inputProps={{
                            [`data-testid`]: "address1Input",
                        }}
                    />
                    <TextField
                        label="City"
                        value={city ?? ""}
                        onChange={setCity}
                        onEnterPress={doNext}
                        margin="dense"
                        isRequired
                        validate={validateNotNull}
                        data-testid={"cityField"}
                        inputProps={{
                            [`data-testid`]: "cityInput",
                        }}
                    />
                    <Select<IJurisdiction>
                        title="State"
                        options={states}
                        value={state}
                        label={(jurisdiction) => jurisdiction.fullName}
                        onChange={(val) => val && setSelectedStateOption(val.abbreviatedName)}
                        isRequired
                        margin="dense"
                        validate={(v) => (v ? undefined : validateNotNull(null))}
                        data-testid={"stateField"}
                        inputProps={{
                            [`data-testid`]: "stateInput",
                        }}
                    />
                    <TextField
                        label="ZIP"
                        validate={(v) => (v ? validateZip(v) : validateNotNull(null))}
                        value={zip ?? ""}
                        onChange={setZip}
                        onEnterPress={doNext}
                        margin="dense"
                        isRequired
                        data-testid={"zipField"}
                        inputProps={{
                            [`data-testid`]: "zipInput",
                        }}
                    />
                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            type="submit"
                            data-testid={"completeSubmit"}
                            variant="contained"
                            color="primary"
                            disabled={
                                !spotOnLocId ||
                                !address1 ||
                                !city ||
                                !state ||
                                !zip ||
                                !email ||
                                !phone ||
                                !isEmailValid(email) ||
                                !isPhoneValid(phone)
                            }
                            style={{ margin: "20px" }}>
                            Connect with SpotOn Restaurant
                        </Button>
                    </div>
                </Box>
            )}
            {status === "initial" && (
                <Box
                    component={"form"}
                    onSubmit={doValidate}
                    noValidate
                    style={{ justifyContent: "stretch" }}
                    data-testid={"initialStep"}>
                    <Typography gutterBottom>
                        Enter the Location ID provided to you via email. If you have not received a Location ID, email{" "}
                        <a href="mailto:retailteam@spoton.com">retailteam@spoton.com</a>.
                    </Typography>

                    <TextField
                        data-testid={"locationIdContainer"}
                        inputProps={{
                            [`data-testid`]: "locationIdInput",
                        }}
                        label="Location Id"
                        value={spotOnLocId ?? ""}
                        onChange={setSpotOnLocId}
                        onEnterPress={doValidate}
                        isDisabled={isBusy}
                        isRequired
                        validate={validateNotNull}
                    />

                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            data-testid={"submitBtn"}
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ margin: "20px" }}
                            disabled={!spotOnLocId || !spotOnLocId.trim() || isBusy}
                            startIcon={
                                isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            }>
                            Continue with SpotOn Restaurant
                        </Button>
                    </div>
                </Box>
            )}
        </BoardingContainer>
    );
}
