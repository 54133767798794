import { Header, POSLogo, ReactTable8 } from "@davo/portal-common";
import { LocationRecord, POSType } from "@davo/types";
import { Button } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MerchantConnectionStatus } from "./components";
import { ActionsRequiredMessage } from "./components/ActionsRequiredMessage";
import { useAccountContext } from "./context";
import { EditCompanyDetailsButton } from "./EditCompanyDetailsButton";

export function Company() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();

    const [activeLocations, setActiveLocations] = useState<LocationRecord[]>([]);
    const [areActionsRequired, setAreActionsRequired] = useState<boolean>();
    const [uniquePOSType, setUniquePOSType] = useState<POSType>();

    // NOTE: extract /:accountId/settings/company and remove suffix
    const url = useLocation().pathname.split("/").slice(0, 4).join("/");

    useEffect(() => {
        if (isNil(accountContext.locations)) {
            return;
        }
        const locs = orderBy(
            accountContext.locations.filter((x) => x.active),
            "name"
        );
        setActiveLocations(locs);

        const uniqPOSTypes: POSType[] = uniq(locs.map((i: LocationRecord) => i.posType));
        if (uniqPOSTypes.length > 1) {
            setUniquePOSType(uniqPOSTypes[0]);
        }
    }, [accountContext.locations]);

    useEffect(() => {
        if (isNil(accountContext.locationsNotFullyConnected)) {
            return;
        }
        if (accountContext.locationsNotFullyConnected.size > 0) {
            setAreActionsRequired(true);
        } else {
            setAreActionsRequired(false);
        }
    }, [accountContext.locationsNotFullyConnected]);

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<LocationRecord>();

        return [
            columnHelper.accessor("name", {
                header: "Name",
            }),
            columnHelper.accessor("id", {
                header: "Status",
                id: "status",
                cell: (data) => <MerchantConnectionStatus locationId={data.getValue()} />,
            }),
            columnHelper.accessor("city", {
                header: "City",
            }),
            columnHelper.accessor("state", {
                header: "State",
            }),
            columnHelper.accessor("id", {
                header: "",
                id: "view",
                cell: (data) => (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`${url}/${data.getValue()}`)}>
                        View
                    </Button>
                ),
                enableSorting: false,
            }),
        ];
    }, [navigate, url]);

    if (isNil(activeLocations) || isNil(areActionsRequired)) {
        return null;
    }

    return (
        <>
            <Header>
                <span style={{ display: "inline", fontSize: "20px", marginBottom: "10px" }}>Company Details</span>
                <span style={{ margin: "0 14px" }}>|</span>
                {`${accountContext.account ? accountContext.account.name : ""}`}
                {!isNil(uniquePOSType) && <POSLogo posType={uniquePOSType} width={125} shouldFloatRight={true} />}
            </Header>

            <div style={{ paddingBottom: "15px" }}>
                <EditCompanyDetailsButton
                    callback={async () => {
                        await accountContext.refresh();
                    }}
                />
            </div>

            <ActionsRequiredMessage showBanner={areActionsRequired} />

            <div className="fs-exclude" style={{ paddingTop: "20px" }}>
                <Header>Locations</Header>
                <ReactTable8<LocationRecord>
                    columns={columns}
                    data={activeLocations}
                    options={{
                        pageSize: 100,
                        pageSizeOptions: [100],
                        hideShowAll: true,
                    }}
                />
            </div>
        </>
    );
}
