import {
    Loading,
    ReactTable8,
    ViewBillingInvoiceModal,
    d30ToastError,
    getBillingsForAccount,
} from "@davo/portal-common";
import { IBillingInfo, LocationRecord, initialCap, money, subscriptionManagedByPOS } from "@davo/types";
import { Typography } from "@mui/material";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AccountSubscriptionsModal } from "./components/AccountSubscriptionsModal";
import { useAccountContext } from "./context";
import { SettingsBillingEmptyState } from "./SettingsBillingEmptyState";

const colors = {
    pending: "#FFAB2B",
    posted: "#FFAB2B",
    settling: "#6DD230",
    funded: "#6DD230",
    settled: "#6DD230",
    rejected: "#FE4D97",
    voided: "#FE4D97",
    returned: "#FF4D97",
} as { [key: string]: string };

const amountFormat = (data: CellContext<IBillingInfo, any>) => {
    const factor = data.cell.row.original.isRefunded ? -1 : 1;
    const totalAmount = +data.cell.row.original.amount * factor + +data.cell.row.original.totalSalesTax;
    return totalAmount ? money(totalAmount / 100) : "0.00";
};

const renderStatus = (data: CellContext<IBillingInfo, any>) => {
    let s = data.cell.row.original.achStatus as string;
    if (!data.cell.row.original.achStatus) {
        s = "";
    }
    if (data.cell.row.original.isRefunded) {
        s = "refunded";
    }
    return (
        <div
            style={{
                borderRadius: "4px",
                fontWeight: "bold",
                color: s && colors[s.toLowerCase()],
            }}>
            {s && initialCap(s)}
        </div>
    );
};

export function Billing() {
    const accountContext = useAccountContext();
    const [billings, setBillings] = useState<IBillingInfo[]>();

    useEffect(() => {
        if (!accountContext.account) {
            return;
        }
        getBillingsForAccount(accountContext.account.id)
            .then((data) => {
                setBillings(data);
            })
            .catch((e) => {
                d30ToastError("Unable to load data", e);
            });
    }, [accountContext.account]);

    const isExternalBilling = useCallback((): boolean => {
        if (isNil(accountContext.locations)) {
            return false;
        }
        return (
            !isNil(accountContext.account?.partnerId) ||
            accountContext.locations.some((x: LocationRecord) => {
                if (x.active) {
                    return subscriptionManagedByPOS.includes(x.posType);
                }
                return false;
            })
        );
    }, [accountContext.account?.partnerId, accountContext.locations]);

    const columns = useMemo(() => {
        const renderActions = (data: CellContext<IBillingInfo, any>) => {
            return <ViewBillingInvoiceModal info={data.cell.row.original} accountId={accountContext.account?.id} />;
        };

        const columnHelper = createColumnHelper<IBillingInfo>();

        return [
            columnHelper.accessor("period", {
                header: "Period",
            }),
            columnHelper.accessor("bank", {
                header: "Bank Account",
                enableSorting: false,
                cell: (data) => {
                    const { bank } = data.cell.row.original;
                    return <div style={{ textAlign: "left" }}>{bank ? <span>{bank}</span> : ""}</div>;
                },
            }),
            columnHelper.accessor("locationName", {
                header: "Location",
                id: "locationName",
            }),
            columnHelper.accessor("amount", {
                header: "Amount",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{amountFormat(data)}</div>,
            }),
            columnHelper.accessor("achStatus", {
                header: "Status",
                id: "status",
                cell: renderStatus,
            }),
            columnHelper.accessor("achStatus", {
                header: "Actions",
                id: "actions",
                cell: renderActions,
            }),
        ];
    }, [accountContext.account?.id]);

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active))
    ) {
        return null;
    }

    return (
        <>
            <Typography variant={"h2"} style={{ display: "inline-block", fontSize: "20px", marginBottom: "15px" }}>
                Billing Details
            </Typography>
            <AccountSubscriptionsModal accountId={accountContext.account.id} />
            {!billings && <Loading />}
            {billings && billings.length > 0 && (
                <div style={{ paddingTop: "20px" }}>
                    <ReactTable8<IBillingInfo>
                        columns={columns}
                        data={billings}
                        options={{
                            hideToolbar: true,
                        }}
                    />
                </div>
            )}
            {!billings && <SettingsBillingEmptyState externalBilling={isExternalBilling()} billings={billings} />}
        </>
    );
}
