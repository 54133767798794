import { qbReconnectBtn, TaxProfileEdit, useLoginContext, useModalEditor } from "@davo/portal-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { LocationInfo } from "./components";
import { ActionsRequiredMessage } from "./components/ActionsRequiredMessage";
import {
    SettingsSingleLocationBankAccountCard,
    SettingsSingleLocationHeader,
    SettingsSingleLocationTaxProfileCard,
} from "./components/SettingsSingleLocation";
import { useAccountContext, useLocationContext, useMerchantPortalConfigContext } from "./context";
import { disconnectQuickbooksLocation } from "./services/account";
import "./SettingsSingleLocation.css";

export const SettingsSingleLocation = () => {
    const navigate = useNavigate();

    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();
    // NOTE: extract /:accountId/settings/company and remove suffix
    const url = useLocation().pathname.split("/").slice(0, 4).join("/");
    const { locationId } = useParams();

    const [areActionsRequired, setAreActionsRequired] = useState<boolean>(false);
    const [restrictedMsg, setRestrictedMsg] = useState<string>("Our partner manages your banking information");
    const [isRestrictedBtnDisabled, setIsRestrictedBtnDisabled] = useState<boolean>(true);
    const [sortedTaxProfileStates, setSortedTaxProfileStates] = useState<string[]>();

    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>(async () => {
        await accountContext.refresh();
        await locationContext.refresh();
    });

    useAsyncEffect(async () => {
        if (locationId && locationId !== locationContext.location?.id) {
            await locationContext.switch(locationId);
        }
    }, [locationId, locationContext.location]);

    useAsyncEffect(async () => {
        if (
            locationContext.location &&
            accountContext.locationsNotFullyConnected &&
            accountContext.locationsNotFullyConnected.has(locationContext.location.id)
        ) {
            setAreActionsRequired(true);
        } else {
            setAreActionsRequired(false);
        }
    }, [locationContext.location, accountContext.locationsNotFullyConnected]);

    useEffect(() => {
        if (loginContext.user && loginContext.user.type !== "Merchant") {
            setRestrictedMsg("Merchant is restricted from editing this information");
            setIsRestrictedBtnDisabled(false);
        }
    }, [loginContext.user]);

    useEffect(() => {
        if (locationContext.location?.taxProfiles.length) {
            const tpStates = locationContext.location?.taxProfiles.map((tp) => tp.state);
            tpStates.sort();
            const uniqueStates = [...new Set(tpStates)];
            setSortedTaxProfileStates(uniqueStates);
        }
    }, [locationContext.location?.taxProfiles]);

    if (!accountContext.account) {
        return null;
    }

    if (!(locationContext.location && accountContext.locations && accountContext.locations.filter((l) => l.active))) {
        return null;
    }

    const locationUrl = `${url}/${locationContext.location.id}`;

    const showTaxProfileEditHandler = () => {
        showTaxProfileEdit([locationContext.location!.taxProfiles[0].id]);
    };

    const navigateToTaxProfilePageHandler = () => {
        navigate(`${locationUrl}/tax`);
    };

    const doDisconnect = async () => {
        if (!accountContext.account || !locationContext.location) {
            return;
        }
        await disconnectQuickbooksLocation(accountContext.account.id, locationContext.location.id);
    };

    return (
        <>
            <SettingsSingleLocationHeader location={locationContext.location} />

            <LocationInfo />

            <ActionsRequiredMessage showBanner={areActionsRequired} />

            <Typography style={{ fontSize: "20px", margin: "20px 0" }}>Banking and Tax</Typography>

            <Grid className={"financial-settings-container"} spacing={{ xs: 2, md: 3 }} container>
                <Grid item xs={12} md={6}>
                    <SettingsSingleLocationBankAccountCard
                        accountId={accountContext.account.id}
                        bankAccount={locationContext.location.bankAccount}
                        restrictBankInfo={{
                            restrictedMsg,
                            isRestrictedBtnDisabled,
                            restrictEditBankInfo: accountContext.partner?.restrictEditBankInfo,
                        }}
                        locationUrl={locationUrl}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <SettingsSingleLocationTaxProfileCard
                        taxProfiles={locationContext.location.taxProfiles}
                        sortedTaxProfileStates={sortedTaxProfileStates}
                        hasBankAccount={!!locationContext.location.bankAccount}
                        showTaxProfileEdit={showTaxProfileEditHandler}
                        navigateToTaxProfilePage={navigateToTaxProfilePageHandler}
                    />
                </Grid>
            </Grid>

            {locationContext.location.posType === "quickbooks" && (
                <>
                    <Accordion style={{ border: "none", boxShadow: "none" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                            More Info
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexFlow: "column" }}>
                            {!locationContext.location.disconnected && (
                                <Button
                                    variant="outlined"
                                    onClick={doDisconnect}
                                    size="small"
                                    color="primary"
                                    style={{ width: "225px", display: "block", margin: "0 auto" }}>
                                    Disconnect Quickbooks
                                </Button>
                            )}
                            {locationContext.location.disconnected && (
                                <>
                                    <div style={{ display: "flex", flexFlow: "column", textAlign: "center" }}>
                                        <Typography>
                                            {`Disconnected: ${locationContext.location.disconnected.toFormat(
                                                "dd MMM yyyy"
                                            )}`}
                                        </Typography>
                                        <Typography>
                                            We will be in contact to understand what to do with any tax we are currently
                                            holding. Click below to reconnect.
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "block",
                                            margin: "0 auto",
                                            marginTop: "20px",
                                        }}>
                                        <a
                                            href={configInfo.quickBooksConnectLink}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img src={qbReconnectBtn} alt="QuickBooks reconnect button" />
                                        </a>
                                    </div>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </>
            )}

            <Outlet />
            {taxProfileEditProps.isDialogOpen && (
                <TaxProfileEdit accountId={accountContext.account.id} {...taxProfileEditProps} />
            )}
        </>
    );
};
