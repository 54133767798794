import { getBankAccountNachaRestricted, getLocationsEnhanced } from "@davo/portal-common";
import { BankAccount, ConnectionStatusLevelMessageOverride, Location, LocationRecord, TaxProfile } from "@davo/types";

export const hasPermission = (userPermissions: string[] | undefined, neededPermission: string) => {
    return !!userPermissions?.includes(neededPermission);
};

export const determineLocationsNotFullyConnected = async (accountId?: string) => {
    const locationsNotFullyConnectedList: string[] = [];
    if (accountId) {
        // we should try and eliminate this expensive query...
        const lList = await getLocationsEnhanced(accountId);
        const activeLocationsForFunding = lList.filter((l: LocationRecord) => l.active && !l.disconnected);

        const badgeCriteria = async (l: Location) => {
            const doesAtLeastOneTaxProfileNeedActiveBank = !l.taxProfiles.every(
                (x: TaxProfile) => x.filingMethod === "file-only" || x.filingMethod === "merchant-pay"
            );
            const hasBankNeedingAttention =
                !l.bankAccount || (l.bankAccount && l.bankAccount.paused && !l.bankAccount.unpauseDate);
            const hasMissingTaxProfile = l.taxProfileIds.length === 0;
            const hasTaxProfilesNeedingAttention = l.taxProfiles.some(
                (tp: TaxProfile) => ["failing"].includes(tp.credentialsFailing) && tp.filingMethod !== "take-give-back"
            );

            let hasRestrictedBankAccounts = false;
            if (l.bankAccountId) {
                const restriction = await getBankAccountNachaRestricted(accountId, l.bankAccountId);
                hasRestrictedBankAccounts = restriction.isNachaRestricted;
            }

            if (
                (doesAtLeastOneTaxProfileNeedActiveBank && hasBankNeedingAttention) ||
                hasMissingTaxProfile ||
                hasTaxProfilesNeedingAttention ||
                hasRestrictedBankAccounts
            ) {
                locationsNotFullyConnectedList.push(l.id);
            }
        };

        await Promise.all(activeLocationsForFunding.map((location) => badgeCriteria(location)));
    }
    return new Set<string>(locationsNotFullyConnectedList);
};

export const formatBankLabel = ({ ba, withStarredPrefix }: { ba: BankAccount; withStarredPrefix: boolean }) => {
    const prefLabel = `${ba.nickname ? ba.nickname + " - " : ""}${ba.name}`;
    return withStarredPrefix ? `${ba.accountStarred} - ${prefLabel}` : `${prefLabel}`;
};

export const defaultStatusLevelMessageMerchantOverride: ConnectionStatusLevelMessageOverride = {
    hasUntestedTaxCredentials: {
        statusLevel: "ok",
    },
};
