import { TaxProfile } from "@davo/types";
import { Button, Card, CardActions, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { TaxProfileStatus } from "../index";
import { SettingsSingleLocationTaxProfileCardContent } from "./SettingsSingleLocationTaxProfileCardContent";

interface ISettingsSingleLocationTaxProfileCardProps {
    taxProfiles?: TaxProfile[];
    sortedTaxProfileStates: string[] | undefined;
    hasBankAccount: boolean;
    showTaxProfileEdit: () => void;
    navigateToTaxProfilePage: () => void;
}

export const SettingsSingleLocationTaxProfileCard: React.FC<ISettingsSingleLocationTaxProfileCardProps> = ({
    taxProfiles,
    sortedTaxProfileStates,
    hasBankAccount,
    navigateToTaxProfilePage,
    showTaxProfileEdit,
}) => {
    const taxProfileStatus = taxProfiles
        ? taxProfiles?.find((tp) => !isEmpty(tp.failingReason)) || taxProfiles[0]
        : undefined;

    return (
        <Card variant={"outlined"}>
            <CardHeader
                title={
                    <div className={"da-settings-header-content"}>
                        <Typography variant={"h4"} style={{ fontSize: "16px", display: "inline-block" }}>
                            Tax Profile
                        </Typography>
                        <div
                            style={{
                                position: "relative",
                                left: "-10px",
                            }}>
                            <TaxProfileStatus taxProfile={taxProfileStatus} />
                        </div>
                    </div>
                }
            />

            <CardContent>
                <SettingsSingleLocationTaxProfileCardContent
                    taxProfiles={taxProfiles}
                    sortedTaxProfileStates={sortedTaxProfileStates}
                />
            </CardContent>
            <div style={{ flexGrow: "1" }} />
            <CardActions>
                {taxProfiles?.length === 1 && (
                    <Button
                        data-testid={"edit-tax-profile-button"}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={showTaxProfileEdit}
                        style={{ margin: "0 6px" }}>
                        {"Edit"}
                    </Button>
                )}

                <Tooltip
                    title={
                        isEmpty(taxProfiles) && !hasBankAccount
                            ? "Please add a bank account before adding a tax profile"
                            : ""
                    }
                    placement="top">
                    <span>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={navigateToTaxProfilePage}
                            disabled={isEmpty(taxProfiles) && !hasBankAccount}>
                            Select Tax Profiles
                        </Button>
                    </span>
                </Tooltip>
            </CardActions>
        </Card>
    );
};
