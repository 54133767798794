import { Paper } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";

export function Settings() {
    return (
        <Paper style={{ boxShadow: "none", padding: "30px 30px 44px", minHeight: "80vh" }} data-testid="settings">
            <Outlet />
        </Paper>
    );
}
