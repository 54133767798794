import { Loading, useLoginContext } from "@davo/portal-common";
import { IOnboardingResult, IStorageMarketingInfo, POSType } from "@davo/types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAccountContext, useMerchantPortalConfigContext } from "../context";
import { boardLocation } from "../services/boarding";
import { BoardingContainer } from "./BoardingContainer";
import { Unavailable } from "./flow/Unavailable";
import { checkErrors, formatPosInfo } from "./LoadInvitationData";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function LoadInvitation({ posType, body }: { posType: POSType; body?: any }) {
    const query = useQuery();
    const navigate = useNavigate();
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [status, setStatus] = useState<"initial" | "creating" | "error">("initial");
    const [errorMessage, setErrorMessage] = useState<string>(
        "Please connect DAVO to your point of sale device again to kick the process off anew."
    );
    let posInfo: any = {};

    useEffect(() => {
        console.debug(status); // eslint-disable-line no-console
    }, [status]);

    useAsyncEffect(async () => {
        if (posType && status === "initial") {
            const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(
                localStorage.getItem("marketingInfo") ?? "{}"
            ); // note this key is left in storage for now
            setStatus("creating");
            try {
                const oauthCode = query.get("code");
                posInfo = formatPosInfo(posType, query, body);
                const posErrorMessage = checkErrors(posType, query);
                if (posErrorMessage) {
                    setStatus("error");
                    setErrorMessage(posErrorMessage);
                } else {
                    // For now we will assume anything coming through here is NOT from device
                    posInfo.fromDevice = false;
                    posInfo.allParams = query.toString();

                    const boardingInfo: IOnboardingResult = await boardLocation(
                        { oauthCode, posInfo, storageMarketingInfo },
                        posType
                    );
                    loginContext.setUser(boardingInfo.loginUser);
                    await accountContext.switch(undefined);

                    if (boardingInfo.error === "Already claimed") {
                        navigate(`/`);
                    } else if (boardingInfo.error === "Clover subscription out of date") {
                        navigate(`/legacy`);
                    } else if (boardingInfo.error === "Poynt: no funding source") {
                        setErrorMessage("You must add an app funding source to your Poynt account to install DAVO.");
                        setStatus("error");
                    } else if (boardingInfo.error === "Poynt: merchant uses transactions not orders") {
                        setErrorMessage(
                            `Sorry but we cannot board your account because your business uses Poynt's transactions rather than orders.
                            Unlike orders, Poynt transactions do not break out the taxes and tax rates in a manner that allows us to determine tax liability.
                            For more information: https://www.godaddy.com/help/create-an-order-on-a-godaddy-smart-terminal-40756`
                        );
                        setStatus("error");
                    } else if (boardingInfo.error) {
                        setStatus("error");
                    } else if (boardingInfo.demo) {
                        window.location.replace(configInfo.demoVideo);
                    } else if (boardingInfo.invitationId) {
                        if (boardingInfo.isReconnect) {
                            navigate(`/reconnect/location/${boardingInfo.invitationId}`);
                        } else if (boardingInfo.isPreAccount) {
                            navigate(`/pre-account/location/${boardingInfo.invitationId}`);
                        } else {
                            navigate(`/invitation/location/${boardingInfo.invitationId}`);
                        }
                    } else {
                        navigate(`/`);
                    }
                }
            } catch (e: any) {
                setStatus("error");
            }
        }
    }, [status, posType]);

    return (
        <>
            <BoardingContainer posType={posType}>
                {(status === "initial" || status === "creating") && (
                    <span style={{ marginTop: "40px" }}>
                        <Loading />
                    </span>
                )}
                {status === "error" && (
                    <Unavailable onContinue={() => navigate(`/boarding/${posType}`)} errorMessage={errorMessage} />
                )}
            </BoardingContainer>
        </>
    );
}
