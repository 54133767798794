import { FormError, TextField } from "@davo/portal-common";
import { isEmailValid, IStorageMarketingInfo, validateEmail, validateNotNull } from "@davo/types";
import { Button, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMerchantPortalConfigContext } from "../context";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { ParBrinkPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function ParBrinkBoarding() {
    const classes = useStyles();
    const [locationToken, setLocationToken] = useState<string | undefined>(undefined);
    const [locationUrl, setLocationUrl] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setEmail(storageMarketingInfo.email);
    }, []);

    const doCall = () => {
        if (isBusy) {
            return;
        }

        if (locationToken && email && locationUrl && isEmailValid(email)) {
            setIsBusy(true);
            const posData: ParBrinkPosInfo = {
                locationToken,
                locationUrl,
                email,
            };
            navigate(configInfo.parBrinkURL, {
                state: posData,
            });
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"par-brink"}>
            <TextField
                label="Par Brink Location Key"
                value={locationToken ?? ""}
                onChange={setLocationToken}
                onEnterPress={doCall}
                inputProps={{
                    [`data-testid`]: "keyInput",
                }}
                isRequired
                validate={validateNotNull}
                margin={"dense"}
                isDisabled={isBusy}
            />
            <TextField
                label="Par Brink Server URL"
                value={locationUrl ?? ""}
                onChange={setLocationUrl}
                onEnterPress={doCall}
                inputProps={{
                    [`data-testid`]: "serverInput",
                }}
                isRequired
                margin={"dense"}
                validate={validateNotNull}
                isDisabled={isBusy}
            />
            <TextField
                label="Email"
                isRequired
                validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                isDisabled={isBusy}
                value={email ?? ""}
                onChange={setEmail}
                onEnterPress={doCall}
                margin={"dense"}
                inputProps={{
                    [`data-testid`]: "emailInput",
                }}
            />
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doCall}
                    data-testid={"submitBtn"}
                    disabled={!locationToken || !locationUrl || !email || isBusy}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}>
                    Connect with Par Brink
                </Button>
            </div>
        </BoardingContainer>
    );
}
