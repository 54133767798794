import { useLoginContext } from "@davo/portal-common";
import { isProduction } from "@davo/types";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useMerchantPortalConfigContext } from "../context";

export function GoogleAnalytics() {
    const loginContext = useLoginContext();
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();

    useEffect(() => {
        if (isProduction(configInfo.d30Env) && (!loginContext.user || loginContext.user?.type === "Merchant")) {
            try {
                TagManager.initialize({ gtmId: "GTM-TP584HB" });
            } catch (e: any) {
                // appears to represent conflicts with browser extensions
                if (!e.message.includes("Cannot redefine property: googletag")) {
                    throw e;
                }
            }
        }
    }, [configInfo, loginContext.user]);

    return null;
}
