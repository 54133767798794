import { BodyText, Header, TextField, addBankAccount, d30Toast, d30ToastError } from "@davo/portal-common";
import { BankAccount, validateAccountLength, validateNotNull, validateRoutingLength } from "@davo/types";
import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { TermsAndConditionsForm } from "./TermsAndConditionsForm";

interface IAddBankAccountFormType {
    accountId: string;
    target?: string;
    onComplete: (bankAccountId: string) => void;
    onCancel?: () => void;
    showCancelButton?: boolean;
    submitButtonLabel?: string;
    showHeader?: boolean;
    showSuccessToast?: boolean;
    showTermsAndConditions: boolean;
}

export const defaultSubmitButtonText = "Connect and Continue";
export const defaultCancelButtonText = "Cancel";
export const defaultRoutingNumberLabel = "Routing Number";
export const defaultAccountNumberLabel = "Account Number";
export const defaultNicknameLabel = "Bank Account Nickname (optional)";
export const defaultHeaderText = "Connect Your Bank Account";
export const defaultSuccessText = "Changes saved";

const getRoutingValidationError = (v: string) => (v ? validateRoutingLength(v) : validateNotNull(v));
const getAccountValidationError = (v: string) => (v ? validateAccountLength(v) : validateNotNull(v));

export const AddBankAccountForm: FunctionComponent<IAddBankAccountFormType> = ({
    accountId,
    onComplete,
    onCancel,
    showCancelButton,
    submitButtonLabel,
    showHeader = true,
    showSuccessToast = true,
    showTermsAndConditions,
}) => {
    const [bankRouting, setBankRouting] = useState<string>("");
    const [bankAccount, setBankAccount] = useState<string>("");
    const [nickname, setNickname] = useState<string>("");
    const [message, setMessage] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [areTermsOfUseAccepted, setAreTermsOfUseAccepted] = useState<boolean>(!showTermsAndConditions);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const createBankAccount = useCallback(() => {
        if (isBusy) return;

        setMessage(undefined);
        setIsBusy(true);
        addBankAccount(accountId, { routing: bankRouting, account: bankAccount, nickname: nickname })
            .then((bank: BankAccount) => {
                onComplete(bank.id);
                // Conditionally show the success message. This is used by the onboarding flow to skip this message
                showSuccessToast && d30Toast(defaultSuccessText);
            })
            .catch((e) => {
                d30ToastError("Problem adding bank.", e);
                setMessage(e.message);
            })
            .finally(() => setIsBusy(false));
    }, [accountId, bankRouting, bankAccount, nickname, onComplete, showSuccessToast, isBusy]);

    const handleSubmitForm = useCallback(
        (event?: FormEvent<HTMLFormElement>) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }

            if (isBusy) return;

            if (isFormValid) {
                createBankAccount();
            } else {
                setMessage("Please fix required fields.");
            }
        },
        [isBusy, isFormValid, createBankAccount]
    );

    useEffect(() => {
        const isRoutingInvalid = !!getRoutingValidationError(bankRouting);
        const isAccountInvalid = !!getAccountValidationError(bankAccount);

        const shouldBeDisabled = isBusy || isRoutingInvalid || isAccountInvalid || !areTermsOfUseAccepted;

        setIsFormValid(!shouldBeDisabled);
    }, [isBusy, bankRouting, bankAccount, areTermsOfUseAccepted]);

    return (
        <Box
            component={"form"}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmitForm}
            data-testid={"bankAddForm"}
            style={{ maxWidth: "100%" }}>
            {showHeader && <Header>{defaultHeaderText}</Header>}
            <BodyText>
                Connect your bank account and we will set aside your sales tax daily and hold it in a secure account for
                you.
                <a
                    href="https://help.davosalestax.com/knowledge-base/kb-search-results?term=bank"
                    target="_blank"
                    style={{ padding: "6px" }}
                    rel="noreferrer">
                    FAQ
                </a>
            </BodyText>

            <TextField
                isRequired
                data-testid={"routing"}
                inputProps={{
                    [`data-testid`]: "routingInput",
                }}
                className="fs-exclude"
                label={defaultRoutingNumberLabel}
                isDisabled={isBusy}
                value={bankRouting}
                onChange={setBankRouting}
                validate={getRoutingValidationError}
                onEnterPress={handleSubmitForm}
                margin={"dense"}
            />
            <TextField
                isRequired
                data-testid={"account"}
                inputProps={{
                    [`data-testid`]: "accountInput",
                }}
                className="fs-exclude"
                label={defaultAccountNumberLabel}
                isDisabled={isBusy}
                value={bankAccount}
                onChange={setBankAccount}
                validate={getAccountValidationError}
                onEnterPress={handleSubmitForm}
                margin={"dense"}
            />
            <TextField
                data-testid={"nickname"}
                inputProps={{
                    [`data-testid`]: "nicknameInput",
                }}
                className="fs-exclude"
                label={defaultNicknameLabel}
                isDisabled={isBusy}
                value={nickname}
                onChange={setNickname}
                onEnterPress={handleSubmitForm}
                margin={"dense"}
            />

            {showTermsAndConditions && (
                <TermsAndConditionsForm
                    onChangeAcceptedTermsOfUse={(hasTermsBeenAccepted: boolean) =>
                        setAreTermsOfUseAccepted(hasTermsBeenAccepted)
                    }
                />
            )}

            {message && (
                <Typography data-testid={"messageContainer"} style={{ color: "red" }}>
                    {message}
                </Typography>
            )}

            <div style={{ textAlign: "right" }}>
                {showCancelButton && (
                    <Button
                        data-testid={"cancelAddBankBtn"}
                        disabled={isBusy}
                        onClick={() => onCancel && onCancel()}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "10px" }}>
                        {defaultCancelButtonText}
                    </Button>
                )}

                <Button
                    type={"submit"}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}
                    data-testid={"addBankAccountFormBtn"}
                    disabled={!isFormValid}
                    variant="contained"
                    color="primary"
                    autoFocus>
                    {submitButtonLabel ?? defaultSubmitButtonText}
                </Button>
            </div>
        </Box>
    );
};
