import {
    BodyText,
    d30Toast,
    d30ToastError,
    getLocation,
    Header,
    setLocationBankAccount,
    useModalEditor,
} from "@davo/portal-common";
import { OptionalString } from "@davo/types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BankAccountSelector } from "./components";
import { useAccountContext, useLocationContext } from "./context";
import { RouteModal } from "./RouteModal";

export function SettingsCompanySetBankModal() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();

    const [locationName, setLocationName] = useState<string>();
    const [selectBankAccountSelectorModalProps] = useModalEditor<string>();
    const [bankAccountId, setBankAccountId] = useState<OptionalString>();
    const [hasAcceptedTermsOfUse, setHasAcceptedTermsOfUse] = useState<boolean>(false);

    useEffect(() => {
        if (!accountContext.account || !locationContext.location) {
            return;
        }

        getLocation(accountContext.account.id, locationContext.location.id)
            .then((location) => {
                setLocationName(location.name);
                setBankAccountId(location.bankAccountId);
            })
            .catch((e) => d30ToastError("Problem getting location.", e));
    }, [accountContext.account, locationContext.location]);

    useEffect(() => {
        setHasAcceptedTermsOfUse(false);
    }, [bankAccountId]);

    if (!accountContext.account || !locationContext.location) {
        return null;
    }

    const handleSave = async (): Promise<void> => {
        if (!accountContext.account || !locationContext.location || !bankAccountId) {
            return;
        }

        await setLocationBankAccount(accountContext.account.id, locationContext.location.id, bankAccountId)
            .then(locationContext.refresh)
            .then(accountContext.refresh)
            .then(() => d30Toast("Successfully set bank account location"))
            .catch((e) => d30ToastError("Problem setting location bank account.", e));
    };

    const headerBank = (
        <div>
            <Header>Bank Account for {locationName}</Header>
            <BodyText>
                Select the bank account for this location. DAVO will set aside collected sales-tax funds from this
                account daily. If you have only one bank account listed, to change bank accounts, you will first need to
                add another.
            </BodyText>
        </div>
    );

    return (
        <RouteModal
            {...selectBankAccountSelectorModalProps}
            isDisabled={!bankAccountId || !hasAcceptedTermsOfUse}
            action={handleSave}
            onCancel={() => {
                navigate("..");
            }}>
            <BankAccountSelector
                bankAccountId={bankAccountId}
                hasAcceptedTermsOfUse={hasAcceptedTermsOfUse}
                setBankAccountIdCallback={setBankAccountId}
                setAcceptedTermsOfUse={setHasAcceptedTermsOfUse}
                header={headerBank}
                onCancel={() => {
                    navigate("..");
                }}
            />
        </RouteModal>
    );
}
