import { getLocationEnhanced } from "@davo/portal-common";
import { Location } from "@davo/types";
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";
import { useAccountContext } from "./AccountContext";

export interface ILocationContext {
    location?: Location;

    switch: (locationId: string | undefined) => Promise<void>;
    refresh: () => Promise<void>;
}

export const LocationContextDefaultValue = {} as ILocationContext;
export const LocationContext = React.createContext(LocationContextDefaultValue);
export const useLocationContext = () => React.useContext(LocationContext);

export const LocationContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const { account } = useAccountContext();

    const [location, setLocation] = useState<Location>();

    useEffect(() => {
        if (!account) {
            setLocation(undefined);
        }
    }, [account]);

    const refreshLocation = async () => {
        if (!account || !location) {
            return;
        }
        const l: Location = await getLocationEnhanced(account.id, location.id);
        setLocation(l);
    };

    const switchLocation = async (locationId: string | undefined) => {
        if (!account) {
            return;
        }
        if (!locationId) {
            setLocation(undefined);
            return;
        }
        const l: Location = await getLocationEnhanced(account.id, locationId);
        setLocation(l);
    };

    return (
        <LocationContext.Provider
            value={{
                switch: switchLocation,
                location,
                refresh: refreshLocation,
            }}>
            {children}
        </LocationContext.Provider>
    );
};
