import {
    ConnectionStatus,
    IConnectionStatusProps,
    mapIAccountLocationsStatusToIConnectionStatus,
    reduceToSingleAccountLocationStatus,
} from "@davo/portal-common";
import React, { useEffect, useMemo, useState } from "react";
import { useAccountContext } from "../context";
import { defaultStatusLevelMessageMerchantOverride } from "../util";

export interface IMerchantConnectionStatusProps {
    locationId?: string;
    hideFixLink?: boolean;
}

export function MerchantConnectionStatus({
    locationId,
    hideFixLink = false,
    ...rest
}: IMerchantConnectionStatusProps & IConnectionStatusProps) {
    const accountContext = useAccountContext();
    const [settingsLink, setSettingsLink] = useState<string | undefined>();
    const [bankSettingsLink, setBankSettingsLink] = useState<string | undefined>();
    const [tpSettingsLink, setTpSettingsLink] = useState<string | undefined>();
    const [restrictedBankSettingsLink, setRestrictedBankSettingsLink] = useState<string | undefined>();

    useEffect(() => {
        if (
            !accountContext.account ||
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
            !accountContext.bankAccounts ||
            !accountContext.taxProfiles
        ) {
            return;
        }
        setSettingsLink(
            locationId
                ? `/${accountContext.account.id}/settings/company/${locationId}`
                : `/${accountContext.account.id}/settings/company`
        );
        setRestrictedBankSettingsLink(
            locationId
                ? `/${accountContext.account.id}/settings/company/${locationId}`
                : `/${accountContext.account.id}/settings/company`
        );
        setBankSettingsLink(`/${accountContext.account.id}/settings/banking`);
        setTpSettingsLink(`/${accountContext.account.id}/settings/filing`);
    }, [
        accountContext.locations,
        accountContext.bankAccounts,
        accountContext.taxProfiles,
        accountContext.account,
        locationId,
    ]);

    const currentConnectionStatus = useMemo(() => {
        if (!accountContext.locationsAggregateStatus) {
            return;
        }
        if (!locationId) {
            return mapIAccountLocationsStatusToIConnectionStatus(
                reduceToSingleAccountLocationStatus(accountContext.locationsAggregateStatus)
            );
        }
        const locationAggregateStatus = accountContext.locationsAggregateStatus?.find(
            (x) => x.locationId === locationId
        );
        return locationAggregateStatus?.statusInfo
            ? mapIAccountLocationsStatusToIConnectionStatus(locationAggregateStatus)
            : undefined;
    }, [accountContext.locationsAggregateStatus, locationId]);

    if (!accountContext.account) {
        return;
    }

    return (
        <div data-testid={"merchantConnectionStatus"}>
            <ConnectionStatus
                locationId={locationId}
                accountId={accountContext.account.id}
                connectionStatusProvided={currentConnectionStatus}
                {...(hideFixLink
                    ? {}
                    : {
                          missingBankLink: settingsLink,
                          pausedBankInUseLink: bankSettingsLink,
                          missingTaxProfileLink: settingsLink,
                          failingTaxProfileLink: tpSettingsLink,
                          restrictedBankLink: restrictedBankSettingsLink,
                      })}
                statusLevelOverride={defaultStatusLevelMessageMerchantOverride}
                {...rest}
            />
        </div>
    );
}
