import { FormError, getPublicJurisdictions, Select, TextField } from "@davo/portal-common";
import {
    IJurisdiction,
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
    validateZip,
} from "@davo/types";
import { Button, FormControl, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useMerchantPortalConfigContext } from "../context";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function LightspeedKSeriesBoarding() {
    const classes = useStyles();
    const [address1, setAddress1] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [state, setState] = useState<string | undefined>(undefined);
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [zip, setZip] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();

    useAsyncEffect(async () => {
        setStates(await getPublicJurisdictions());
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    const doCall = () => {
        if (address1 && city && state && zip && email && phone && isEmailValid(email) && isPhoneValid(phone)) {
            setMessage(undefined);
            const stateParams = { address1, city, state, zip, email, phone };
            setMessage(undefined);
            const passThrough = Buffer.from(JSON.stringify(stateParams)).toString("base64");
            window.location.replace(`${configInfo.lightspeedKSeriesAuthURL}&state=${passThrough}`);
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"lightspeed-k-series"}>
            <div style={{ paddingBottom: "20px" }}>Tell us a little about your business:</div>
            <TextField
                label="Phone"
                validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                value={phone ?? ""}
                onChange={setPhone}
                onEnterPress={doCall}
                margin={"dense"}
                inputProps={{
                    [`data-testid`]: "phoneInput",
                }}
                isRequired
            />
            <TextField
                label="Email"
                validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                value={email ?? ""}
                onChange={setEmail}
                onEnterPress={doCall}
                margin={"dense"}
                inputProps={{
                    [`data-testid`]: "emailInput",
                }}
                isRequired
            />
            <TextField
                label="Address"
                value={address1 ?? ""}
                onChange={setAddress1}
                validate={validateNotNull}
                onEnterPress={doCall}
                margin={"dense"}
                isRequired
                inputProps={{
                    [`data-testid`]: "addressInput",
                }}
            />
            <TextField
                label="City"
                value={city ?? ""}
                onChange={setCity}
                onEnterPress={doCall}
                validate={validateNotNull}
                margin={"dense"}
                isRequired
                inputProps={{
                    [`data-testid`]: "cityInput",
                }}
            />
            <FormControl data-testid={"stateSelector"} style={{ width: "100%" }}>
                <Select<IJurisdiction>
                    title="State"
                    options={states}
                    value={states.find((ba: IJurisdiction) => ba.abbreviatedName === state)}
                    label={(jurisdiction) => jurisdiction.fullName}
                    onChange={(val) => {
                        if (!val) {
                            return;
                        }
                        setState(val.abbreviatedName);
                    }}
                    margin={"dense"}
                    isRequired
                    validate={(v) => (v ? undefined : validateNotNull(null))}
                />
            </FormControl>
            <TextField
                label="Zip"
                validate={(v) => (v ? validateZip(v) : validateNotNull(null))}
                value={zip ?? ""}
                onChange={setZip}
                onEnterPress={doCall}
                margin={"dense"}
                isRequired
                inputProps={{
                    [`data-testid`]: "zipInput",
                }}
            />
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doCall}
                    data-testid={"submitBtn"}
                    disabled={!address1 || !city || !state || !zip || !email || !phone}
                    style={{ margin: "20px" }}>
                    Continue with Lightspeed K-Series
                </Button>
            </div>
        </BoardingContainer>
    );
}
