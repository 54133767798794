import { ReactTable8 } from "@davo/portal-common";
import { BankAccount } from "@davo/types";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CellContext, ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import React, { FunctionComponent, useMemo } from "react";
import { useAccountContext } from "../context";
import { BankAccountStatus } from "./BankAccountStatus";
import { BankDetailsExpanded } from "./BankDetailsExpanded";
import { RenderBankName } from "./RenderBankName";

export interface IActiveBankAccountsTable {
    showBankAccountInfoPaneModal: (thing?: string | undefined) => void;
    activeBankAccounts: BankAccount[];
    restrictMerchantEditMessage: string;
    shouldRestrictMerchantEdit: boolean;
}

export const ActiveBankAccountsTable: FunctionComponent<IActiveBankAccountsTable> = ({
    showBankAccountInfoPaneModal,
    activeBankAccounts,
    restrictMerchantEditMessage,
    shouldRestrictMerchantEdit,
}) => {
    const { locations, account } = useAccountContext();

    const renderBankDetail = (row: Row<BankAccount>) => {
        if (!locations || (locations && !locations.filter((l) => l.active))) {
            return null;
        }
        const bankAccount = row.original;
        const found = locations.filter((loc) => loc.bankAccountId === bankAccount.id && loc.active);
        if (found.length > 0) {
            return (
                <BankDetailsExpanded
                    bankAccount={bankAccount}
                    associatedLocations={found}
                    restrictMerchantEditMessage={restrictMerchantEditMessage}
                    shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                />
            );
        } else {
            return null;
        }
    };

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<BankAccount>();

        const renderStatus = (data: CellContext<BankAccount, any>) => {
            const bankAccount = data.row.original;
            return (
                account && (
                    <BankAccountStatus
                        accountId={account.id}
                        bankAccount={bankAccount}
                        onClick={() => showBankAccountInfoPaneModal(bankAccount.id)}
                        showButton={true}
                    />
                )
            );
        };

        const shouldToggle = (bankAccount: BankAccount) => {
            if (!locations || (locations && !locations.filter((l) => l.active))) {
                return;
            }
            return locations.some((loc) => loc.bankAccountId === bankAccount.id && loc.active);
        };

        return [
            columnHelper.accessor("id", {
                id: "expander", // Make sure expand columns have an ID
                header: "",
                cell: (data) => {
                    const bankAccount = data.row.original;
                    return shouldToggle(bankAccount) ? (
                        <span
                            className={"mui-expand-toggle-container"}
                            onClick={() => data.row.toggleExpanded()}
                            style={{ paddingLeft: "8px" }}>
                            {data.row.getIsExpanded() ? (
                                <span className={"expandLess"}>
                                    <ExpandLessIcon data-testid={`bank-expander-less-${bankAccount.id}`} />
                                </span>
                            ) : (
                                <span className={"expandMore"}>
                                    <ExpandMoreIcon data-testid={`bank-expander-more-${bankAccount.id}`} />
                                </span>
                            )}
                        </span>
                    ) : null;
                },
                enableSorting: false,
            }),
            columnHelper.accessor("name", {
                header: "Name",
                cell: (data) => {
                    const bankAccount = data.row.original;
                    return <RenderBankName bankAccount={bankAccount} />;
                },
            }),
            columnHelper.accessor("routing", {
                header: "Routing Number",
                enableSorting: false,
            }),
            columnHelper.accessor("accountStarred", {
                header: "Account Number",
                enableSorting: false,
            }),
            columnHelper.display({
                header: "Status",
                cell: renderStatus,
                enableSorting: false,
            }),
        ];
    }, [account, locations, showBankAccountInfoPaneModal]);

    return (
        <div>
            <ReactTable8<BankAccount>
                columns={columns as ColumnDef<BankAccount>[]}
                options={{
                    hideToolbar: true,
                    pageSize: 10,
                }}
                renderRowSubComponent={renderBankDetail}
                data={activeBankAccounts}
            />
        </div>
    );
};
