import { BodyText, d30Toast, d30ToastError, Header, setLocationBankAccount } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useAccountContext } from "../context";
import { BankAccountSelector } from "./BankAccountSelector";

export interface IChangeBankTarget {
    bankAccountId: string;
    locationName: string;
    locationId: string;
}

export interface IChangeBankModal {
    closeDialog(): void;
    target?: IChangeBankTarget;
}

export const ChangeBankModal: FunctionComponent<IChangeBankModal> = ({ closeDialog, target }) => {
    const titleId = "associate-locs-to-bank-modal-title";
    const { account, refresh } = useAccountContext();
    const [hasAcceptedTermsOfUse, setHasAcceptedTermsOfUse] = useState<boolean>(false);

    const [bankAccountId, setBankAccountId] = useState<string>(
        typeof target?.bankAccountId === "string" ? target?.bankAccountId : ""
    );

    if (!target) return;

    const headerBank = (
        <div>
            <Header>Bank Account for {target.locationName}</Header>
            <BodyText>
                Select the bank account for this location. DAVO will set aside collected sales-tax funds from this
                account daily. If you have only one bank account listed, to change bank accounts, you will first need to
                add another.
            </BodyText>
        </div>
    );

    const handleSave = async (): Promise<void> => {
        if (!account || !target.locationId || !bankAccountId) {
            return;
        }

        await setLocationBankAccount(account.id, target.locationId, bankAccountId)
            .then(refresh)
            .then(() => d30Toast("Successfully set bank account location"))
            .catch((e) => d30ToastError("Problem setting location bank account.", e))
            .finally(closeDialog);
    };

    const handleChangeBankAccountSelection = (v?: string) => v && setBankAccountId(v);

    return (
        <Dialog
            data-testid={"changeBankModal"}
            maxWidth={"lg"}
            fullWidth={true}
            open={true}
            aria-labelledby={titleId}
            onClose={closeDialog}>
            <DialogTitle id={titleId}>Select a bank</DialogTitle>
            <DialogContent>
                <BankAccountSelector
                    bankAccountId={bankAccountId}
                    hasAcceptedTermsOfUse={hasAcceptedTermsOfUse}
                    setBankAccountIdCallback={handleChangeBankAccountSelection}
                    setAcceptedTermsOfUse={setHasAcceptedTermsOfUse}
                    header={headerBank}
                    onCancel={closeDialog}
                    showAddBank={false}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                    data-testid={"saveChangeBankBtn"}
                    variant="contained"
                    onClick={handleSave}
                    disabled={!bankAccountId || !hasAcceptedTermsOfUse}
                    size="small">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
