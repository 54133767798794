import { BodyText } from "@davo/portal-common";
import { BankAccount, LocationRecord, waiveTermsAndConditionsByPOS } from "@davo/types";
import { Button } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { AddBankAccountForm, BankAccountSelector } from "../../components";
import { useAccountContext } from "../../context";

export interface IBankAccountSetupType {
    location: LocationRecord;
    onSave: (bankAccountId: string) => void;
}

export function BankAccountSetup({ location, onSave }: IBankAccountSetupType) {
    const accountContext = useAccountContext();
    const [nonHiddenBanks, setNonHiddenBanks] = useState<BankAccount[]>();
    const [bankAccountId, setBankAccountId] = useState<string | undefined>();
    const [hasAcceptedTermsOfUse, setHasAcceptedTermsOfUse] = useState<boolean>(false);

    useEffect(() => {
        if (isNil(accountContext.bankAccounts) || bankAccountId) {
            return;
        }
        const banks = accountContext.bankAccounts?.filter((b: BankAccount) => !b.hidden);
        setNonHiddenBanks(banks);

        // if there is only one bank default select it
        if (banks.length === 1) {
            setBankAccountId(banks[0].id);
        }
    }, [accountContext.bankAccounts, bankAccountId]);

    if (
        isNil(nonHiddenBanks) ||
        !accountContext.account ||
        isNil(accountContext.bankAccounts) ||
        isNil(accountContext.locations) ||
        (!isNil(accountContext.locations) && !accountContext.locations.filter((l) => l.active))
    ) {
        return null;
    }

    if (accountContext.bankAccounts.length > 0) {
        const bankAccountHeader = (
            <div>
                <h2>Connect Your Bank Account</h2>
                <BodyText>
                    Select the bank account for this location. DAVO will set aside collected sales-tax funds from this
                    account daily.
                </BodyText>
                <BodyText>
                    If you have only one bank account listed, to change bank accounts, you will first need to add
                    another.
                </BodyText>
            </div>
        );

        return (
            <div>
                <BankAccountSelector
                    bankAccountId={bankAccountId}
                    setBankAccountIdCallback={setBankAccountId}
                    hasAcceptedTermsOfUse={hasAcceptedTermsOfUse}
                    setAcceptedTermsOfUse={setHasAcceptedTermsOfUse}
                    header={bankAccountHeader}
                />
                <div style={{ textAlign: "right" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!hasAcceptedTermsOfUse}
                        onClick={() => {
                            if (bankAccountId) {
                                onSave(bankAccountId);
                            }
                        }}>
                        Set Bank Account
                    </Button>
                </div>
            </div>
        );
    } else {
        return (
            <AddBankAccountForm
                accountId={accountContext.account.id}
                showSuccessToast={false}
                showTermsAndConditions={accountContext.locations.some(
                    (loc: LocationRecord) => !waiveTermsAndConditionsByPOS.includes(loc.posType) && location.active
                )}
                onComplete={(bankAccId: string) => {
                    setBankAccountId(bankAccId);
                    onSave(bankAccId);
                }}
            />
        );
    }
}
