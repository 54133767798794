import { BankAccount } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Card, CardActions, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BankAccountStatus } from "../index";
import { SettingsSingleLocationBankAccountCardContent } from "./SettingsSingleLocationBankAccountCardContent";

interface ISettingsSingleLocationBankAccountCardProps {
    accountId: string;
    bankAccount?: BankAccount | null;
    locationUrl: string;
    restrictBankInfo: {
        restrictedMsg: string;
        isRestrictedBtnDisabled: boolean;
        restrictEditBankInfo?: boolean;
    };
}

export const SettingsSingleLocationBankAccountCard: React.FC<ISettingsSingleLocationBankAccountCardProps> = ({
    accountId,
    bankAccount,
    locationUrl,
    restrictBankInfo,
}) => {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate(`${locationUrl}/bank`);
    };
    const renderBankLink = () => {
        if (restrictBankInfo.restrictEditBankInfo) {
            return (
                <>
                    <Button
                        data-testid="bank-account-button"
                        disabled={restrictBankInfo.isRestrictedBtnDisabled}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={onClickHandler}>
                        {bankAccount ? "Change" : "Fix"}
                    </Button>
                    <Tooltip title={restrictBankInfo.restrictedMsg} placement="top">
                        <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                    </Tooltip>
                </>
            );
        }
        return (
            <Button
                data-testid="bank-account-button"
                variant={!bankAccount ? "contained" : "outlined"}
                color="primary"
                size="small"
                onClick={onClickHandler}>
                {bankAccount ? "Change" : "Fix"}
            </Button>
        );
    };
    return (
        <Card variant={"outlined"}>
            <CardHeader
                title={
                    <div className={"da-settings-header-content"}>
                        <Typography variant={"h4"} style={{ fontSize: "16px", display: "inline-block" }}>
                            Bank Account
                        </Typography>
                        <BankAccountStatus accountId={accountId} bankAccount={bankAccount} showPausedMessage />
                    </div>
                }
            />
            <CardContent>
                <SettingsSingleLocationBankAccountCardContent bankAccount={bankAccount} />
            </CardContent>
            <div style={{ flexGrow: "1" }} />
            <CardActions>{renderBankLink()}</CardActions>
        </Card>
    );
};
