import { defaultConnectionStatus } from "@davo/portal-common";
import { getTaxProfileStatus, TaxProfile, TaxProfileFailureReasonsDescriptions } from "@davo/types";
import React from "react";
import { useAccountContext } from "../context";
import { MerchantConnectionStatus } from "./MerchantConnectionStatus";

export interface ITaxProfileStatusType {
    taxProfile?: TaxProfile;
    showToolTipOnly?: boolean;
}

export function TaxProfileStatus({ taxProfile, showToolTipOnly }: ITaxProfileStatusType) {
    const accountContext = useAccountContext();
    const { hasMissingTaxInfo, hasFailingTaxInfo, hasUntestedTaxCredentials } = getTaxProfileStatus(
        taxProfile ? [taxProfile] : []
    );
    const tooltipMessage = taxProfile?.failingReason
        ? TaxProfileFailureReasonsDescriptions[taxProfile?.failingReason]
        : undefined;
    return (
        <>
            {accountContext?.account?.id && (
                <MerchantConnectionStatus
                    accountId={accountContext.account?.id}
                    tooltipMessage={tooltipMessage}
                    hideMessage={showToolTipOnly}
                    hideFixLink
                    connectionStatusProvided={{
                        ...defaultConnectionStatus,
                        hasMissingTaxInfo,
                        hasFailingTaxInfo,
                        hasUntestedTaxCredentials,
                    }}
                />
            )}
        </>
    );
}
