import { BankDetailsPausedMessage, defaultConnectionStatus, getBankAccountNachaRestricted } from "@davo/portal-common";
import { BankAccount, BankAccountNachaRestrictedMessage, getBankingStatus, IConnectionStatus } from "@davo/types";
import { Alert, Button } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { MerchantConnectionStatus } from "./MerchantConnectionStatus";

export interface IBankAccountStatusProps {
    accountId: string;
    bankAccount?: BankAccount | null;
    onClick?: () => void;
    showButton?: boolean;
    showPausedMessage?: boolean;
}

export function BankAccountStatus({
    accountId,
    bankAccount,
    onClick,
    showButton,
    showPausedMessage,
}: IBankAccountStatusProps) {
    const [message, setMessage] = useState<string>();
    const [nachaRestricted, setNachaRestricted] = useState<BankAccountNachaRestrictedMessage>();
    const [bankAccountConnectionStatus, setBankAccountConnectionStatus] = useState<IConnectionStatus>();

    useAsyncEffect(async () => {
        const { bankAccountUnpauseDate, arePausedBankAccountsInUse } = getBankingStatus(bankAccount);
        setBankAccountConnectionStatus({
            ...defaultConnectionStatus,
            hasMissingBankInfo: !bankAccount,
            arePausedBankAccountsInUse: arePausedBankAccountsInUse,
            pausedWithUnpauseDate: isEmpty(bankAccountUnpauseDate)
                ? null
                : DateTime.fromFormat(bankAccountUnpauseDate, "MMM dd, yyyy"),
        });
        if (!bankAccount) {
            return;
        }
        const restricted = await getBankAccountNachaRestricted(accountId, bankAccount.id);
        setNachaRestricted(restricted);

        if (restricted?.isNachaRestricted && !bankAccount.paused) {
            setMessage("In order to re-enable this account, you have to provide a new bank account");
        }
    }, [bankAccount]);

    return (
        <>
            <div
                data-testid={"bank-status"}
                style={{
                    display: "flex",
                    gap: "10px",
                }}>
                <div
                    style={{
                        position: "relative",
                        left: "-10px",
                    }}>
                    {bankAccountConnectionStatus ? (
                        <MerchantConnectionStatus
                            accountId={accountId}
                            connectionStatusProvided={bankAccountConnectionStatus}
                            hideFixLink
                        />
                    ) : null}
                </div>
                {bankAccount?.paused && showButton && !nachaRestricted?.isNachaRestricted && (
                    <Button
                        onClick={onClick}
                        variant="outlined"
                        size={"small"}
                        color="primary"
                        data-testid={"reactivate-button"}>
                        Reactivate
                    </Button>
                )}
            </div>
            {showPausedMessage && (
                <BankDetailsPausedMessage bankAccount={bankAccount} nachaRestricted={nachaRestricted} />
            )}
            {message && (
                <Alert data-testid="alert-nacha-restricted-message" severity="error">
                    {message}
                </Alert>
            )}
        </>
    );
}
