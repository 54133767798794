import { PaperComponent, ReactTable8 } from "@davo/portal-common";
import {
    FilingHistoricalWithAttachments,
    FilingWithAttachmentHistoryAndLocations,
    moneyFromCents,
    toDateTime,
    toDisplayDateString,
} from "@davo/types";
import { Button, Dialog, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import React, { useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import { useMerchantFilingFilterResultsContext } from "../context";
import workingImage from "../resources/davo_illustrations_working.png";
import { FilingAttachmentsReadonly, FilingHistoricalAttachments } from "./index";

export function AccountFilingsTable() {
    const {
        filingFilterResults,
        taxProfileOptions,
        account,
        showHistorical,
        historicalFilingResults,
        setShowHistorical,
    } = useMerchantFilingFilterResultsContext();
    const taxProfileNameFormat = useCallback(
        (taxProfileId: string) => {
            const tp = find(taxProfileOptions, { id: taxProfileId });
            if (!tp) {
                return "";
            }

            return tp.name;
        },
        [taxProfileOptions]
    );

    const discountFormat = (data: CellContext<FilingWithAttachmentHistoryAndLocations, any>) => (
        <div style={{ textAlign: "right" }}>${moneyFromCents(data.getValue() ?? 0)}</div>
    );
    const dateFormat = (data: CellContext<FilingWithAttachmentHistoryAndLocations, any>) =>
        data.getValue() ? toDisplayDateString(data.getValue()) : "";
    const periodEndFormat = (data: CellContext<FilingWithAttachmentHistoryAndLocations, any>) =>
        data.getValue() ? toDisplayDateString(data.getValue()) : "";
    const taxDueFormat = (data: CellContext<FilingWithAttachmentHistoryAndLocations, any>) => (
        <div style={{ textAlign: "right" }}>${moneyFromCents(data.getValue() ?? 0)}</div>
    );
    const legalNameFormat = (taxProfileId: string) => {
        const tp = find(taxProfileOptions, { id: taxProfileId });
        if (!tp) {
            return "";
        }

        return `${tp.legalName}`;
    };
    function getExportName(acctName: string) {
        const name = acctName.replace(/\s/g, "-");
        return `${name}-filings.csv`;
    }
    const columns = useMemo(() => {
        const nameFormat = (taxProfileId: string) => {
            const tp = find(taxProfileOptions, { id: taxProfileId });
            if (!tp) {
                return "";
            }
            let label = `${tp.legalName}-${tp.name}`;
            label = label.length > 40 ? `${label.substring(0, 40)}...` : label;
            return label;
        };

        const attachmentFormat = (data: CellContext<FilingWithAttachmentHistoryAndLocations, any>) => {
            if (!data || !account) {
                return <div></div>;
            }

            if (!data.cell.row.original.historical) {
                return <FilingAttachmentsReadonly accountId={account.id} filing={data.cell.row.original} />;
            } else {
                const hist = [];
                for (const a of data.cell.row.original.attachments) {
                    hist.push({ ...a, filingHistoricalId: a.filingId });
                }
                const historic: FilingHistoricalWithAttachments = {
                    ...(data.cell.row.original as any),
                    attachments: hist,
                };
                return <FilingHistoricalAttachments accountId={account.id} filing={historic} />;
            }
        };

        const columnHelper = createColumnHelper<FilingWithAttachmentHistoryAndLocations>();

        return [
            columnHelper.accessor("filedDate", {
                header: "Filed Date",
                cell: (data) => (
                    <span data-testid={"filedDateTableData"} style={{ minWidth: "80px" }}>
                        {dateFormat(data)}
                    </span>
                ),
            }),
            columnHelper.accessor("periodEnd", {
                header: "For Period End",
                cell: (data) => (
                    <span data-testid={"periodEndTableData"} style={{ minWidth: "132px" }}>
                        {periodEndFormat(data)}
                    </span>
                ),
            }),
            columnHelper.accessor("jurisdiction", {
                header: "Jurisdiction",
            }),
            columnHelper.accessor("id", {
                header: "Tax Profile Name",
                cell: (data) => nameFormat(data.cell.row.original.taxProfileId),
            }),
            columnHelper.accessor("taxDue", {
                header: "Tax Due",
                cell: taxDueFormat,
            }),
            columnHelper.accessor("discount", {
                header: () => (
                    <Tooltip placement={"top"} title={"In jurisdictions where timely filing discounts are available."}>
                        <div>Timely File Discount</div>
                    </Tooltip>
                ),
                cell: (data) => (
                    <span data-testid={"discountTableData"} style={{ minWidth: "165px" }}>
                        {discountFormat(data)}
                    </span>
                ),
            }),
            columnHelper.accessor("attachments", {
                header: "Attachments",
                cell: attachmentFormat,
            }),
        ];
    }, [account, taxProfileOptions]);

    const getExportData = useCallback(
        (data: FilingWithAttachmentHistoryAndLocations[]) => {
            const results: any[] = [];

            for (const d of data) {
                for (const location of orderBy(d.locations, "name")) {
                    const remitted =
                        +(d.remitted ?? 0) +
                        +(d.remittedPart2 ?? 0) +
                        +(d.remittedPart3 ?? 0) +
                        +(d.remittedPart4 ?? 0) +
                        +(d.remittedPart5 ?? 0);

                    results.push({
                        "Legal Name": legalNameFormat(d.taxProfileId),
                        Jurisdiction: d.jurisdiction,
                        "Tax Profile Name": taxProfileNameFormat(d.taxProfileId),
                        "Tax Due": moneyFromCents(d.taxDue ?? 0),
                        Remitted: moneyFromCents(remitted),
                        "Merchant Direct Remit": moneyFromCents(d.remittedDirect ?? 0),
                        Discount: moneyFromCents(d.discount ?? 0),
                        "Period Start": toDateTime(d.periodStart).toSQLDate(),
                        "Period End": toDateTime(d.periodEnd).toSQLDate(),
                        "Filed Date": d.filedDate ? toDateTime(d.filedDate).toSQLDate() : "-",
                        "Merchant Note": d.merchantNote,
                        "Location Name": location.name,
                        "Tax Due Location": moneyFromCents(location.taxDue ?? 0),
                        "Remitted Location": moneyFromCents(location.remitted ?? 0),
                        "Merchant Direct Remitted Location": moneyFromCents(location.directRemittance ?? 0),
                        "Discount Location": moneyFromCents(location.discount ?? 0),
                    });
                }
            }
            return results;
        },
        [legalNameFormat, taxProfileNameFormat]
    );
    return (
        <div>
            {(!filingFilterResults || isEmpty(filingFilterResults)) &&
                (!historicalFilingResults || isEmpty(historicalFilingResults)) && (
                    <div style={{ textAlign: "center" }}>
                        <img src={workingImage} style={{ maxWidth: "100%" }} alt={"Thank you for your service"} />
                        <Typography
                            style={{ fontSize: "20px", margin: "10px", maxWidth: "100%" }}
                            data-testid={"thanks"}>
                            Thank you for using our service.
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "14px",
                                maxWidth: "100%",
                                color: "#252631",
                                margin: "auto",
                                marginBottom: "30px",
                            }}>
                            We are excited to have you using our updated portal. This is where you&apos;ll find the tax
                            filings we perform on your behalf.
                        </Typography>
                    </div>
                )}
            {filingFilterResults && !isEmpty(filingFilterResults) && (
                <div data-testid={"filingsTable"} style={{ maxWidth: "100%" }}>
                    <div style={{ maxWidth: "100%" }} id="davoTable">
                        <ReactTable8<FilingWithAttachmentHistoryAndLocations>
                            columns={columns}
                            data={filingFilterResults}
                            options={{
                                pageSize: 5,
                                hideToolbar: true,
                            }}
                        />
                    </div>
                    <Button
                        data-testid={"export-to-csv-table-data"}
                        variant="outlined"
                        color="primary"
                        size={"small"}
                        style={{ margin: "10px" }}>
                        <CSVLink
                            filename={getExportName(account ? account.name : "None")}
                            data={getExportData(filingFilterResults)}
                            style={{ textDecoration: "unset", color: "unset" }}
                            className="btn btn-primary">
                            Export Filings
                        </CSVLink>
                    </Button>
                </div>
            )}
            {showHistorical && !isEmpty(historicalFilingResults) && (
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    data-testid={"historicalFilingsModal"}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    fullScreen={false}
                    open={true}
                    onClose={() => {
                        setShowHistorical(!showHistorical);
                    }}>
                    <DialogTitle>
                        <span data-testid={"historicalFilingsModalDialogTitle"} style={{ alignSelf: "center" }}>
                            Historical Filings
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <>
                            <ReactTable8<FilingWithAttachmentHistoryAndLocations>
                                columns={columns}
                                data={historicalFilingResults}
                                options={{
                                    pageSize: 5,
                                    hideToolbar: true,
                                }}
                            />
                        </>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
}
