import { CommonPortalConfigContext, LoadingWithLogo } from "@davo/portal-common";
import { emptyDefaultMerchantPortalConfig, ICommonPortalConfigType, IMerchantPortalConfigType } from "@davo/types";
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getMerchantPortalConfig } from "../services/config";

export interface IMerchantPortalConfigContext {
    merchantPortalConfigInfo: IMerchantPortalConfigType;
}

export const MerchantPortalConfigContextDefaultValue: IMerchantPortalConfigContext = {
    merchantPortalConfigInfo: emptyDefaultMerchantPortalConfig,
};
export const MerchantPortalConfigContext = createContext(MerchantPortalConfigContextDefaultValue);
export const useMerchantPortalConfigContext = () => useContext(MerchantPortalConfigContext);

export const MerchantPortalConfigContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [merchantPortalConfigInfo, setMerchantPortalConfigInfo] = useState<IMerchantPortalConfigType>();
    const [commonPortalConfigInfo, setCommonPortalConfigInfo] = useState<ICommonPortalConfigType>();

    useAsyncEffect(async () => {
        const config = await getMerchantPortalConfig();
        setMerchantPortalConfigInfo(config);
        setCommonPortalConfigInfo(config);
    }, []);

    if (!commonPortalConfigInfo || !merchantPortalConfigInfo) {
        return <LoadingWithLogo />;
    }

    return (
        <MerchantPortalConfigContext.Provider
            value={{
                merchantPortalConfigInfo,
            }}>
            <CommonPortalConfigContext.Provider
                value={{
                    commonPortalConfigInfo,
                }}>
                {children}
            </CommonPortalConfigContext.Provider>
        </MerchantPortalConfigContext.Provider>
    );
};
