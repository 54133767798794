import {
    getTaxProfilesIdsWithFiledFilingsForAccount,
    postGetFiledFilingsForAccount,
    postGetFilingFilterOptions,
} from "@davo/portal-common/lib/services";
import {
    AccountRecord,
    FilingWithAttachmentHistoryAndLocations,
    IFilingOptionsFilters,
    PeriodFilterType,
    TaxProfile,
} from "@davo/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useRef,
    useState,
} from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./AccountContext";

export interface IMerchantFilingFilterResultsContext {
    filingFilterResults: FilingWithAttachmentHistoryAndLocations[] | undefined;
    historicalFilingResults: FilingWithAttachmentHistoryAndLocations[] | undefined;
    filters: IFilingOptionsFilters | undefined;
    periodEndOptions: PeriodFilterType[] | undefined;
    stateOptions: string[] | undefined;
    taxProfileOptions: TaxProfile[] | undefined;
    setFilters: Dispatch<SetStateAction<IFilingOptionsFilters | undefined>>;
    showHistorical: boolean;
    showHistoricalButton: boolean;
    setShowHistorical: Dispatch<SetStateAction<boolean>>;
    account: AccountRecord | undefined;
}

export const FilingFilterResultsDefaultValue: IMerchantFilingFilterResultsContext = {
    filingFilterResults: [],
    historicalFilingResults: [],
    filters: undefined,
    setFilters: () => undefined,
    showHistorical: false,
    showHistoricalButton: false,
    setShowHistorical: () => undefined,
    stateOptions: undefined,
    taxProfileOptions: undefined,
    periodEndOptions: undefined,
    account: undefined,
};

export const FilingFilterResultsContext = createContext(FilingFilterResultsDefaultValue);
export const useMerchantFilingFilterResultsContext = () => useContext(FilingFilterResultsContext);

const sortedOptions = (options: PeriodFilterType[]) => {
    return options.sort((x: PeriodFilterType, y: PeriodFilterType) => {
        if (x.end > y.end) {
            return -1;
        } else if (x.end < y.end) {
            return 1;
        }
        return 0;
    });
};

export const MerchantFilingsFilterResultsContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { taxProfiles, account } = useAccountContext();

    const [stateOptions, setStateOptions] = useState<string[] | undefined>([]);
    const [periodEndOptions, setPeriodEndOptions] = useState<PeriodFilterType[] | undefined>([]);
    const [historicalFilingResults, setHistoricalFilingResults] = useState<
        FilingWithAttachmentHistoryAndLocations[] | undefined
    >(undefined);
    const [showHistorical, setShowHistorical] = useState<boolean>(false);
    const [showHistoricalButton, setShowHistoricalButton] = useState<boolean>(false);
    const [filters, setFilters] = useState<IFilingOptionsFilters>();
    const abortControllerRef = useRef<AbortController>();
    const [taxProfilesForFilter, setTaxProfilesForFilter] = useState<TaxProfile[]>(taxProfiles ?? []);
    const [filingFilterResults, setFilingFilterResults] = useState<FilingWithAttachmentHistoryAndLocations[]>();

    // Gets Filing Filter Options
    useAsyncEffect(async () => {
        if (isNil(account) || isNil(taxProfiles)) {
            return;
        }
        let tpsForFilter: TaxProfile[] = [];
        tpsForFilter = await getTaxProfilesIdsWithFiledFilingsForAccount(account).then(
            (tpIds: string[]) =>
                taxProfiles
                    ?.filter((tp: TaxProfile) => tpIds.indexOf(tp.id) !== -1)
                    .sort((a: TaxProfile, b: TaxProfile) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) ?? []
        );
        setTaxProfilesForFilter(tpsForFilter);
        if (!isEmpty(tpsForFilter)) {
            const filterOptions = await postGetFilingFilterOptions(
                account,
                tpsForFilter.map((tp) => tp.id),
                "filed"
            );
            const sortedPeriodEndOptions: PeriodFilterType[] = sortedOptions(filterOptions.periodFilter);
            setPeriodEndOptions(sortedPeriodEndOptions);
            setStateOptions(filterOptions.state);
            setFilters({
                state: undefined,
                taxProfile: undefined,
                periodFilter: sortedPeriodEndOptions[0],
            });
        }
    }, [account, taxProfiles]);

    // Gets Filings For Current Filter
    useAsyncEffect(async () => {
        if (isNil(filters) || isNil(account)) {
            return;
        }
        abortControllerRef.current?.abort(`Abort request in favor of new search filter`);
        abortControllerRef.current = new AbortController();

        await postGetFiledFilingsForAccount(
            account,
            {
                state: filters.state,
                periodFilter: filters.periodFilter ? filters.periodFilter : undefined,
                taxProfile: filters.taxProfile,
            },
            abortControllerRef.current
        )
            .then((filings: FilingWithAttachmentHistoryAndLocations[]) => {
                if (filings.some((filing) => filing.historical)) {
                    setShowHistoricalButton(true);
                    setHistoricalFilingResults(filings.filter((filing) => filing.historical));
                }
                setFilingFilterResults(filings.filter((filing) => !filing.historical));
            })
            .catch((e: any) => {
                if (e.name === "AbortError") {
                    console.debug(e.message); // eslint-disable-line no-console
                    return [];
                }
            });
    }, [filters]);

    useAsyncEffect(async () => {}, [filingFilterResults]);

    return (
        <FilingFilterResultsContext.Provider
            value={{
                filingFilterResults: filingFilterResults,
                historicalFilingResults: historicalFilingResults,
                filters: filters,
                setFilters: setFilters,
                periodEndOptions: periodEndOptions,
                showHistorical: showHistorical,
                showHistoricalButton: showHistoricalButton,
                setShowHistorical: setShowHistorical,
                taxProfileOptions: taxProfilesForFilter,
                stateOptions: stateOptions,
                account: account,
            }}>
            {children}
        </FilingFilterResultsContext.Provider>
    );
};
