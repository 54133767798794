import { BankAccount } from "@davo/types";
import React from "react";

interface ISettingsSingleLocationBankAccountContentProps {
    bankAccount?: BankAccount | null;
}

export const SettingsSingleLocationBankAccountCardContent: React.FC<ISettingsSingleLocationBankAccountContentProps> = ({
    bankAccount,
}) => {
    if (!bankAccount) {
        return null;
    }

    const bankName = `${bankAccount.nickname ? bankAccount.nickname + " - " : ""}${bankAccount.name ?? "None"}`;
    return (
        <>
            <div className={"da-finance-item"}>
                <strong>Name:</strong> {bankName}
            </div>
            <div className={"da-finance-item"}>
                <strong>Account:</strong> {bankAccount.accountStarred ? bankAccount.accountStarred : "None"}
            </div>
        </>
    );
};
