import { LocationRecord } from "@davo/types";
import { Checkbox, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useAccountContext } from "../context";

export interface IAssociateBankToLocations {
    selectedLocations: LocationRecord[];
    handleSelectLocation(l: LocationRecord): void;
}

export const AssociateBankToLocations: FunctionComponent<IAssociateBankToLocations> = ({
    handleSelectLocation,
    selectedLocations,
}) => {
    const { locations } = useAccountContext();
    const [locationsWithBank, setLocationsWithBank] = useState<LocationRecord[]>();
    const [locationsWithoutBank, setLocationsWithoutBank] = useState<LocationRecord[]>();

    useEffect(() => {
        if (!locations) return;
        const activeLocations = locations.filter((l) => l.active);
        const withBank = activeLocations.filter((l) => !!l.bankAccountId);
        const withoutBank = activeLocations.filter((l) => !l.bankAccountId);
        setLocationsWithBank(withBank);
        setLocationsWithoutBank(withoutBank);
    }, [locations]);

    if (!locationsWithoutBank?.length) {
        return null;
    }

    return (
        <div data-testid={"associateBankToLocations"}>
            <List
                subheader={
                    <Typography variant={"h6"} component={"h3"}>
                        Locations without bank
                    </Typography>
                }>
                {locationsWithoutBank.map((l: LocationRecord) => {
                    return (
                        <ListItemButton
                            role={"listitem"}
                            key={`loc-${l.id}`}
                            data-testid={`loc-${l.id}`}
                            dense
                            divider
                            disabled={locationsWithBank?.includes(l)}
                            onClick={() => handleSelectLocation(l)}>
                            <ListItemIcon>
                                <Checkbox
                                    data-testid={`loc-${l.id}-cb`}
                                    inputProps={{ "aria-labelledby": l.id }}
                                    edge="start"
                                    checked={selectedLocations.includes(l)}
                                    tabIndex={-1}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={l.id}
                                primary={l.name}
                                secondary={locationsWithBank?.includes(l) && "Has bank"}
                            />
                        </ListItemButton>
                    );
                })}
            </List>
        </div>
    );
};
