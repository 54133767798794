import { ReactTable8, useModalEditor } from "@davo/portal-common";
import { BankAccount } from "@davo/types";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, Tooltip } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { FunctionComponent, useMemo } from "react";
import { useAccountContext } from "../context";
import { formatBankLabel } from "../util";
import { AssociateBankToLocationsModal } from "./AssociateBankToLocationsModal";
import { RenderBankName } from "./RenderBankName";

export interface IUnusedBankAccountsTable {
    setSelected: (resourceIdToDelete: string) => void;
    showHiddenConfirmationModal: (thing?: string | undefined) => void;
    unusedBankAccounts: BankAccount[];
}

export const UnusedBankAccountsTable: FunctionComponent<IUnusedBankAccountsTable> = ({
    setSelected,
    showHiddenConfirmationModal,
    unusedBankAccounts,
}) => {
    const { refresh, locations } = useAccountContext();
    const [showAssociateBankToLocationsModal, associateBankToLocationsModalProps] = useModalEditor<string>(async () => {
        await refresh();
    });

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<BankAccount>();

        return [
            columnHelper.accessor("name", {
                header: "Name",
                cell: (data) => {
                    const ba = data.row.original;
                    return <RenderBankName bankAccount={ba} />;
                },
            }),
            columnHelper.accessor("routing", {
                header: "",
                enableSorting: false,
            }),
            columnHelper.accessor("accountStarred", {
                header: "",
                cell: (data) => <span className="fs-exclude">{data.row.original.accountStarred}</span>,
                enableSorting: false,
            }),
            columnHelper.display({
                id: "actions",
                header: "",
                cell: (data) => {
                    const bankAccount = data.row.original;
                    return (
                        <div>
                            <Tooltip title="Manage Location Associations" placement={"top"}>
                                <span>
                                    <IconButton
                                        data-testid={"associateBankBtn"}
                                        disabled={!locations?.filter((l) => l.active && !l.bankAccountId).length}
                                        style={{ marginRight: "18px" }}
                                        size="small"
                                        onClick={() => showAssociateBankToLocationsModal(bankAccount.id)}>
                                        <LinkIcon stroke={"currentColor"} strokeWidth={0.3} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                title={`Delete ${formatBankLabel({
                                    ba: bankAccount,
                                    withStarredPrefix: false,
                                })}`}
                                placement={"top"}>
                                <IconButton
                                    data-testid={"deleteBankBtn"}
                                    size="small"
                                    onClick={() => {
                                        setSelected(bankAccount.id);
                                        showHiddenConfirmationModal(bankAccount.id);
                                    }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                },
                enableSorting: false,
            }),
        ];
    }, [locations, showAssociateBankToLocationsModal, setSelected, showHiddenConfirmationModal]);

    return (
        <div data-testid={"unusedBankAccountsContainer"}>
            <ReactTable8<BankAccount>
                columns={columns as ColumnDef<BankAccount>[]}
                options={{
                    hideToolbar: true,
                }}
                renderRowSubComponent={undefined}
                data={unusedBankAccounts}
            />

            {associateBankToLocationsModalProps.isDialogOpen && (
                <AssociateBankToLocationsModal {...associateBankToLocationsModalProps} />
            )}
        </div>
    );
};
