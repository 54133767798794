import {
    ConfirmationModal,
    d30Toast,
    d30ToastError,
    expireCurrentInvitation,
    getUserPermissionsForAccount,
    inviteUser,
    ReactTable8,
    removeAccountPermissions,
    removeUserInvite,
    TextField,
    updateAccountEmailPreferences,
    updateAccountPermissions,
    updateUserSummaryPreferences,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    isEmailValid,
    IUserAndPermissions,
    toDisplayDateString,
    UserInvitation,
    UserRole,
    validateEmail,
} from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch as MaterialSwitch,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";
import { getUserInvitationsForAccount } from "./services/invitation";

const createDisplayName = (userRoleObj: UserRole) => {
    const displayNameParts = [userRoleObj.firstName];
    !!userRoleObj.lastName && displayNameParts.push(userRoleObj.lastName);
    return displayNameParts.join(" ");
};

const createAccessibleLabel = (u: CellContext<IUserAndPermissions, any>, controlType: "toggle" | "button") => {
    return `${u.cell.column.columnDef.header} ${controlType} for ${createDisplayName(u.row.original)}`;
};

const isInvitationExpired = (inviteArg: UserInvitation) => {
    return inviteArg.expires <= DateTime.now();
};

export function Users() {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [users, setUsers] = useState<IUserAndPermissions[]>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [canInviteAsAdmin, setCanInviteAsAdmin] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<UserInvitation[]>();
    const [inactives, setInactives] = useState<UserInvitation[]>();

    const [showConfirmationModal, confirmationModalProps] = useModalEditor<string>((userId?: string) => {
        if (!userId || !accountContext.account) {
            return;
        }
        // If userId is defined then delete the account access and show a success message
        removeAccountPermissions(userId, accountContext.account.id)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("User removed from account.");
                    })
                    .catch((e) => {
                        d30ToastError("Unable to remove user from account.", e);
                    });
            })
            .catch((e) => {
                d30ToastError("Unable to remove user from account.", e);
            });
    });

    const refresh = useCallback(async () => {
        if (!accountContext.account) {
            return;
        }

        const usersPromise = getUserPermissionsForAccount(accountContext.account.id);
        const userInvitationsPromise = getUserInvitationsForAccount(accountContext.account.id);
        const [uList, invitationList] = await Promise.all([usersPromise, userInvitationsPromise]);
        setUsers(uList);

        const expd: UserInvitation[] | ((prevState: never[]) => never[]) = [];
        const unexpired: any[] | ((prevState: UserInvitation[]) => UserInvitation[]) = [];
        for (const i of invitationList) {
            if (i.expires > DateTime.now()) {
                unexpired.push(i);
            } else {
                expd.push(i);
            }
        }
        setInactives(expd);
        setInvitations(unexpired);
    }, [accountContext.account]);

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        await refresh();
    }, [accountContext.account, refresh]);

    useEffect(() => {
        if (!loginContext.user || !users) {
            return;
        }
        const currentUser = users.filter((u: IUserAndPermissions) => u.id === loginContext.user?.id);
        if (loginContext.user?.type === "Admin" || loginContext.user?.type === "Superuser") {
            setIsAdmin(true);
        } else {
            setIsAdmin(
                currentUser.length === 1 && currentUser[0].type === "Merchant" && currentUser[0].role === "admin"
            );
        }
    }, [loginContext.user, users]);

    const removeInvite = useCallback(
        (inviteId: string) => {
            if (!accountContext.account) {
                return;
            }
            removeUserInvite(accountContext.account.id, inviteId)
                .then(() => {
                    refresh()
                        .then(() => {
                            d30Toast("Done");
                        })
                        .catch((e) => {
                            d30ToastError("Problem removing user invite.", e);
                        });
                })
                .catch((e) => d30ToastError("Problem removing user invite.", e));
        },
        [accountContext.account, refresh]
    );

    const invite = useCallback(
        async (emailArg?: string, invitationId?: string) => {
            if (!accountContext.account || !invitations) {
                return;
            }
            let thisEmail = "";
            if (emailArg && invitationId) {
                thisEmail = emailArg;
            } else {
                thisEmail = email ?? "";
            }
            if (thisEmail === "") {
                return;
            }
            const thisInvite = invitations.find((i) => i.originalEmail === thisEmail);

            if (thisInvite?.id && !isInvitationExpired(thisInvite)) {
                await expireCurrentInvitation(accountContext.account.id, thisInvite.id);
                removeInvite(thisInvite.id);
            }
            await inviteUser(accountContext.account.id, thisEmail, canInviteAsAdmin ? "admin" : "user");
            if (emailArg && invitationId) {
                removeInvite(invitationId);
            }
            await refresh();
            setCanAdd(false);
            setEmail(undefined);
            d30Toast("User invite sent!");
        },
        [accountContext.account, canInviteAsAdmin, email, invitations, refresh, removeInvite]
    );

    const sendInvitePressed = useCallback(
        async (id?: string) => {
            if (!inactives || !invitations) {
                return;
            }
            if (id) {
                const em = inactives.find((i) => i.id === id)?.originalEmail;
                if (em) {
                    return invite(em, id);
                }
            }
            if (!isAdmin || !email || !!validateEmail(email)) {
                return;
            }
            if (invitations.filter((i) => i.originalEmail === email && i.expires > DateTime.now()).length > 0) {
                d30ToastError("Cannot send invitation while one is already active!");
                return;
            }
            await invite(accountContext.account?.id);
        },
        [accountContext.account?.id, email, inactives, invitations, invite, isAdmin]
    );

    const isOwnAccount = (rowUserId: string, globalUserId?: string) => rowUserId === globalUserId;

    const isLastAdmin = useCallback(
        (rowUser: IUserAndPermissions) => {
            if (!users) {
                return;
            }
            return rowUser.role === "admin" && users.filter((u) => u.role === "admin").length === 1;
        },
        [users]
    );

    const columnsUsers = useMemo(() => {
        const removeUser = (userId: string) => {
            showConfirmationModal(userId);
        };

        const handleRoleChange = (userId: string, oldRole: string) => () => {
            if (!accountContext.account) {
                return;
            }
            let newRole = "user";
            if (oldRole === "user") {
                newRole = "admin";
            }
            updateAccountPermissions(userId, accountContext.account.id, newRole)
                .then(() => {
                    refresh()
                        .then(() => {
                            d30Toast("User permissions updated!");
                        })
                        .catch((e) => {
                            d30ToastError("Could Not Update Account Permissions.", e);
                        });
                })
                .catch((e) => {
                    d30ToastError("Could Not Update Account Permissions.", e);
                });
        };

        const handleEmailOptOutChange = (userId: string, isOldEmailOptOut: boolean) => () => {
            if (!accountContext.account) {
                return;
            }
            updateAccountEmailPreferences(userId, accountContext.account.id, !isOldEmailOptOut)
                .then(() => {
                    refresh()
                        .then(() => {
                            d30Toast("User email preferences updated!");
                        })
                        .catch((e) => {
                            d30ToastError("Could Not Update Email Preferences.", e);
                        });
                })
                .catch((e) => {
                    d30ToastError("Could Not Update Email Preferences.", e);
                });
        };

        const handleSummaryOptInChange = (userId: string, isOldSummaryOptIn: boolean) => () => {
            if (!accountContext.account) {
                return;
            }
            updateUserSummaryPreferences(userId, accountContext.account.id, !isOldSummaryOptIn)
                .then(() => {
                    refresh()
                        .then(() => {
                            d30Toast("User summary preferences updated!");
                        })
                        .catch((e) => {
                            d30ToastError("Could Not Update Summary Preferences.", e);
                        });
                })
                .catch((e) => {
                    d30ToastError("Could Not Update Summary Preferences.", e);
                });
        };

        const columnHelper = createColumnHelper<IUserAndPermissions>();

        return [
            columnHelper.accessor("firstName", {
                header: "Name",
                cell: (u) => (
                    <div className="fs-exclude">
                        <span id={u.row.original.id}>{createDisplayName(u.row.original)}</span>
                        <br />
                        <span style={{ fontSize: ".9em" }}>{u.row.original.email}</span>
                    </div>
                ),
            }),
            columnHelper.accessor("role", {
                header: "Admin",
                cell: (u) => (
                    <MaterialSwitch
                        disabled={
                            !isAdmin ||
                            isOwnAccount(u.row.original.id, loginContext.user?.id) ||
                            isLastAdmin(u.row.original)
                        }
                        checked={u.cell.row.original.role === "admin"}
                        value="isAdmin"
                        color="primary"
                        data-testid={`isAdminRoleToggle-${u.row.original.id}`}
                        aria-label={createAccessibleLabel(u, "toggle")}
                        onChange={handleRoleChange(u.row.original.id, u.row.original.role)}
                    />
                ),
            }),
            columnHelper.accessor("emailOptOut", {
                header: "Receiving daily sales tax email",
                cell: (u) => (
                    <Tooltip
                        title={
                            "This email includes information about sales and sales tax for location(s) associated with a given bank account."
                        }
                        placement="top">
                        <MaterialSwitch
                            disabled={!isAdmin && !isOwnAccount(u.row.original.id, loginContext.user?.id)}
                            checked={!u.getValue()}
                            color="primary"
                            data-testid={`dailySalesTaxEmailToggle-${u.row.original.id}`}
                            aria-label={createAccessibleLabel(u, "toggle")}
                            onChange={handleEmailOptOutChange(u.row.original.id, u.getValue())}
                        />
                    </Tooltip>
                ),
            }),
            columnHelper.accessor("summaryOptIn", {
                header: "Receiving daily summary email",
                cell: (u) => (
                    <Tooltip
                        title={
                            "This email includes information about sales and set aside amounts for all locations associated with this account."
                        }
                        placement="top">
                        <MaterialSwitch
                            disabled={!isAdmin && !isOwnAccount(u.row.original.id, loginContext.user?.id)}
                            checked={u.getValue()}
                            color="primary"
                            data-testid={`dailySummaryEmailToggle-${u.row.original.id}`}
                            aria-label={createAccessibleLabel(u, "toggle")}
                            onChange={handleSummaryOptInChange(u.row.original.id, u.getValue())}
                        />
                    </Tooltip>
                ),
            }),
            columnHelper.accessor("id", {
                header: "",
                cell: (u) => (
                    <Button
                        disabled={
                            !isAdmin ||
                            isOwnAccount(u.row.original.id, loginContext.user?.id) ||
                            isLastAdmin(u.row.original)
                        }
                        onClick={() => removeUser(u.row.original.id)}
                        variant="outlined"
                        color="primary"
                        data-testid={`removeUserBtn-${u.row.original.id}`}
                        aria-describedby={u.row.original.id}
                        size="small">
                        Remove
                    </Button>
                ),
                enableSorting: false,
            }),
            columnHelper.accessor("lastName", {
                header: "",
            }),
            columnHelper.accessor("email", {
                header: "",
            }),
        ];
    }, [accountContext.account, isAdmin, isLastAdmin, loginContext.user?.id, refresh, showConfirmationModal]);

    const columnsInvitations = useMemo(() => {
        const columnHelper = createColumnHelper<UserInvitation>();

        return [
            columnHelper.accessor("originalEmail", {
                header: "Invitation Email",
                cell: (t) => <span className="fs-exclude">{t.getValue()}</span>,
            }),
            columnHelper.accessor("expires", {
                header: "Expiration Date",
                cell: (t) => (
                    <span style={t.getValue() < DateTime.now() ? { color: "red" } : { color: "primary" }}>
                        {toDisplayDateString(t.getValue())}
                    </span>
                ),
            }),
            columnHelper.accessor("id", {
                header: "",
                cell: (t) => (
                    <Button onClick={() => removeInvite(t.getValue())} variant="outlined" color="primary" size="small">
                        Remove
                    </Button>
                ),
            }),
        ];
    }, [removeInvite]);

    const columnsExpired = useMemo(() => {
        const columnHelper = createColumnHelper<UserInvitation>();

        return [
            columnHelper.accessor("originalEmail", {
                header: "Invitation Email",
                cell: (t) => <span className="fs-exclude">{t.getValue()}</span>,
            }),
            columnHelper.accessor("expires", {
                header: "Expiration Date",
                cell: (t) => (
                    <span style={t.getValue() < DateTime.now() ? { color: "red" } : { color: "primary" }}>
                        {toDisplayDateString(t.getValue())}
                    </span>
                ),
            }),
            columnHelper.accessor("id", {
                header: "",
                cell: (t) => (
                    <Button
                        onClick={() => sendInvitePressed(t.getValue())}
                        variant="contained"
                        color="primary"
                        size="small">
                        Resend Invitation
                    </Button>
                ),
            }),
        ];
    }, [sendInvitePressed]);

    if (!accountContext.account || !users || !invitations || !inactives) {
        return null;
    }

    return (
        <>
            <Accordion
                data-testid={"usersAccordion"}
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ boxShadow: "none", marginBottom: "unset" }}
                defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Users Details
                </AccordionSummary>
                {isAdmin && (
                    <div style={{ textAlign: "right" }}>
                        <Button
                            data-testid={"addUserInvitationLink"}
                            size={"small"}
                            variant={"contained"}
                            onClick={() => {
                                setCanAdd(true);
                            }}>
                            Add User
                        </Button>
                    </div>
                )}

                <ReactTable8<IUserAndPermissions>
                    columns={columnsUsers}
                    data={users}
                    options={{
                        hiddenColumns: ["lastName", "email"],
                    }}
                />
            </Accordion>

            {invitations.length > 0 && (
                <Accordion
                    data-testid={"pendingInvitationsAccordion"}
                    slotProps={{ transition: { unmountOnExit: true } }}
                    style={{ boxShadow: "none" }}
                    defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Pending Invitations
                    </AccordionSummary>
                    <ReactTable8<UserInvitation> columns={columnsInvitations} data={invitations} />
                </Accordion>
            )}

            {inactives.length > 0 && (
                <Accordion
                    data-testid={"inactiveUsersAccordion"}
                    slotProps={{ transition: { unmountOnExit: true } }}
                    style={{ boxShadow: "none" }}
                    defaultExpanded={false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Expired Invitations
                        <Tooltip
                            title={"Invitations that have expired in the last 30 days will appear here. "}
                            placement="right-end">
                            <HelpTwoToneIcon fontSize="small" color="info" style={{ marginLeft: "10px" }} />
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex", justifyContent: "space-around" }}>
                        <ReactTable8<UserInvitation>
                            title={"Expired Invitations"}
                            columns={columnsExpired}
                            data={inactives}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
            {canAdd && (
                <Dialog
                    data-testid={"addUserInvitationModal"}
                    fullScreen={makeFullScreen}
                    open={true}
                    onClose={() => setCanAdd(false)}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}>
                    <DialogTitle id="responsive-dialog-title">Invite user</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the email of the person you would like to invite. We will send them an invitation with
                            a link to connect.
                        </DialogContentText>
                        <TextField
                            className="fs-exclude"
                            label="Email Address"
                            isDisabled={!isAdmin}
                            value={email ?? ""}
                            onChange={setEmail}
                            validate={validateEmail}
                            onEnterPress={sendInvitePressed}
                            inputProps={{
                                [`data-testid`]: "userInvitationEmail",
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: "0px", color: "rgba(0, 0, 0, 0.54)" }}
                            control={
                                <MaterialSwitch
                                    checked={canInviteAsAdmin}
                                    value="inviteAdmin"
                                    color="primary"
                                    onChange={() => setCanInviteAsAdmin(!canInviteAsAdmin)}
                                />
                            }
                            label="Invite as admin?"
                            labelPlacement="start"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCanAdd(false)} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            data-testid={"inviteUserButton"}
                            disabled={!isAdmin || !isEmailValid(email)}
                            onClick={() => invite()}
                            variant="contained"
                            color="primary">
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {confirmationModalProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to remove this user's access to your account?"
                    title="Remove user?"
                    {...confirmationModalProps}
                />
            )}
        </>
    );
}
