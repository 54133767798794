import { useModalEditor } from "@davo/portal-common";
import { Button, ButtonOwnProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useAccountContext } from "../context";
import { AddBankAccountModal } from "./AddBankAccountModal";
import { AssociateBankToLocationsModal } from "./AssociateBankToLocationsModal";

export interface IAddBankButton extends ButtonOwnProps {
    isPrimary: boolean;
    showSuccessToast: boolean;
    prefLabel?: string;
}

export const AddBankButton: FunctionComponent<IAddBankButton> = ({
    isPrimary,
    showSuccessToast,
    prefLabel,
    ...props
}) => {
    const { refresh, locations } = useAccountContext();

    const [showAssociateBankToLocationsModal, associateBankToLocationsModalProps] = useModalEditor<string>(() =>
        refresh()
    );

    const [showAddBankModal, addBankModalProps] = useModalEditor<string>(async (addedBankAccountId) => {
        if (!addedBankAccountId) return;
        await refresh();

        const activeLocsWithoutBank = locations?.filter((l) => l.active && !l.bankAccountId);
        if (activeLocsWithoutBank?.length) {
            showAssociateBankToLocationsModal(addedBankAccountId);
        }
    });

    return (
        <>
            <Button
                data-testid={"launchAddBankModalBtn"}
                variant={isPrimary ? "contained" : "outlined"}
                onClick={() => showAddBankModal()}
                size="small"
                {...props}>
                {prefLabel ?? "Add Bank Account"}
            </Button>

            {addBankModalProps.isDialogOpen && (
                <AddBankAccountModal
                    showTermsAndConditions={false}
                    showSuccessToast={showSuccessToast}
                    {...addBankModalProps}
                />
            )}

            {associateBankToLocationsModalProps.isDialogOpen && (
                <AssociateBankToLocationsModal {...associateBankToLocationsModalProps} />
            )}
        </>
    );
};
