import { getSubscriptionsForAccount, ReactTable8, useModalEditor } from "@davo/portal-common";
import { ISubscriptionAndType, moneyFromCents, toDisplayDateString } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";

const renderStartDate = (data: any) => {
    return <>{toDisplayDateString(data.getValue())}</>;
};

const renderChargeAmount = (data: any) => {
    return <>{moneyFromCents(data.getValue())}</>;
};

export function AccountSubscriptionsModal({ accountId }: { accountId: string }) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [subscriptions, setSubscriptions] = useState<(ISubscriptionAndType & { locationName: string })[]>();

    const loadSubscriptions = useCallback(async () => {
        const subs = await getSubscriptionsForAccount(accountId);
        setSubscriptions(subs);
    }, [accountId]);

    useAsyncEffect(async () => {
        if (!isDialogOpen) {
            return;
        }
        await loadSubscriptions();
    }, [loadSubscriptions, isDialogOpen]);

    const shutdown = () => {
        closeDialog();
    };

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<ISubscriptionAndType & { locationName: string }>();

        return [
            columnHelper.accessor("startDate", {
                header: "Start date",
                cell: renderStartDate,
            }),
            columnHelper.accessor("type.description", {
                header: "Description",
            }),
            columnHelper.accessor("type.charge", {
                header: "Charge",
                cell: renderChargeAmount,
            }),
            columnHelper.accessor("locationName", {
                header: "Location",
            }),
        ];
    }, []);

    return (
        <div data-testid={"subscription-list"}>
            <Button
                data-testid={"subscription-dialog-button"}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary"
                style={{ marginBottom: "2px", marginTop: "2px" }}>
                {"Subscriptions"}
            </Button>
            {isDialogOpen && subscriptions && (
                <Dialog
                    data-testid={"subscription-dialog"}
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={() => {
                        shutdown();
                    }}>
                    <DialogTitle id="responsive-dialog-title">Subscriptions</DialogTitle>
                    <DialogContent>
                        <ReactTable8<ISubscriptionAndType & { locationName: string }>
                            columns={columns}
                            data={subscriptions}
                            options={{
                                hideToolbar: true,
                                pageSize: 10,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
