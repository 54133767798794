import { FormError, TextField } from "@davo/portal-common";
import {
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
} from "@davo/types";
import { Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useMerchantPortalConfigContext } from "../context";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function LightspeedLSeriesBoarding() {
    const classes = useStyles();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();

    useEffect(() => {
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    const doCall = () => {
        if (isEmailValid(email) && isPhoneValid(phone)) {
            setMessage(undefined);
            const passThrough = Buffer.from(`{"email": "${email}", "phone": "${phone}"}`).toString("base64");
            window.location.replace(
                `${configInfo.lightspeedLSeriesBaseURL}/resto/oauth2/v1/authorize?response_type=code&client_id=${configInfo.lightspeedLSeriesKey}&code_challenge=${configInfo.lightspeedLSeriesChallenge}&code_challenge_method=S256&state=${passThrough}`
            );
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"lightspeed-l-series"}>
            <TextField
                label="Phone"
                inputProps={{
                    [`data-testid`]: "phoneInput",
                }}
                value={phone ?? ""}
                onChange={setPhone}
                validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                onEnterPress={doCall}
                margin={"dense"}
                isRequired
            />
            <TextField
                label="Email"
                inputProps={{
                    [`data-testid`]: "emailInput",
                }}
                value={email ?? ""}
                validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                onChange={setEmail}
                onEnterPress={doCall}
                isRequired
                margin={"dense"}
            />
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doCall}
                    data-testid={"submitBtn"}
                    disabled={!email || !phone}>
                    Connect with Lightspeed L-Series
                </Button>
            </div>
        </BoardingContainer>
    );
}
