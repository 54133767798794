import {
    getLocationsEnhanced,
    HiddenConfirmation,
    ReactTable8,
    TaxProfileAdd,
    TaxProfileEdit,
    useModalEditor,
} from "@davo/portal-common";
import { FrequencyLabels, Location, TaxProfile } from "@davo/types";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
    Button,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { CellContext, createColumnHelper, Row } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { TaxProfileStatus } from "./components";
import { useAccountContext } from "./context";
import { MultiLocationSelector } from "./MultiLocationSelector";
import { SettingsTaxProfileEmptyState } from "./SettingsTaxProfileEmptyState";

const renderFrequency = (data: CellContext<TaxProfile, any>) => {
    return <>{FrequencyLabels[data.getValue()]}</>;
};

const renderStatus = (data: CellContext<TaxProfile, any>) => {
    return <TaxProfileStatus taxProfile={data.cell.row.original} />;
};

export function SettingsTaxProfile() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();

    const [locations, setLocations] = useState<Location[]>();
    const [activeTaxProfiles, setActiveTaxProfiles] = useState<TaxProfile[]>();
    const [unusedTaxProfiles, setUnusedTaxProfiles] = useState<TaxProfile[]>();

    const [showUnused, setShowUnused] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const [taxProfilesToAssociate, setTaxProfilesToAssociate] = useState<TaxProfile[]>([]);

    const refresh = useCallback(async () => {
        if (!accountContext.taxProfiles || !accountContext.account) {
            return;
        }
        const lList = await getLocationsEnhanced(accountContext.account.id);
        setLocations(lList);

        const active: TaxProfile[] = [];
        let unused: TaxProfile[] = [];

        accountContext.taxProfiles.forEach((tp) => {
            lList.some((loc) => loc.active && loc.taxProfileIds.includes(tp.id)) ? active.push(tp) : unused.push(tp);
        });

        unused = unused.filter((x: TaxProfile) => !x.hidden);
        setActiveTaxProfiles(active);
        setUnusedTaxProfiles(unused);
    }, [accountContext.account, accountContext.taxProfiles]);

    useAsyncEffect(async () => {
        await refresh();
    }, [accountContext.taxProfiles, accountContext.account, refresh]);

    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>(async () => {
        await accountContext.refresh();
    });

    const [
        showAddNewTaxProfileModal,
        { isDialogOpen: isAddNewTaxProfileModalOpen, closeDialog: closeAddNewTaxProfileModal },
    ] = useModalEditor(() => {
        showAssociateLocToTpModal(taxProfilesToAssociate);
    });

    const [
        showAssociateLocToTpModal,
        { isDialogOpen: isAssociateLocToTpModalOpen, closeDialog: closeAssociateLocToTpModal },
    ] = useModalEditor();

    const [showHiddenConfirmation, hiddenConfirmationProps] = useModalEditor<string>(async () => {
        await accountContext.refresh();
    });

    const renderActionButtons = useCallback(
        (data: CellContext<TaxProfile, any>) => (
            <div style={{ whiteSpace: "nowrap" }}>
                <Tooltip title="Manage Location Associations">
                    <IconButton
                        style={{ marginRight: "18px" }}
                        size="small"
                        onClick={() => {
                            const tps = accountContext.taxProfiles?.filter((p) => p.id === data.getValue());
                            setTaxProfilesToAssociate(tps ?? []);
                            showAssociateLocToTpModal();
                        }}>
                        <LinkIcon stroke={"currentColor"} strokeWidth={0.3} />
                    </IconButton>
                </Tooltip>
                <Button variant="outlined" size="small" onClick={() => showTaxProfileEdit([data.getValue()])}>
                    Edit
                </Button>
            </div>
        ),
        [accountContext.taxProfiles, showAssociateLocToTpModal, showTaxProfileEdit]
    );

    const renderLink = useCallback(
        (name: string, locId: string) => {
            const LinkButton = (
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Button
                        style={{ marginRight: "12px" }}
                        variant={"text"}
                        color={"primary"}
                        size="small"
                        onClick={() => navigate(`/${accountContext.account?.id}/settings/company/${locId}`)}>
                        Manage
                    </Button>
                </div>
            );

            return <Tooltip title={`Manage ${name}`}>{LinkButton}</Tooltip>;
        },
        [accountContext.account?.id, navigate]
    );

    const getDetailPaneData = useCallback(
        (row: Row<TaxProfile>) => {
            if (!locations) {
                return;
            }
            const found = locations.filter(
                (loc: Location) => loc.taxProfileIds.includes(row.original.id) && loc.active
            );
            if (found.length > 0) {
                return (
                    <div style={{ padding: "32px" }}>
                        <Typography style={{ display: "inline-block", fontSize: "20px" }}>
                            <Tooltip title={`Locations associated with ${row.original.name}`} placement={"top"}>
                                <span>Locations associated with this tax profile</span>
                            </Tooltip>
                        </Typography>
                        <List sx={{ bgcolor: "background.paper" }} dense className={"da-row-expand-content"}>
                            {found.map((loc) => {
                                return (
                                    <ListItem
                                        key={loc.id}
                                        secondaryAction={renderLink(loc.name, loc.id)}
                                        sx={{
                                            boxShadow: 1,
                                            mb: "4px",
                                        }}>
                                        <ListItemAvatar>
                                            <LocationOnIcon />
                                        </ListItemAvatar>
                                        <ListItemText primary={loc.name} secondary={loc.address1} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                );
            } else {
                return null;
            }
        },
        [locations, renderLink]
    );

    const sharedColumns = {
        name: {
            header: () => (
                <Tooltip title={"Tax Profile Nickname"} placement={"top"}>
                    <span>{"Nickname"}</span>
                </Tooltip>
            ),
        },
        frequency: {
            header: () => (
                <Tooltip title={"The filing frequency"} placement={"top"}>
                    <span>{"Frequency"}</span>
                </Tooltip>
            ),
            cell: renderFrequency,
        },
        credentialsFailing: {
            header: () => <span>{"Status"}</span>,
            cell: renderStatus,
        },
        actionButtons: {
            header: "",
            cell: renderActionButtons,
            enableSorting: false,
        },
    };
    const columnsTPs = useMemo(() => {
        const columnHelper = createColumnHelper<TaxProfile>();

        return [
            columnHelper.accessor("id", {
                id: "expander", // Make sure expand columns have an ID
                header: "",
                cell: (data) => {
                    if (
                        locations?.some(
                            (loc: Location) => loc.taxProfileIds.includes(data.row.original.id) && loc.active
                        )
                    ) {
                        return (
                            <span
                                className={"mui-expand-toggle-container"}
                                onClick={() => data.row.toggleExpanded()}
                                style={{ paddingLeft: "8px" }}>
                                {data.row.getIsExpanded() ? (
                                    <span className={"expandLess"}>
                                        <ExpandLessIcon />
                                    </span>
                                ) : (
                                    <span className={"expandMore"}>
                                        <ExpandMoreIcon />
                                    </span>
                                )}
                            </span>
                        );
                    } else {
                        return null;
                    }
                },
                enableSorting: false,
            }),
            columnHelper.accessor("name", sharedColumns.name),
            columnHelper.accessor("frequency", sharedColumns.frequency),
            columnHelper.accessor("credentialsFailing", sharedColumns.credentialsFailing),
            columnHelper.accessor("id", sharedColumns.actionButtons),
        ];
    }, [locations, renderActionButtons]);
    const columnsUnusedTPs = useMemo(() => {
        const columnHelper = createColumnHelper<TaxProfile>();

        return [
            columnHelper.accessor("name", sharedColumns.name),
            columnHelper.accessor("frequency", sharedColumns.frequency),
            columnHelper.accessor("credentialsFailing", sharedColumns.credentialsFailing),
            columnHelper.accessor("id", {
                id: "actions",
                header: "",
                cell: (data) => (
                    <Tooltip title="Delete">
                        <IconButton
                            size="small"
                            disabled={false}
                            onClick={() => {
                                setSelected(data.row.original.id);
                                showHiddenConfirmation(data.row.original.id);
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ),
                enableSorting: false,
            }),
            columnHelper.accessor("id", sharedColumns.actionButtons),
        ];
    }, [renderActionButtons, showHiddenConfirmation]);

    if (!locations || !accountContext.account || !activeTaxProfiles || !unusedTaxProfiles) {
        return null;
    }

    return (
        <div>
            {activeTaxProfiles.length + unusedTaxProfiles.length === 0 ? (
                <SettingsTaxProfileEmptyState />
            ) : (
                <>
                    <Stack
                        style={{ marginBottom: "16px" }}
                        justifyContent={"space-between"}
                        direction="row"
                        spacing={2}>
                        <Typography variant={"h2"} style={{ display: "inline-block", fontSize: "20px" }}>
                            Tax Profile Details
                        </Typography>

                        <div>
                            <Button
                                size={"small"}
                                variant={"contained"}
                                onClick={() => {
                                    setTaxProfilesToAssociate([]);
                                    showAddNewTaxProfileModal();
                                }}>
                                Add Tax Profile
                            </Button>
                        </div>
                    </Stack>
                    <ReactTable8<TaxProfile>
                        columns={columnsTPs}
                        options={{
                            hideToolbar: true,
                            pageSize: 10,
                        }}
                        renderRowSubComponent={getDetailPaneData}
                        data={activeTaxProfiles}
                    />

                    <Stack style={{ margin: "16px 0" }} justifyContent={"space-between"} direction="row" spacing={2}>
                        {unusedTaxProfiles.length !== 0 && (
                            <FormGroup style={{ margin: "14px" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            data-testid={"show-unused-switch"}
                                            checked={showUnused}
                                            onChange={() => setShowUnused(!showUnused)}
                                        />
                                    }
                                    label="Show unassociated tax profiles"
                                />
                            </FormGroup>
                        )}
                    </Stack>

                    {showUnused && (
                        <ReactTable8<TaxProfile>
                            title="Unassociated Tax Profiles"
                            columns={columnsUnusedTPs}
                            options={{
                                hideToolbar: true,
                            }}
                            renderRowSubComponent={getDetailPaneData}
                            data={unusedTaxProfiles}
                        />
                    )}
                </>
            )}

            {isAddNewTaxProfileModalOpen && (
                <TaxProfileAdd
                    accountId={accountContext.account.id}
                    shouldLimitEdit={false}
                    target={undefined}
                    closeDialog={closeAddNewTaxProfileModal}
                    setTpsToAssociate={setTaxProfilesToAssociate}
                />
            )}
            {accountContext.account.id &&
                taxProfilesToAssociate &&
                !isEmpty(taxProfilesToAssociate) &&
                isAssociateLocToTpModalOpen && (
                    <MultiLocationSelector
                        locations={locations}
                        accountId={accountContext.account.id}
                        taxProfilesToAssociate={taxProfilesToAssociate}
                        handleClose={closeAssociateLocToTpModal}
                    />
                )}
            {taxProfileEditProps.isDialogOpen && (
                <TaxProfileEdit accountId={accountContext.account.id} {...taxProfileEditProps} />
            )}
            {hiddenConfirmationProps.isDialogOpen && (
                <HiddenConfirmation
                    accountId={accountContext.account.id}
                    taxProfileId={selected}
                    label={"tax profile"}
                    {...hiddenConfirmationProps}
                />
            )}
        </div>
    );
}
