import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, AlertTitle, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { AddBankButton } from "./components";
import bank from "./resources/davo_illustrations_network.png";

export const SettingsBankingEmptyState: FunctionComponent<{
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage?: string;
}> = ({ shouldRestrictMerchantEdit, restrictMerchantEditMessage }) => {
    return (
        <div>
            <Typography component={"h3"} variant={"h6"} gutterBottom textAlign={"center"}>
                You don&apos;t have any bank accounts
            </Typography>
            <Typography
                style={{
                    textAlign: "center",
                    fontSize: "14px",
                    maxWidth: "100%",
                    color: "#252631",
                    margin: "auto",
                    marginBottom: "30px",
                }}>
                Connect your bank account and we will set aside your sales tax daily and hold it in a secure account for
                you.
            </Typography>

            <img src={bank} style={{ maxWidth: "420px", margin: "0 auto 4px", display: "block" }} alt={"Bank"} />

            <Alert severity={"info"} sx={{ maxWidth: "600px", margin: "0 auto 16px" }}>
                <AlertTitle>Note:</AlertTitle>
                Sales tax withholding is run daily as an ACH transaction against this bank account. DAVO does not modify
                merchant processor batch deposits.
            </Alert>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <AddBankButton
                    data-testid={"connect-bank"}
                    disabled={shouldRestrictMerchantEdit}
                    prefLabel={"Connect Bank Account"}
                    isPrimary={true}
                    showSuccessToast={true}
                />

                {restrictMerchantEditMessage && (
                    <Tooltip title={restrictMerchantEditMessage} placement="top">
                        <InfoOutlinedIcon
                            fontSize="small"
                            color="primary"
                            sx={{
                                alignSelf: "center",
                                marginLeft: "4px",
                            }}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
