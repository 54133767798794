import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";
import React from "react";

export const ActionsRequiredMessage = ({ showBanner }: { showBanner: boolean }) => {
    if (showBanner) {
        return (
            <div
                data-testid="actions-required-message"
                style={{
                    border: "1px solid black",
                    marginTop: "15px",
                    marginBottom: "15px",
                    padding: "20px",
                }}>
                <WarningIcon color={"warning"} style={{ verticalAlign: "middle", marginRight: "10px" }} />{" "}
                <span style={{ verticalAlign: "middle" }}>Actions Required</span>
                <div>
                    <div style={{ marginTop: "20px" }}>
                        <Typography variant="body2" color="textSecondary">
                            For each location:
                        </Typography>
                        <ol>
                            <Typography variant="body2" color="textSecondary">
                                1. Please add or review your bank information. If your account is currently paused,
                                unpause it to begin daily set asides.
                            </Typography>
                        </ol>
                        <ol>
                            <Typography variant="body2" color="textSecondary">
                                2. Please add or review your tax profile. If you have made any changes to your login
                                credentials or filing frequency please update them.
                            </Typography>
                        </ol>
                        <ol>
                            <Typography variant="body2" color="textSecondary">
                                3. If you are now filing in an additional jurisdiction, such as a new state, please add
                                this information to a new tax profile.
                            </Typography>
                        </ol>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
