import Select from "@davo/portal-common/lib/Select";
import { Button, FormControlLabel, Typography } from "@mui/material";
import find from "lodash/find";
import React from "react";
import { useMerchantFilingFilterResultsContext } from "../context";

export function MerchantFilingsFilter() {
    const {
        filters,
        taxProfileOptions,
        stateOptions,
        periodEndOptions,
        setFilters,
        showHistoricalButton,
        showHistorical,
        setShowHistorical,
    } = useMerchantFilingFilterResultsContext();
    const taxProfileNameFormat = (taxProfileId: string) => {
        const tp = find(taxProfileOptions, { id: taxProfileId });
        if (!tp) {
            return "";
        }

        return tp.name;
    };

    return (
        <div data-testid={"filingFilter"} style={{ marginBottom: "20px" }}>
            {filters && periodEndOptions && stateOptions && (
                <div data-testid={"filterSelectors"} style={{ alignItems: "center", display: "inline-flex" }}>
                    <Typography style={{ marginRight: "20px" }} variant="body1">
                        Filters:
                    </Typography>
                    <FormControlLabel
                        control={
                            <Select<string>
                                data-testid={"periodEndSelector"}
                                title="Period"
                                style={{}}
                                noneLabel={"None"}
                                options={periodEndOptions.map((option) => option.label)}
                                value={filters.periodFilter?.label}
                                onChange={(value) => {
                                    setFilters({
                                        ...filters,
                                        periodFilter:
                                            periodEndOptions.find((option) => option.label === value) ?? undefined,
                                    });
                                }}
                            />
                        }
                        label={""}
                    />
                    {stateOptions && stateOptions.length > 1 && (
                        <FormControlLabel
                            control={
                                <Select<string>
                                    data-testid={"stateSelector"}
                                    title="State"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={stateOptions}
                                    value={filters.state}
                                    onChange={(value) => {
                                        setFilters({ ...filters, state: value ?? undefined });
                                    }}
                                />
                            }
                            label={""}
                        />
                    )}
                    {taxProfileOptions && taxProfileOptions.length > 1 && (
                        <FormControlLabel
                            control={
                                <Select<string>
                                    data-testid={"taxProfileSelector"}
                                    title="Tax Profile"
                                    style={{}}
                                    noneLabel={"None"}
                                    label={(value) => {
                                        return taxProfileNameFormat(value);
                                    }}
                                    options={taxProfileOptions ? taxProfileOptions.map((tp) => tp.id) : []}
                                    value={filters.taxProfile}
                                    onChange={(value) => {
                                        setFilters({ ...filters, taxProfile: value ?? undefined });
                                    }}
                                />
                            }
                            label={""}
                        />
                    )}

                    {showHistoricalButton && (
                        <Button
                            data-testid={"viewHistorical"}
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => setShowHistorical(!showHistorical)}>
                            View Historical
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}
