import { useModalEditor } from "@davo/portal-common";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Button, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useAccountContext } from "../context";
import { ChangeBankModal, IChangeBankTarget } from "./ChangeBankModal";

export interface IBankSummaryActions {
    bankAccountName: string;
    bankAccountId: string;
    locationName: string;
    locationId: string;
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage: string;
}

export const BankSummaryActions: React.FunctionComponent<IBankSummaryActions> = ({
    bankAccountName,
    bankAccountId,
    locationName,
    locationId,
    shouldRestrictMerchantEdit,
    restrictMerchantEditMessage,
}) => {
    const accountContext = useAccountContext();
    const [showChangeBankModal, changeBankModalProps] = useModalEditor<IChangeBankTarget>(() =>
        accountContext.refresh()
    );

    return (
        <Stack justifyContent={"end"} direction="row" spacing={2}>
            <Tooltip title={`Change bank associated with ${bankAccountName}`} placement="top">
                <span>
                    <Button
                        data-testid={`change-bank-for-${locationId}-btn`}
                        disabled={shouldRestrictMerchantEdit}
                        variant={"text"}
                        color="primary"
                        onClick={() =>
                            showChangeBankModal({
                                bankAccountId,
                                locationName,
                                locationId,
                            })
                        }
                        size="small">
                        Change
                    </Button>
                </span>
            </Tooltip>

            {restrictMerchantEditMessage ? (
                <Tooltip title={restrictMerchantEditMessage} placement="top">
                    <HelpOutline fontSize="small" color="primary" style={{ alignSelf: "center" }} />
                </Tooltip>
            ) : null}

            {changeBankModalProps.isDialogOpen && <ChangeBankModal {...changeBankModalProps} />}
        </Stack>
    );
};
