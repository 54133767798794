import { auth, d30ToastError, Loading, LoginForm, useLoginContext } from "@davo/portal-common";
import { IUserInvitationInfo } from "@davo/types";
import { Alert, Button, Grid, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Sentry from "@sentry/browser";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { CapturePhone } from "./boarding/detail/CapturePhone";
import { useAccountsContext, useMerchantPortalConfigContext } from "./context";
import illustrationsData from "./resources/davo_illustrations_data.png";
import { getUserInvitationInfo, redeemUserInvitation } from "./services/invitation";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: "20px",
        height: "100%",
    },
    control: {
        padding: theme.spacing(2),
    },
    panel: {
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    item: {
        maxWidth: "400px",
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "50%",
        cursor: "pointer",
    },
}));

export function UserInvitationLogin() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const loginContext = useLoginContext();
    const accountsContext = useAccountsContext();

    const { code } = useParams();
    const [invitationInfo, setInvitationInfo] = useState<IUserInvitationInfo>();
    const [isUnavailable, setIsUnavailable] = useState<boolean>(false);
    const [isPhoneCaptureRequired, setIsPhoneCaptureRequired] = useState<boolean>(false);

    useAsyncEffect(async () => {
        if (loginContext.user) {
            // log out existing session
            await auth.logout().finally(() => {
                loginContext.setUser(undefined);
            });
        }
    }, []);

    useAsyncEffect(async () => {
        if (!code) {
            return;
        }
        try {
            const invite = await getUserInvitationInfo(code);
            if (invite?.status === "redeemed") {
                navigate("/");
            } else if (invite?.status === "expired") {
                setIsUnavailable(true);
            } else {
                setInvitationInfo(invite);
            }
        } catch (e: any) {
            d30ToastError("Failed to load", e);
            setIsUnavailable(true);
        }
    }, [code, navigate]);

    useAsyncEffect(async () => {
        if (!code || !loginContext.user) {
            return;
        }
        try {
            await redeemUserInvitation(code);
        } catch (e: any) {
            console.error(e); // eslint-disable-line no-console
            Sentry.captureException(e);
            setIsUnavailable(true);
            return;
        }
        await accountsContext.refresh();
        if (isNil(loginContext.user.phone)) {
            setIsPhoneCaptureRequired(true);
        } else {
            navigate("/");
        }
    }, [loginContext.user, code]);

    if (!code) {
        return null;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            style={{ padding: "10px" }}
            data-testid={"userInvitationLogin"}>
            <Grid item xs={12} md={6} lg={6} className={classes.item}>
                <Paper className={classes.paper}>
                    <div className={classes.panel}>
                        <img style={{ width: "200px" }} alt="DAVO" src={illustrationsData} />
                        <div className={classes.panel}>
                            <Typography style={{ fontSize: 20, fontWeight: "bold", margin: "20px" }}>
                                {invitationInfo?.name}
                            </Typography>
                            <Typography style={{ marginBottom: "20px" }}>
                                You&apos;ve been invited to join the DAVO portal.
                            </Typography>
                            <Typography>Please create an account or login to complete the process.</Typography>
                        </div>
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6} className={classes.item}>
                <Paper className={classes.paper}>
                    <div style={{ background: "gray", marginTop: "10px", marginBottom: "10px", width: "1px" }} />
                    <div className={classes.panel}>
                        {isUnavailable && (
                            <div>
                                <div style={{ textAlign: "center" }}>
                                    <Alert severity="error">This invitation is no longer available.</Alert>
                                    <br />
                                    <span>Please contact the account owner to receive another invitation.</span>
                                    <div>
                                        <Button
                                            className={classes.button}
                                            style={{ marginTop: "20px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate("/")}>
                                            Return
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isUnavailable && !invitationInfo && <Loading />}
                        {invitationInfo && !loginContext.user && (
                            <LoginForm
                                showCreate={true}
                                userInvitationId={invitationInfo?.id}
                                googleOAuthClientId={configInfo.googleOAuthClientId}
                                appType="merchantPortal"
                            />
                        )}
                        {isPhoneCaptureRequired && <CapturePhone onContinue={() => navigate("/")} />}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}
