import { setLocationBankAccount } from "@davo/portal-common";
import { BankAccount, LocationRecord } from "@davo/types";
import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useAccountContext } from "../context";
import { formatBankLabel } from "../util";
import { AssociateBankToLocations } from "./AssociateBankToLocations";

export interface IAssociateBankToLocationsModal {
    closeDialog(): void;
    target?: string;
}

export const AssociateBankToLocationsModal: FunctionComponent<IAssociateBankToLocationsModal> = ({
    closeDialog,
    target: targetBankAccountId,
}) => {
    const titleId = "associate-locs-to-bank-modal-title";
    const { locations, account, bankAccounts } = useAccountContext();
    const [initiallySelectedLocations, setInitiallySelectedLocations] = useState<LocationRecord[]>([]);
    const [selectedLocations, setSelectedLocations] = useState<LocationRecord[]>([]);
    const [bankAccount, setBankAccount] = useState<BankAccount>();

    useEffect(() => {
        setBankAccount(bankAccounts?.find((ba) => ba.id === targetBankAccountId));
    }, [targetBankAccountId, bankAccounts]);

    useEffect(() => {
        const locationsWithThisBank = locations?.filter((loc) => loc.bankAccountId === targetBankAccountId) ?? [];

        setSelectedLocations(locationsWithThisBank);
        setInitiallySelectedLocations(locationsWithThisBank);
    }, [locations, targetBankAccountId]);

    const handleSelectLocation = (l: LocationRecord) => {
        const isAlreadySelected = selectedLocations.includes(l);

        if (isAlreadySelected) {
            setSelectedLocations(selectedLocations.filter((loc) => loc !== l));
        } else {
            setSelectedLocations([...selectedLocations, l]);
        }
    };

    const handleSubmit = async () => {
        if (!account?.id || !targetBankAccountId) {
            return;
        }

        for await (const loc of selectedLocations) {
            await setLocationBankAccount(account.id, loc.id, targetBankAccountId);
        }
        closeDialog();
    };

    if (!locations || !targetBankAccountId || !bankAccount) {
        return null;
    }

    return (
        <Dialog
            data-testid={"associateBankToLocationsModal"}
            maxWidth={"lg"}
            fullWidth={true}
            open={true}
            aria-labelledby={titleId}
            onClose={closeDialog}>
            <DialogTitle id={titleId}>
                <Stack justifyContent={"space-between"} direction="row" alignItems={"start"}>
                    <div>
                        <Typography component={"h2"} variant={"h5"}>
                            Associate bank account with location(s)
                            {bankAccount && (
                                <Typography variant={"caption"} display={"block"}>
                                    {formatBankLabel({
                                        ba: bankAccount,
                                        withStarredPrefix: false,
                                    })}
                                </Typography>
                            )}
                        </Typography>
                    </div>
                    <IconButton onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="warning" sx={{ mb: "8px" }}>
                    <AlertTitle>Billing implications</AlertTitle>
                    Locations that share banks are billed and share daily set asides together.
                </Alert>

                <AssociateBankToLocations
                    selectedLocations={selectedLocations}
                    handleSelectLocation={handleSelectLocation}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    data-testid={"confirmBankAssociationsBtn"}
                    color={
                        initiallySelectedLocations.length !== 0 && selectedLocations.length === 0
                            ? "warning"
                            : "primary"
                    }
                    variant="contained"
                    size="small"
                    onClick={handleSubmit}>
                    {`${
                        initiallySelectedLocations.length !== 0 && selectedLocations.length === 0 ? "Remove" : "Confirm"
                    } Associations`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
