import { AddressVerificationContextProvider, lightTheme, LoginContextProvider } from "@davo/portal-common";
import "@davo/portal-common/lib/toast.css";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAsyncEffect from "use-async-effect";
import "./App.css";
import {
    AccountContext,
    AccountContextProvider,
    AccountsContextProvider,
    LocationContext,
    LocationContextProvider,
    MerchantPortalConfigContextProvider,
} from "./context";
import { MerchantRouter } from "./Routes";
import { setReferralCookie } from "./services/reftracking";
import { FullStory } from "./third-party/FullStory";
import { GoogleAnalytics } from "./third-party/GoogleAnalytics";
import { HubSpot } from "./third-party/HubSpot";

export function Preload() {
    useAsyncEffect(async () => {
        // Initialize page and setup account auto-refresh
        const params = new URLSearchParams(window.location.search);

        const marketingInfo = {
            email: params.get("email"),
            firstName: params.get("firstName"),
            lastName: params.get("lastName"),
            phone: params.get("phone"),
        };
        try {
            localStorage.setItem("marketingInfo", JSON.stringify(marketingInfo));
        } catch {
            // ignore these errors
        }

        const refSource = params.get("refSource");
        const refId = params.get("refId");
        const refDealId = params.get("refDealId");
        if (refSource) {
            await setReferralCookie(
                refSource,
                refId === null ? undefined : refId,
                refDealId === null ? undefined : refDealId
            );
        }
    }, []);

    return (
        <MerchantPortalConfigContextProvider>
            <LoginContextProvider>
                <ThemeProvider theme={lightTheme}>
                    <GoogleAnalytics />
                    <HubSpot />
                    <FullStory />

                    <AccountsContextProvider>
                        <AccountContextProvider>
                            <AccountContext.Consumer>
                                {(accountContext) => (
                                    <LocationContextProvider>
                                        <LocationContext.Consumer>
                                            {(locationContext) => (
                                                <AddressVerificationContextProvider
                                                    location={locationContext.location}
                                                    accountId={accountContext.account?.id}
                                                    shouldAutoPrompt={true}
                                                    refreshLocation={locationContext.refresh}>
                                                    <MerchantRouter />
                                                </AddressVerificationContextProvider>
                                            )}
                                        </LocationContext.Consumer>
                                    </LocationContextProvider>
                                )}
                            </AccountContext.Consumer>
                        </AccountContextProvider>
                    </AccountsContextProvider>
                    <ToastContainer />
                </ThemeProvider>
            </LoginContextProvider>
        </MerchantPortalConfigContextProvider>
    );
}
