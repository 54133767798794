import {
    accountHasAnyActivity,
    getAccount,
    getAccountLocationsStatus,
    getBankAccounts,
    getLocationsAll,
    getPartner,
    getTaxProfiles,
    useLoginContext,
} from "@davo/portal-common";
import {
    AccountRecord,
    BankAccount,
    IAccountLocationStatus,
    LocationRecord,
    NonEmptyArray,
    Partner,
    TaxProfile,
} from "@davo/types";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { determineLocationsNotFullyConnected } from "../util";

export interface IAccountContext {
    account: AccountRecord | undefined;
    partner: Partner | undefined;
    locations: LocationRecord[] | undefined;
    locationsNotFullyConnected: Set<string> | undefined;
    locationsAggregateStatus: NonEmptyArray<IAccountLocationStatus> | undefined;
    bankAccounts: BankAccount[] | undefined;
    taxProfiles: TaxProfile[] | undefined;
    hasActivity: boolean | undefined;

    switch: (accountId: string | undefined) => Promise<void>;
    refresh: () => Promise<void>;
}

export const AccountContextDefaultValue = {} as IAccountContext;
export const AccountContext = React.createContext(AccountContextDefaultValue);
export const useAccountContext = () => React.useContext(AccountContext);

export const AccountContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const loginContext = useLoginContext();

    const [account, setAccount] = useState<AccountRecord>();
    const [partner, setPartner] = useState<Partner>();
    const [accountLocations, setAccountLocations] = useState<LocationRecord[]>();
    const [accountBankAccounts, setAccountBankAccounts] = useState<BankAccount[]>();
    const [accountTaxProfiles, setAccountTaxProfiles] = useState<TaxProfile[]>();
    const [locationsAggregateStatus, setLocationsAggregateStatus] = useState<NonEmptyArray<IAccountLocationStatus>>();
    const [locationsNotFullyConnected, setLocationsNotFullyConnected] = useState<Set<string>>();
    const [hasActivity, setHasActivity] = useState<boolean>();

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            clearAccount();
        }
    }, [loginContext.user]);

    const clearAccount = () => {
        setAccount(undefined);
        setPartner(undefined);
        setAccountLocations(undefined);
        setAccountBankAccounts(undefined);
        setAccountTaxProfiles(undefined);
        setLocationsNotFullyConnected(undefined);
    };

    const switchAccount = async (accountId: string | undefined) => {
        if (!accountId) {
            clearAccount();
            return;
        }
        await loadAccount(accountId);
    };

    const refreshAccount = async () => {
        if (!account) {
            return;
        }
        await loadAccount(account.id);
    };

    const loadAccount = async (accountId: string) => {
        const accountPromise = getAccount(accountId);
        const locationsPromise = getLocationsAll(accountId);
        const bankAccountsPromise = getBankAccounts(accountId);
        const taxProfilesPromise = getTaxProfiles(accountId);
        const locationsNotFullyConnectedPromise = determineLocationsNotFullyConnected(accountId);
        const accountHasAnyActivityPromise = accountHasAnyActivity(accountId);
        const accLocationsStatusPromise = getAccountLocationsStatus(accountId);
        const [
            accountResult,
            locationsResult,
            bankAccountsResult,
            taxProfilesResult,
            locationsNotFullyConnectedResult,
            hasActivityResult,
            accLocationsStatusResult,
        ] = await Promise.all([
            accountPromise,
            locationsPromise,
            bankAccountsPromise,
            taxProfilesPromise,
            locationsNotFullyConnectedPromise,
            accountHasAnyActivityPromise,
            accLocationsStatusPromise,
        ]);
        setAccount(accountResult);
        setAccountLocations(locationsResult);
        setAccountBankAccounts(bankAccountsResult);
        setAccountTaxProfiles(taxProfilesResult);
        setLocationsNotFullyConnected(locationsNotFullyConnectedResult);
        setHasActivity(hasActivityResult);
        setLocationsAggregateStatus(accLocationsStatusResult);
    };

    useAsyncEffect(async () => {
        if (!account?.partnerId) {
            setPartner(undefined);
            return;
        }
        setPartner(await getPartner(account.partnerId));
    }, [account]);

    return (
        <AccountContext.Provider
            value={{
                account,
                switch: switchAccount,
                refresh: refreshAccount,
                partner,
                locations: accountLocations,
                bankAccounts: accountBankAccounts,
                taxProfiles: accountTaxProfiles,
                locationsAggregateStatus,
                locationsNotFullyConnected,
                hasActivity,
            }}>
            {children}
        </AccountContext.Provider>
    );
};
