import { getAccounts, getLocationsEnhanced, useLoginContext } from "@davo/portal-common";
import { AccountRecord, LocationRecord, OpsUserTypes } from "@davo/types";
import isEmpty from "lodash/isEmpty";
import React, { FunctionComponent, PropsWithChildren, useCallback, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getIncompleteBoarding, getInvitationByPOSMerchant, getLocationsByPOSMerchant } from "../services/boarding";

export interface IAccountsContext {
    accounts: AccountRecord[] | undefined;

    refresh: () => Promise<void>;
}

export const AccountsContextDefaultValue = {} as IAccountsContext;
export const AccountsContext = React.createContext(AccountsContextDefaultValue);
export const useAccountsContext = () => React.useContext(AccountsContext);

export const AccountsContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const loginContext = useLoginContext();

    const [accounts, setAccounts] = useState<AccountRecord[]>();

    const refreshAccounts = async () => {
        if (!loginContext.user) {
            return;
        }
        const accountsList = await getAccounts(loginContext.user.id);
        setAccounts(accountsList);
    };

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            setAccounts(undefined);
        } else {
            await refreshAccounts();
        }
    }, [loginContext.user]);

    const getInvitationForBoardingRedirect = useCallback(async () => {
        if (accounts && accounts.length === 1) {
            const lList = await getLocationsEnhanced(accounts[0].id);
            const activeLocations = lList.filter((l: LocationRecord) => l.active && !l.disconnected);

            if (!isEmpty(activeLocations) && activeLocations.length === 1) {
                const activeLocation = activeLocations[0];

                const locationsForPOSMerchant = await getLocationsByPOSMerchant(
                    activeLocation.posType,
                    activeLocation.posMerchant
                );
                if (locationsForPOSMerchant && locationsForPOSMerchant.length > 1) {
                    return;
                }

                if (
                    !activeLocation.bankAccount ||
                    isEmpty(activeLocation.taxProfileIds) ||
                    activeLocation.taxProfileIds.length === 0
                ) {
                    return getInvitationByPOSMerchant(activeLocation.posType, activeLocation.posMerchant);
                }
            }
        }
    }, [accounts]);

    useAsyncEffect(async () => {
        if (accounts && loginContext.user && ![...OpsUserTypes, "Partner"].includes(loginContext.user.type)) {
            const isInInvitationEnrollment = window.location.pathname.startsWith("/invitation");
            if (accounts.length === 0) {
                const isInBoarding = window.location.pathname.startsWith("/boarding");
                if (!isInBoarding && !isInInvitationEnrollment) {
                    const openBoarding = await getIncompleteBoarding();
                    if (openBoarding) {
                        window.location.replace(`/boarding/${openBoarding.posType}`);
                    }
                }
            } else if (accounts.length === 1 && !isInInvitationEnrollment) {
                const invitation = await getInvitationForBoardingRedirect();
                if (invitation) {
                    window.location.replace(`/invitation/location/${invitation.id}`);
                }
            }
        }
    }, [accounts, getInvitationForBoardingRedirect, loginContext.user]);

    return (
        <AccountsContext.Provider
            value={{
                accounts,
                refresh: refreshAccounts,
            }}>
            {children}
        </AccountsContext.Provider>
    );
};
