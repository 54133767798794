import { INavMenuItem, LoginPage, Profile, ResetPassword, SetPassword, useLoginContext } from "@davo/portal-common";
import { IMerchantPortalConfigType, POSType } from "@davo/types";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AdditionalData, showMerchantDataTab } from "./AdditionalData";
import { PreAccount } from "./boarding/flow/PreAccount";
import { Reconnect } from "./boarding/flow/Reconnect";
import { HeartlandRestaurantBoarding } from "./boarding/HeartlandRestaurantBoarding";
import { HeartlandRetailBoarding } from "./boarding/HeartlandRetailBoarding";
import { LavuBoarding } from "./boarding/LavuBoarding";
import { LegacySubscriptionPage } from "./boarding/LegacySubscriptionPage";
import { LightspeedKSeriesBoarding } from "./boarding/LightspeedKSeriesBoarding";
import { LightspeedLSeriesBoarding } from "./boarding/LightspeedLSeriesBoarding";
import { LightspeedUSeriesBoarding } from "./boarding/LightspeedUSeriesBoarding";
import { LightspeedXSeriesBoarding } from "./boarding/LightspeedXSeriesBoarding";
import { LoadInvitation } from "./boarding/LoadInvitation";
import { MerchantInvitationLogin } from "./boarding/MerchantInvitationLogin";
import { MindbodyBoarding } from "./boarding/MindbodyBoarding";
import { ParBrinkBoarding } from "./boarding/ParBrinkBoarding";
import { QuickBooksBoarding } from "./boarding/QuickBooksBoarding";
import { RevelBoarding } from "./boarding/RevelBoarding";
import { SpotOnRestaurantBoarding } from "./boarding/SpotOnRestaurantBoarding";
import { SpotOnRetailBoarding } from "./boarding/SpotOnRetailBoarding";
import { TouchBistroBoarding } from "./boarding/TouchBistroBoarding";
import { useAccountContext, useAccountsContext, useMerchantPortalConfigContext } from "./context";
import { Dashboard } from "./Dashboard";
import { Filings } from "./Filings";
import { FirstAccountChanger } from "./FirstAccountChanger";
import { History } from "./History";
import { InvitationRequired } from "./InvitationRequired";
import { MerchantDonut } from "./MerchantDonut";
import { NoticesTab } from "./notices/NoticesTab";
import { QuickBooksDisconnect } from "./QuickBooksDisconnect";
import DashboardIcon from "./resources/dashboard.svg";
import DataIcon from "./resources/data.svg";
import FilingsIcon from "./resources/filings.svg";
import ViewTimeLine from "./resources/viewtimeline.svg";
import { Settings } from "./Settings";
import { Banking } from "./SettingsBanking";
import { Billing } from "./SettingsBilling";
import { Company } from "./SettingsCompany";
import { SettingsCompanySetBankModal } from "./SettingsCompanySetBankModal";
import { SettingsCompanySetTaxProfilesModal } from "./SettingsCompanySetTaxProfiles";
import { SettingsSingleLocation } from "./SettingsSingleLocation";
import { SettingsTaxProfile } from "./SettingsTaxProfile";
import { UserReferral } from "./SettingsUserReferral";
import { Users } from "./SettingsUsers";
import { UserInvitationLogin } from "./UserInvitationLogin";

export function MerchantRouter() {
    const { merchantPortalConfigInfo: configInfo } = useMerchantPortalConfigContext();
    const loginContext = useLoginContext();
    const { accounts } = useAccountsContext();
    const { account, taxProfiles, locations, locationsNotFullyConnected, hasActivity } = useAccountContext();

    const [donutPrimaryRoutes, setDonutPrimaryRoutes] = useState<INavMenuItem[]>([]);
    const [shouldShowDetailPanes, setShouldShowDetailPane] = useState<boolean>(false);
    const [firstDonutPath, setFirstDonutPath] = useState<string>();

    useEffect(() => {
        if (isNil(locations) || isNil(hasActivity)) {
            return;
        }
        // NOTE: if all locations are inactive, and we have no activity for this account (for any location)
        // hide the Dashboard and Settings endpoints and menu items
        const hasActiveLocations = locations.some((l) => l.active);

        setShouldShowDetailPane(hasActiveLocations || hasActivity);
    }, [locations, hasActivity]);

    useAsyncEffect(async () => {
        if (isNil(loginContext.user) || isNil(account) || isNil(taxProfiles)) {
            return;
        }

        const theRoutes: INavMenuItem[] = [];
        if (shouldShowDetailPanes) {
            theRoutes.push({
                name: "Dashboard",
                slug: "dashboard",
                destination: "dashboard",
                element: <Dashboard />,
                icon: DashboardIcon,
            });
        }
        theRoutes.push({
            name: "Filings",
            slug: "filings",
            destination: "filings",
            element: <Filings />,
            icon: FilingsIcon,
        });
        theRoutes.push({
            name: "History",
            slug: "history",
            destination: "history",
            element: <History />,
            icon: ViewTimeLine,
        });
        if (showMerchantDataTab(taxProfiles, loginContext.permissions)) {
            theRoutes.push({
                name: "Additional Info",
                slug: "additional-info",
                destination: "additional-info",
                element: <AdditionalData />,
                icon: DataIcon,
            });
        }
        if (taxProfiles.length > 0) {
            theRoutes.push({
                name: "Notices",
                slug: "notices",
                destination: "notices",
                element: <NoticesTab />,
                icon: DataIcon,
            });
        }

        setDonutPrimaryRoutes(theRoutes);

        if (shouldShowDetailPanes && locationsNotFullyConnected && locationsNotFullyConnected.size > 0) {
            if (locations?.length === 1 && locationsNotFullyConnected.size === 1) {
                setFirstDonutPath(`settings/company/${[...locationsNotFullyConnected][0]}`);
            } else {
                setFirstDonutPath("settings/company");
            }
        } else {
            setFirstDonutPath(theRoutes[0].slug);
        }
    }, [
        loginContext.user,
        loginContext.permissions,
        account,
        locations,
        taxProfiles,
        locationsNotFullyConnected,
        shouldShowDetailPanes,
    ]);

    const profileProps = {
        showScreenMode: false,
    };

    return (
        <Router>
            <Routes>
                <Route path="/boarding/v2clover" element={<CloverV2Boarding configInfo={configInfo} />} />
                <Route path="/boarding/clover" element={<CloverBoarding configInfo={configInfo} />} />
                <Route path="/boarding/square" element={<SquareBoarding configInfo={configInfo} />} />
                <Route path="/boarding/poynt" element={<PoyntBoarding configInfo={configInfo} />} />
                <Route path="/invitation/create/:protocol" element={<CreateInvitation />} />
                <Route path="/index.html" element={<Navigate to="/" />} />
                <Route path="/pre-account/location/:code" element={<PreAccount />} />
                <Route path="/reconnect/location/:code" element={<Reconnect />} />
                <Route path="/invitation/location/:code/:ai" element={<MerchantInvitationLogin />} />
                <Route path="/invitation/location/:code" element={<MerchantInvitationLogin />} />
                <Route path="/invitation/user/:code" element={<UserInvitationLogin />} />
                <Route path="/authenticate/resetPassword" element={<ResetPassword />} />
                <Route path="/authenticate/setPassword" element={<SetPassword />} />
                <Route path="/boarding/revel" element={<RevelBoarding />} />
                <Route path="/boarding/heartland-restaurant" element={<HeartlandRestaurantBoarding />} />
                <Route path="/boarding/heartland-retail" element={<HeartlandRetailBoarding />} />
                <Route path="/boarding/par-brink" element={<ParBrinkBoarding />} />
                <Route path="/boarding/mindbody" element={<MindbodyBoarding />} />
                <Route path="/boarding/spoton-restaurant" element={<SpotOnRestaurantBoarding />} />
                <Route path="/boarding/spoton-retail" element={<SpotOnRetailBoarding />} />
                <Route path="/boarding/quickbooks" element={<QuickBooksBoarding />} />
                <Route path="/boarding/lavu" element={<LavuBoarding />} />
                <Route path="/boarding/shift4" element={<Shift4Boarding configInfo={configInfo} />} />
                <Route path="/boarding/touchbistro" element={<TouchBistroBoarding />} />
                <Route path="/disconnect/quickbooks" element={<QuickBooksDisconnect />} />
                <Route
                    path="/boarding/lightspeed-e-series"
                    element={<LightspeedESeriesBoarding configInfo={configInfo} />}
                />
                <Route path="/boarding/lightspeed-k-series" element={<LightspeedKSeriesBoarding />} />
                <Route path="/boarding/lightspeed-l-series" element={<LightspeedLSeriesBoarding />} />
                <Route
                    path="/boarding/lightspeed-r-series"
                    element={<LightspeedRSeriesBoarding configInfo={configInfo} />}
                />
                <Route path="/boarding/lightspeed-u-series" element={<LightspeedUSeriesBoarding />} />
                <Route path="/boarding/lightspeed-x-series" element={<LightspeedXSeriesBoarding />} />
                <Route path="/legacy" element={<LegacySubscriptionPage />} />
                {!loginContext.user && (
                    <Route
                        path="*"
                        element={
                            <LoginPage
                                googleOAuthClientId={configInfo.googleOAuthClientId}
                                isAvalaraIdentityEnabled={configInfo.avalaraIdentityEnabled}
                                appType="merchantPortal"
                            />
                        }
                    />
                )}
                {loginContext.user && (!account || !accounts || (accounts && accounts.length > 0)) && (
                    <Route path="*" element={<FirstAccountChanger />} />
                )}
                {loginContext.user && (
                    <Route
                        path="/:accountId"
                        element={
                            <MerchantDonut routes={donutPrimaryRoutes} shouldShowDetailPanes={shouldShowDetailPanes} />
                        }>
                        {/* Redirect to first donut route if not provided */}
                        {firstDonutPath && <Route path="" element={<Navigate to={firstDonutPath} replace />} />}

                        <Route path="profile" element={<Profile {...profileProps} />} />

                        <Route path="settings" element={<Settings />}>
                            <Route path="company" element={<Company />} />
                            <Route path="company/:locationId" element={<SettingsSingleLocation />}>
                                <Route path="bank" element={<SettingsCompanySetBankModal />} />
                                <Route path="tax" element={<SettingsCompanySetTaxProfilesModal />} />
                            </Route>

                            <Route path="banking/*" element={<Banking />} />
                            <Route path="filing/*" element={<SettingsTaxProfile />} />
                            <Route path="users/*" element={<Users />} />
                            {account && !account.partnerId && <Route path="billing/*" element={<Billing />} />}
                            {loginContext.user?.referralCode && <Route path="referral/*" element={<UserReferral />} />}

                            {/* The settings page defaults to the company pane */}
                            <Route path="" element={<Navigate to="company" replace />} />
                        </Route>

                        {donutPrimaryRoutes.map((item) => (
                            <Route key={item.slug} path={`${item.slug}/*`} element={item.element} />
                        ))}

                        {/* redirect any other path to the first donut route */}
                        {firstDonutPath && <Route path="*" element={<Navigate to={firstDonutPath} replace />} />}
                    </Route>
                )}
                {account && <Route path="/" element={<Navigate to={`/${account.id}`} />} />}
                {!account && <Route path="*" element={<InvitationRequired />} />}
            </Routes>
        </Router>
    );
}

function CloverBoarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(configInfo.cloverV2BaseURL);
    return null;
}

function CloverV2Boarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(`${configInfo.cloverV2BaseURL}`);
    return null;
}

function SquareBoarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(
        `${configInfo.squareBaseURL}?client_id=${configInfo.squareAppId}&scope=MERCHANT_PROFILE_READ+PAYMENTS_READ+SETTLEMENTS_READ+BANK_ACCOUNTS_READ+EMPLOYEES_READ+ORDERS_READ+ITEMS_READ`
    );
    return null;
}

function PoyntBoarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    const encodedURI = encodeURI(`&redirect_uri=${configInfo.poyntRedirectURL}`);
    window.location.replace(`${configInfo.poyntBaseURL}?client_id=${configInfo.poyntCloudAppId}${encodedURI}`);
    return null;
}

function CreateInvitation() {
    const params = useParams();
    const location = useLocation();
    let paramsProtocol = params.protocol;

    if (!paramsProtocol) {
        return null;
    }
    if (paramsProtocol === "poynt" && location.search.length === 0) {
        // Poynt has one link in the app for oauth redirect and for the link to visit DAVO once the merchant is subbed
        // so we redirect to login here if there aren't any of the params we'd get from an oauth redirect
        return <Navigate to="/" />;
    }

    // Lightspeed doesn't allow use of "lightspeed" in oauth redirects, so we have to use non-POStype ls-* then reassign for all Lightspeed integrations
    if (paramsProtocol && paramsProtocol.startsWith("ls-")) {
        paramsProtocol = paramsProtocol.replace("ls-", "lightspeed-");
    }

    return <LoadInvitation posType={paramsProtocol as POSType} body={location.state} />;
}

function LightspeedRSeriesBoarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(`${configInfo.lightspeedRSeriesUrl}`);
    return null;
}

function LightspeedESeriesBoarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(`${configInfo.lightspeedESeriesAuthUri}`);
    return null;
}

function Shift4Boarding({ configInfo }: { configInfo: IMerchantPortalConfigType }) {
    window.location.replace(`${configInfo.shift4AuthUri}`);
    return null;
}
