import { d30ToastError, getNotices, Header, NoticesTable } from "@davo/portal-common";
import { BusinessDay, INotice } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Paper, Tooltip } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useState } from "react";
import { useAccountContext } from "../context";
import { AddNoticeModal } from "./AddNoticeModal";

export function NoticesTab() {
    const accountContext = useAccountContext();

    const [notices, setNotices] = useState<(INotice & { downloadReady: boolean })[]>();

    const search = useCallback(async () => {
        if (isNil(accountContext.account)) {
            return;
        }
        const n = await getNotices(accountContext.account.id, BusinessDay.from(2018, 1, 1), BusinessDay.tomorrow());
        setNotices(n);
    }, [accountContext.account]);

    useEffect(() => {
        search().catch((e) => {
            d30ToastError("Could not refresh data!", e);
        });
    }, [search]);

    if (isNil(accountContext.account) || isNil(accountContext.taxProfiles)) {
        return null;
    }

    return (
        <>
            <Paper
                style={{
                    padding: "24px",
                    paddingBottom: "50px",
                    boxShadow: "4px 4px 5px -3px rgba(219,219,219,1)",
                    marginBottom: "50px",
                }}
                data-testid="notices-tab">
                <Header>
                    <span style={{ color: "rgb(3, 187, 156)", marginLeft: "15px" }}>
                        Tell us about a notice you received
                    </span>
                </Header>
                <h4>
                    <div
                        style={{
                            color: "#1a2f5b",
                            width: "50%",
                            marginLeft: "15px",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <span>
                            This could include tax discrepancies, changes to rates, penalties, or something else.
                        </span>
                        <Tooltip title="You’ll be asked to choose a tax profile, and fill out the dates the notice applies to.  We also ask that you upload the file you received if possible.">
                            <div style={{ textAlign: "right", marginTop: "2px" }}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </div>
                        </Tooltip>
                    </div>
                </h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                    }}>
                    <div style={{ margin: "5px 10px" }}>
                        <AddNoticeModal accountId={accountContext.account.id} isDisabled={false} refresh={search} />
                    </div>
                </div>
            </Paper>
            {notices && notices.length !== 0 && (
                <Paper style={{ padding: "24px", paddingBottom: "0px", boxShadow: "none" }}>
                    <NoticesTable notices={notices} includeCancelButton={true} includeNotes={true} refresh={search} />
                </Paper>
            )}
        </>
    );
}
