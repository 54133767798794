import { d30Toast, d30ToastError, TextField, updateBankAccountNickname, useModalEditor } from "@davo/portal-common";
import EditIcon from "@mui/icons-material/Edit";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useAccountContext } from "../context";

export function EditBankNicknameModalButton({
    bankAccountId,
    oldNickname,
    onCloseSuccess,
}: {
    bankAccountId: string;
    oldNickname: string;
    onCloseSuccess: () => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const { account } = useAccountContext();
    const [nickname, setNickname] = useState<string>(oldNickname);

    if (!account) {
        return null;
    }

    const doSave = () => {
        updateBankAccountNickname(account.id, bankAccountId, nickname.trim())
            .then(() => {
                d30Toast("Nickname saved");
                onCloseSuccess();
            })
            .catch((e) => {
                d30ToastError(e.message, e);
            })
            .finally(closeDialog);
    };

    return (
        <>
            <IconButton aria-label={`Edit ${oldNickname ?? "bank"}`} onClick={showDialog}>
                <EditIcon color="primary" />
            </IconButton>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}
                    onClose={closeDialog}>
                    <DialogTitle id="responsive-dialog-title">Edit Your Bank Nickname</DialogTitle>
                    <DialogContent>
                        <TextField
                            label={"Bank Nickname"}
                            value={nickname}
                            onChange={setNickname}
                            onEnterPress={doSave}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={doSave}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
