import {
    d30Toast,
    getFirstFundingBreakoutForLocations,
    getMerchantDailyDetail,
    getOpenFilingsForAccount,
    getStartDateForMerchantDailyDetail,
    ITaxAccountAndFilingType,
    LinkButton,
    Loading,
    Select,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import { LocationSelector } from "@davo/portal-common/lib/LocationSelector";
import {
    BusinessDay,
    dsoStartDate,
    getMerchantDailyDetailStatus,
    LocationRecord,
    MerchantDailyDetail,
    MerchantDetailSetAsideSummary,
    moneyFromCents,
    toDisplayDateString,
} from "@davo/types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputBase,
    Paper,
    Radio,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import { DateTime } from "luxon";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { MerchantCard } from "./MerchantCard";
import { TaxChart } from "./TaxChart";
import { TaxTable } from "./TaxTable";
import { FirstFundingSelfServeModal, MerchantConnectionStatus } from "./components";
import { useAccountContext } from "./context";
import hubspotChat from "./resources/chat_bubble.png";
import illustrationsFriend from "./resources/davo_illustrations_refer_friend.png";

const spacing = 2;

const useStyles = makeStyles(() => ({
    helperTile: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    helperTileNeedHelpBox: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "0px 0px 10px 0px",
    },
    helperTileNeedHelpLink: {
        display: "flex",
        width: "15%",
        alignSelf: "flex-start",
    },
    helperTileNeedHelpIcon: {
        display: "flex",
        width: "100%",
    },
    helperTileNeedHelpTextBox: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexBasis: 1,
        flexGrow: 3,
        marginLeft: "10px",
    },
    helperTileNeedHelpTitleBox: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        width: "100%",
    },
    helperTileNeedHelpTextHeader: {
        margin: "0px 0px 5px 0px",
        fontSize: "30px",
        width: "75%",
    },
    helperTileNeedHelpTextBody: {
        margin: 0,
    },
    helperTileHubspotIconBox: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    helperTileHubspotIcon: {
        width: "30%",
    },
    helperTileSomethingElseBox: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        margin: "10px 0px 0px 0px",
        width: "100%",
    },
    helperTileSomethingElseHeader: {
        fontSize: "20px",
        margin: "5px 0px 10px 0px",
    },
    helperTileSomethingElseVerticalButtonBox: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        alignItems: "space-between",
        width: "100%",
    },
    helperTileHorizontalButtonBox: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "space-between",
        width: "100%",
    },
    helperTileSomethingElseButton1: {
        width: "47%",
        margin: "0px 5px 0px 0px",
        fontSize: "10px !important",
    },
    helperTileSomethingElseButton2: {
        width: "47%",
        margin: "0px 0px 0px 5px",
        fontSize: "10px !important",
    },
    helperTileSomethingElseButton3: {
        width: "100%",
        margin: "0px 5px 10px 0px !important",
        fontSize: "10px !important",
    },
}));

const SubtleSelectInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        padding: "0px",
        border: "none",
        boxShadow: "none",
        marginRight: "8px",
        paddingLeft: "6px",
        position: "relative",
        fontSize: "20px",
        color: "#778ca2",
    },
}))(InputBase);

const monthYear = (period: string) => {
    const month = DateTime.fromFormat(period, monthFormat);
    return `${month.monthLong} ${month.year}`;
};

const monthFormat = "yyyy-MM";

// Filter list of all daily details by account for just the ones for our location. If our location is 'all'
// rollup the individual location values by day
function getDetailsForLocation(accountDetails: MerchantDailyDetail[], locationIdOrAll: string) {
    const results: MerchantDailyDetail[] = [];

    if (locationIdOrAll !== "all") {
        for (const detail of accountDetails) {
            if (detail.locationId === locationIdOrAll) {
                results.push(detail);
            }
        }
    } else {
        const totalResultsByDate = groupBy(accountDetails, (detail) => {
            return detail.date;
        });
        for (const date of Object.keys(totalResultsByDate)) {
            const detailsForDate: MerchantDailyDetail[] = totalResultsByDate[date];
            let sales = 0;
            let salesTax = 0;
            let totalSetAsideAmount = 0;
            const setAsideSummaries: MerchantDetailSetAsideSummary[] = [];

            for (const d of detailsForDate) {
                sales += d.sales;
                salesTax += d.salesTax;
                totalSetAsideAmount += d.totalSetAsideAmount;
                setAsideSummaries.push(...d.setAsides);
            }

            results.push({
                date: BusinessDay.fromSQLDate(date),
                locationId: "all",
                sales,
                salesTax,
                totalSetAsideAmount,
                status: getMerchantDailyDetailStatus(setAsideSummaries),
                setAsides: setAsideSummaries,
            });
        }
    }

    return results;
}

const renderExplanationModal = (
    text: string,
    clickAction?: (open: boolean) => void
): (string | JSX.IntrinsicElements["div"]) & ReactNode => {
    if (clickAction) {
        return (
            <div style={{ position: "relative" }}>
                {text}
                <HelpOutlineIcon
                    className="hover-cursor-pointer"
                    style={{ position: "absolute", fontSize: "20px", margin: "0 0 0 7px" }}
                    onClick={() => clickAction(true)}
                />
            </div>
        );
    } else {
        return text;
    }
};

export const Dashboard = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [location, setLocation] = useState<LocationRecord>();
    const [locations, setLocations] = useState<LocationRecord[]>();
    const [hasInactiveLocations, setHasInactiveLocations] = useState<boolean>(false);
    const [showInactiveLocations, setShowInactiveLocations] = useState<boolean>(false);

    const [accountDailyDetails, setAccountDailyDetails] = useState<MerchantDailyDetail[]>();
    const [locationDailyDetails, setLocationDailyDetails] = useState<MerchantDailyDetail[]>();
    const [period, setPeriod] = useState<string | undefined>();
    const [nextDueDate, setNextDueDate] = useState<string | undefined>();
    const [taxFilingPeriod, setTaxFilingPeriod] = useState<string | undefined>(undefined);

    // NOTE: We're grabbing the first 2 elements to get /:accountId/dashboard and not the remainder of the url
    const url = useLocation().pathname.split("/").slice(0, 3).join("/");
    const { routeLocationId, routePeriod } = useMatch(`${url}/:routeLocationId/:routePeriod`)?.params ?? {};

    const [filteredLocations, setFilteredLocations] = useState<LocationRecord[]>([]);
    const [showSalesModal, setShowSalesModal] = useState<boolean>(false);
    const [canShowSetAsideModal, setShowSetAsideModal] = useState<boolean>(false);
    const [canShowFirstFundingSelfServeCard, setCanShowFirstFundingSelfServeCard] = useState<boolean>(false);
    const [isFirstFundingModalReadOnly, setIsFirstFundingModalReadOnly] = useState<boolean>(false);
    const [firstFundingBreakout, setFirstFundingBreakout] = useState<
        { locationId: string; locationName: string; amount: number; status: string }[]
    >([]);
    const [showFirstFundingSelfServeModal, firstFundingSelfServeModalProps] = useModalEditor<boolean>(
        async (hasVal) => {
            await refreshFirstFundingBreakout();
            if (hasVal) {
                d30Toast("First funding choice registered");
            }
        }
    );
    const [selectableMonths, setSelectableMonths] = useState<string[]>([]);

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        const filingObj: ITaxAccountAndFilingType[] = await getOpenFilingsForAccount(accountContext.account.id);
        const dueDates = filingObj.map((element) => DateTime.fromISO(element.filing.filingDueDate.toString()));
        const nextDue = DateTime.min(...dueDates)?.toSQLDate();
        const nextPeriod = filingObj.find(
            (f) => DateTime.fromISO(f.filing.filingDueDate.toString()).toSQLDate() === nextDue
        );
        if (nextPeriod) {
            setTaxFilingPeriod(
                `${toDisplayDateString(nextPeriod.filing.periodStart.toString())} - ${toDisplayDateString(
                    nextPeriod?.filing.periodEnd.toString()
                )}`
            );
        }
        setNextDueDate(nextDue ? toDisplayDateString(nextDue) : undefined);
    }, [accountContext.account]);

    useEffect(() => {
        if (!accountContext.locations) {
            return;
        }
        setHasInactiveLocations(!isEmpty(accountContext.locations.filter((l) => !l.active)));
        setLocations(
            showInactiveLocations
                ? accountContext.locations
                : accountContext.locations.filter((l) => {
                      return l.active === true;
                  })
        );
        const hasLocations = accountContext.locations.length > 0;

        if (!routeLocationId && hasLocations) {
            navigate(`${url}/*/${DateTime.local().toFormat(monthFormat)}`, { replace: true });
        } else if (routeLocationId) {
            if (routeLocationId === "*") {
                setLocation(undefined);
            } else {
                const loc = accountContext.locations.find((l: LocationRecord) => l.id === routeLocationId);
                if (loc) {
                    if (loc !== location) {
                        setLocation(loc);
                    }
                } else if (hasLocations) {
                    navigate(`${url}/*/${routePeriod}`, { replace: true });
                }
            }
            if (period !== routePeriod) {
                setPeriod(routePeriod);
            }
        }
    }, [url, routeLocationId, routePeriod, accountContext.locations, showInactiveLocations]);

    useAsyncEffect(async () => {
        if (!locations) {
            return;
        }
        const selectableMonthsList: string[] = [];
        const now = DateTime.local();
        let start = now.minus({ month: 1 });
        if (accountContext.account) {
            const earliestActivity = await getStartDateForMerchantDailyDetail(accountContext.account.id);
            if (!isNil(earliestActivity)) {
                start = earliestActivity.startOf("month");
            } else {
                if (!location && !isEmpty(locations)) {
                    for (const l of locations) {
                        if (l.created && l.created < start) {
                            start = l.created;
                        }
                    }
                } else if (location && location.created) {
                    start = location.created;
                } else {
                    start = dsoStartDate; // Approx D20 start date
                }
            }
        }
        // add a month so that locations starting toward the end of the month (where this would otherwise round down) get their first month shown
        const monthsDiff = now.diff(start, ["months"]).plus({ month: 1 });

        for (let x = 0; x < monthsDiff.months; ++x) {
            const monthStr = now.minus({ month: x }).toFormat(monthFormat);
            selectableMonthsList.push(monthStr);
        }
        setSelectableMonths(selectableMonthsList);
    }, [location, locations]);

    useAsyncEffect(async () => {
        await refreshFirstFundingBreakout();
    }, [loginContext.user, locations]);

    const refreshFirstFundingBreakout = async () => {
        if (!locations || !loginContext.user || loginContext.user.type !== "Merchant") {
            return;
        }
        const firstFundingEligibleLocations: LocationRecord[] = locations.filter(
            (x: LocationRecord) => x.firstFundingEligible === "eligible"
        );
        const firstFundingDecidedLocations: LocationRecord[] = locations.filter(
            (x: LocationRecord) =>
                x.firstFundingEligible === "accepted" ||
                x.firstFundingEligible === "declined" ||
                x.firstFundingEligible === "initiated"
        );

        const breakout: { locationId: string; locationName: string; amount: number; status: string }[] = [];
        const decidedBreakout: { locationId: string; locationName: string; amount: number; status: string }[] = [];
        if (firstFundingEligibleLocations.length > 0) {
            const list = await getFirstFundingBreakoutForLocations(firstFundingEligibleLocations.map((x) => x.id));
            for (const l of list) {
                const found = firstFundingEligibleLocations.find((x) => x.id === l.locationId);
                if (found && l.outstandingAmount > 0) {
                    breakout.push({
                        locationId: l.locationId,
                        locationName: found.name,
                        amount: l.outstandingAmount,
                        status: "eligible",
                    });
                }
            }
        } else if (firstFundingDecidedLocations.length > 0) {
            for (const l of firstFundingDecidedLocations) {
                decidedBreakout.push({
                    locationId: l.id,
                    locationName: l.name,
                    amount: 0,
                    status: l.firstFundingEligible,
                });
            }
        }

        if (breakout.length > 0) {
            setFirstFundingBreakout(breakout);
            setCanShowFirstFundingSelfServeCard(true);
            setIsFirstFundingModalReadOnly(false);
            showFirstFundingSelfServeModal();
        } else if (decidedBreakout.length > 0) {
            setFirstFundingBreakout(decidedBreakout);
            setCanShowFirstFundingSelfServeCard(true);
            setIsFirstFundingModalReadOnly(true);
        } else {
            setCanShowFirstFundingSelfServeCard(false);
        }
    };

    useAsyncEffect(async () => {
        if (!accountContext.account || !locations) {
            return;
        }
        setAccountDailyDetails(undefined);
        setLocationDailyDetails(undefined);

        if (!period) {
            return;
        }

        const dateStr = period ?? DateTime.local().toFormat(monthFormat);
        const details: MerchantDailyDetail[] = await getMerchantDailyDetail(accountContext.account.id, dateStr);
        // update the list of locations used in the drop down to only include active locations or inactive locations with data
        const locationsToShow = locations.filter((l: LocationRecord) => {
            return l.active || details.some((detail) => detail.locationId === l.id);
        });
        setFilteredLocations(orderBy(locationsToShow, "name"));
        setAccountDailyDetails(details);
        setLocationDailyDetails(getDetailsForLocation(details, location?.id ?? "all"));
    }, [locations, period, accountContext.account]);

    useEffect(() => {
        if (accountDailyDetails) {
            setLocationDailyDetails(getDetailsForLocation(accountDailyDetails, location?.id ?? "all"));
        }
    }, [location, accountDailyDetails]);

    const onSetPeriod = (per?: string) =>
        navigate(`${url}/${location ? location.id : "*"}/${per ?? DateTime.local().toFormat(monthFormat)}`);
    const onSetLocation = (loc: LocationRecord | undefined) => navigate(`${url}/${loc ? loc.id : "*"}/${period}`);

    const getNetAmount = (field: "sales" | "salesTax" | "totalSetAsideAmount") => {
        if (!locationDailyDetails) {
            return "--";
        }
        return `$${moneyFromCents(sumBy(locationDailyDetails, field))}`;
    };

    if (!accountContext.account || !locations) {
        return null;
    }

    return (
        <>
            <Grid container spacing={spacing} style={{ minWidth: "100%", width: "100%" }}>
                <Grid item container spacing={spacing} xs={12} md={8} alignContent="flex-start">
                    <Grid
                        item
                        xs={12}
                        style={{
                            color: "#778ca2",
                            fontSize: "20px",
                            paddingLeft: "16px",
                            paddingBottom: "0px",
                            paddingTop: "0px",
                            lineHeight: "22px",
                        }}>
                        <Typography variant="h6">
                            <LocationSelector
                                accountId={accountContext.account.id}
                                locations={locations}
                                onLocationChange={onSetLocation}
                                location={location}
                                locationFilter={(l: LocationRecord) => {
                                    return !!(
                                        l.active || accountDailyDetails?.some((detail) => detail.locationId === l.id)
                                    );
                                }}
                                forceRefilter={accountDailyDetails}
                                onSetLocationsFiltered={setFilteredLocations}
                            />
                            <Select<string>
                                value={period}
                                onChange={onSetPeriod}
                                options={selectableMonths}
                                label={monthYear}
                                showFullWidth={false}
                                input={<SubtleSelectInput />}
                            />
                            {hasInactiveLocations && (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={showInactiveLocations}
                                                onClick={() => setShowInactiveLocations(!showInactiveLocations)}
                                            />
                                        }
                                        label="Include Inactive Locations"
                                    />
                                </div>
                            )}
                            {!isEmpty(filteredLocations.filter((l) => !l.active)) && (
                                <div style={{ fontSize: "12px" }}>
                                    Data included for inactive locations. This gives you a more complete picture of
                                    sales/tax for this period.
                                </div>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MerchantCard
                            title={getNetAmount("sales")}
                            subheader={renderExplanationModal("Net Sales", setShowSalesModal)}
                            icon="work_outline"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MerchantCard
                            title={getNetAmount("salesTax")}
                            subheader="Sales Tax"
                            icon="playlist_add_check"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MerchantCard
                            title={getNetAmount("totalSetAsideAmount")}
                            subheader={renderExplanationModal("Set Aside", setShowSetAsideModal)}
                            icon="playlist_add_check"
                        />
                    </Grid>
                    {canShowFirstFundingSelfServeCard && (
                        <Grid item xs={12}>
                            <Paper style={{ padding: "24px", boxShadow: "none" }}>
                                {!isNil(locationDailyDetails) && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ width: "100%", marginTop: "45px" }}
                                        onClick={() => {
                                            setIsFirstFundingModalReadOnly(true);
                                            showFirstFundingSelfServeModal();
                                        }}>
                                        View First Filing Funding Choices
                                    </Button>
                                )}
                                {isNil(locationDailyDetails) && <Loading />}
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Paper style={{ padding: "24px", boxShadow: "none" }}>
                            {!isNil(locationDailyDetails) && (
                                <TaxChart height={200} data={locationDailyDetails || []} />
                            )}
                            {isNil(locationDailyDetails) && <Loading />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{ padding: "24px", paddingBottom: "2px", boxShadow: "none" }}>
                            {!isNil(locationDailyDetails) && (
                                <TaxTable
                                    accountDailyDetails={accountDailyDetails ?? []}
                                    locationDailyDetails={locationDailyDetails ?? []}
                                    period={period ?? ""}
                                    includeAllLocations={!location?.id}
                                />
                            )}
                            {isNil(locationDailyDetails) && <Loading />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{ padding: "24px", paddingBottom: "2px", boxShadow: "none" }}>
                            <div style={{ fontSize: "20px", lineHeight: "24px", color: "#252631", marginTop: "10px" }}>
                                Have another location you need sales tax filed for?
                            </div>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: "100%", marginTop: "45px" }}
                                onClick={() => {
                                    window.open("https://www.davosalestax.com/integrations/", "_blank");
                                }}>
                                GET STARTED
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} style={{ paddingTop: "0px" }}>
                    <Paper style={{ marginTop: "20px", padding: "27px", boxShadow: "none" }}>
                        <div style={{ fontSize: "18px", lineHeight: "22px", marginTop: "20px" }}>
                            Next Filing
                            <Tooltip
                                title="This is the due date of your upcoming filing submission. Make sure to check the status of your tax credentials."
                                placement="top">
                                <HelpTwoToneIcon
                                    fontSize="small"
                                    color="primary"
                                    style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                />
                            </Tooltip>
                        </div>
                        <div style={{ fontSize: "14px", lineHeight: "21px", color: "#778CA2" }}>{nextDueDate}</div>
                        <div style={{ fontSize: "18px", lineHeight: "28px", marginTop: "10px" }}>
                            Period
                            <Tooltip
                                title="This is the date range that DAVO will be submitting tax collections and data for."
                                placement="top">
                                <HelpTwoToneIcon
                                    fontSize="small"
                                    color="primary"
                                    style={{ verticalAlign: "middle", marginLeft: "10px" }}
                                />
                            </Tooltip>
                        </div>
                        <div style={{ fontSize: "14px", lineHeight: "21px", color: "#778CA2" }}>{taxFilingPeriod}</div>

                        <div style={{ fontSize: "18px", lineHeight: "22px", marginTop: "20px" }}>Connection Status</div>
                        <div
                            style={{
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#778CA2",
                                paddingTop: "10px",
                            }}>
                            <MerchantConnectionStatus />
                        </div>
                    </Paper>
                    <Paper style={{ padding: "27px", boxShadow: "none", marginTop: "24px" }}>
                        <div className={classes.helperTile}>
                            <div className={classes.helperTileNeedHelpBox}>
                                <div className={classes.helperTileNeedHelpTextBox}>
                                    <div className={classes.helperTileNeedHelpTitleBox}>
                                        <a
                                            aria-hidden={true}
                                            tabIndex={-1}
                                            className={classes.helperTileNeedHelpLink}
                                            href="#hs-chat-open">
                                            <img
                                                alt={"chat icon"}
                                                src={hubspotChat}
                                                className={classes.helperTileNeedHelpIcon}
                                            />
                                        </a>
                                        <p className={classes.helperTileNeedHelpTextHeader}>Need Help?</p>
                                    </div>

                                    <p className={classes.helperTileNeedHelpTextBody}>
                                        Most Merchants find that the
                                        <a
                                            href="https://help.davosalestax.com/knowledge-base/kb-search-results?term=bank"
                                            target="_blank"
                                            style={{ padding: "6px" }}
                                            rel="noreferrer">
                                            help center
                                        </a>{" "}
                                        answers their common questions.
                                    </p>
                                </div>
                            </div>

                            <div className={classes.helperTileSomethingElseBox}>
                                <p className={classes.helperTileSomethingElseHeader}>Need Something Else?</p>

                                <div className={classes.helperTileSomethingElseVerticalButtonBox}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.helperTileSomethingElseButton3}
                                        href="#hs-chat-open">
                                        Chat With Us
                                    </Button>
                                    <div className={classes.helperTileHorizontalButtonBox}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.helperTileSomethingElseButton1}
                                            href="mailto:support@davosalestax.com?subject=DAVO Feedback">
                                            Email Us
                                        </Button>
                                        <Button
                                            href="https://help.davosalestax.com/meetings/customersuccess-/scheduled-call"
                                            target="_blank"
                                            variant="outlined"
                                            color="primary"
                                            rel="noreferrer"
                                            className={classes.helperTileSomethingElseButton2}>
                                            Schedule A Call
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper style={{ padding: "27px", boxShadow: "none", marginTop: "24px" }}>
                        <div style={{ fontSize: "20px", lineHeight: "24px", color: "#252631", marginTop: "10px" }}>
                            Do you love DAVO?
                        </div>
                        <div
                            style={{
                                color: "#252631",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                                marginTop: "10px",
                            }}>
                            Now you can earn a $50 Amazon gift card when you refer a friend and they sign up for DAVO.
                        </div>
                        <LinkButton
                            style={{ width: "100%", marginTop: "45px" }}
                            url={`/${accountContext.account.id}/settings/referral`}
                            label="REFER A FRIEND"
                        />
                        <div style={{ textAlign: "center", margin: "10px 0px 0px 0px" }}>
                            <img src={illustrationsFriend} alt="promotion" width="100%" />
                        </div>
                    </Paper>
                </Grid>
                {showSalesModal && (
                    <Dialog
                        open={true}
                        onClose={() => setShowSalesModal(false)}
                        aria-labelledby="responsive-dialog-title"
                        style={{ boxShadow: "none", padding: "10px" }}>
                        <DialogTitle id="responsive-dialog-title">Portal Sales Amounts</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This sales data reflects orders DAVO sees and may differ from the amounts on your daily
                                POS breakout due to timing differences in the acquisition of orders, discounts, service
                                charges, refunds, as well as third party sales (DoorDash, Uber Eats, etc.). Also, if you
                                add sales data in the &quot;Additional Info&quot; page, that will be included here.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowSalesModal(false)} variant="outlined" color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {canShowSetAsideModal && (
                    <Dialog
                        open={true}
                        onClose={() => setShowSetAsideModal(false)}
                        aria-labelledby="responsive-dialog-title"
                        style={{ boxShadow: "none", padding: "10px" }}>
                        <DialogTitle id="responsive-dialog-title">Set Aside Totals</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                The set aside and sales tax total data might differ due to timing between systems, paid
                                vs. closed transactions, modifications in POS data, rejected attempts to collect funds,
                                or adjustments. Set aside funds are held in a secure tax holding account.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowSetAsideModal(false)} variant="outlined" color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Grid>
            {firstFundingSelfServeModalProps.isDialogOpen && (
                <FirstFundingSelfServeModal
                    {...firstFundingSelfServeModalProps}
                    firstFundingBreakout={firstFundingBreakout}
                    isReadOnly={isFirstFundingModalReadOnly}
                />
            )}
        </>
    );
};
