import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useAccountContext } from "../context";
import { AddBankAccountForm } from "./AddBankAccountForm";

export function AddBankAccountModal({
    showTermsAndConditions,
    closeDialog,
    showSuccessToast,
}: {
    showTermsAndConditions: boolean;
    closeDialog: (target?: string) => void;
    showSuccessToast: boolean;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const accountContext = useAccountContext();

    if (!accountContext.account) {
        return null;
    }

    return (
        <Dialog
            data-testid={"addBankAccountModal"}
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            style={{ boxShadow: "none", padding: "10px" }}
            onClose={() => closeDialog()}>
            <DialogTitle id="responsive-dialog-title">Connect Your Bank Account</DialogTitle>
            <DialogContent>
                <AddBankAccountForm
                    accountId={accountContext.account.id}
                    onComplete={(bankAccountId: string) => {
                        closeDialog(bankAccountId);
                    }}
                    onCancel={closeDialog}
                    showCancelButton={true}
                    submitButtonLabel={"Connect"}
                    showHeader={false}
                    showTermsAndConditions={showTermsAndConditions}
                    showSuccessToast={showSuccessToast}
                />
            </DialogContent>
        </Dialog>
    );
}
