import { Header, POSLogo } from "@davo/portal-common";
import { Location } from "@davo/types";
import React from "react";

interface ISettingsSingleLocationHeaderProps {
    location?: Location;
}
export const SettingsSingleLocationHeader: React.FC<ISettingsSingleLocationHeaderProps> = ({ location }) => {
    if (!location) {
        return null;
    }
    return (
        <Header>
            <span style={{ display: "inline", fontSize: "20px", marginBottom: "16px" }}>Location Details</span>
            <span style={{ margin: "0 14px" }}>|</span>
            {`${location.name} ${location.active ? "" : " - Inactive"}`}
            <POSLogo posType={location.posType} width={125} shouldFloatRight={true} />
        </Header>
    );
};
