import { BankAccount } from "@davo/types";
import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useAccountContext } from "../context";
import { formatBankLabel } from "../util";
import { EditBankNicknameModalButton } from "./EditBankNicknameModalButton";

export interface IRenderBankName {
    bankAccount: BankAccount;
}

export const RenderBankName: FunctionComponent<IRenderBankName> = ({ bankAccount }) => {
    const { refresh } = useAccountContext();

    return (
        <div style={{ display: "inline-flex", marginTop: "5px", whiteSpace: "nowrap" }}>
            <Typography
                title={formatBankLabel({ ba: bankAccount, withStarredPrefix: false })}
                style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "336px" }}>
                {formatBankLabel({ ba: bankAccount, withStarredPrefix: false })}
            </Typography>
            <div style={{ marginTop: "-10px" }}>
                <EditBankNicknameModalButton
                    bankAccountId={bankAccount.id}
                    oldNickname={bankAccount.nickname ?? ""}
                    onCloseSuccess={refresh}
                />
            </div>
        </div>
    );
};
