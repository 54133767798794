import {
    addNotice,
    addNoticeFile,
    d30Toast,
    d30ToastError,
    getFilingPeriodOptionsForTaxProfile,
    getTaxProfiles,
    Loading,
    NumberField,
    PaperComponent,
    requestFVSInit,
    Select,
    TextField,
    validateNonNullAndPositive,
} from "@davo/portal-common";
import {
    NoticeType,
    NoticeTypeKeys,
    NoticeTypes,
    TaxPeriod,
    TaxProfile,
    TaxProfileRecord,
    toDisplayDateString,
    toPennies,
    truncateString,
} from "@davo/types";
import Block from "@mui/icons-material/Block";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUpload";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import useAsyncEffect from "use-async-effect";
import { v4 as uuid } from "uuid";

export function AddNoticeModal({
    accountId,
    isDisabled,
    refresh,
}: {
    accountId: string;
    isDisabled: boolean;
    refresh: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [taxProfileOptions, setTaxProfileOptions] = useState<TaxProfileRecord[]>();
    const [taxProfile, setTaxProfile] = useState<TaxProfileRecord>();
    const [taxPeriodOptions, setTaxPeriodOptions] = useState<TaxPeriod[]>();
    const [taxPeriod, setTaxPeriod] = useState<TaxPeriod>();
    const [type, setType] = useState<NoticeType | undefined>();
    const [taxDue, setTaxDue] = useState<number>();
    const [penalty, setPenalty] = useState<number>();
    const [interest, setInterest] = useState<number>();
    const [noteFromMerchant, setNoteFromMerchant] = useState<string>();
    const [heldFile, setHeldFile] = useState<File | undefined>(undefined);
    const [mode, setMode] = useState<"upload" | "form">("upload");

    const [taxProfileValidationResults, setTaxProfileValidationResults] = useState<string>();
    const [periodValidationResults, setPeriodValidationResults] = useState<string>();
    const [typeValidationResults, setTypeValidationResults] = useState<string>();
    const [taxDueValidationResults, setTaxDueValidationResults] = useState<string>();
    const [penaltyValidationResults, setPenaltyValidationResults] = useState<string>();
    const [interestValidationResults, setInterestValidationResults] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const counterRef = useRef(0);
    const formRef = useRef<HTMLFormElement>();

    const maxSize = 25 * 1024 * 1024; // 25 MB
    const acceptedFileTypes = { "application/pdf": [".pdf"] };

    const invalidFormLabel = "Please correct invalid fields";
    const taxProfileLabel = "Tax Profile";
    const periodLabel = "Filing Period";
    const typeLabel = "Notice Type";
    const taxDueLabel = "Tax Due";
    const penaltiesLabel = "Penalties";
    const interestLabel = "Interest";
    const notesLabel = "Anything else we should know?";

    useAsyncEffect(async () => {
        const tps: TaxProfile[] = await getTaxProfiles(accountId);
        const records: TaxProfileRecord[] = [];
        for (const x of tps) {
            records.push({ ...x, accountId });
        }
        setTaxProfileOptions(records);
    }, [accountId]);

    useAsyncEffect(async () => {
        if (!taxProfile) {
            return;
        }
        setTaxPeriodOptions(await getFilingPeriodOptionsForTaxProfile(accountId, taxProfile.id));
    }, [taxProfile]);

    useEffect(() => {
        const failedFields: string[] = [];

        const taxProfileValidation = taxProfile ? undefined : "Select a tax profile";
        const periodValidation = taxPeriod ? undefined : "Select a period the notice applies to";
        const typeValidation = type ? undefined : "Select a notice type";
        const taxDueValidation = validateNonNullAndPositive(taxDue);
        const penaltyValidation = validateNonNullAndPositive(penalty);
        const interestValidation = validateNonNullAndPositive(interest);

        taxProfileValidation && failedFields.push("Tax Profile");
        periodValidation && failedFields.push("Period");
        typeValidation && failedFields.push("Type");
        taxDueValidation && failedFields.push("Tax Due");
        penaltyValidation && failedFields.push("Penalty");
        interestValidation && failedFields.push("Interest");

        setTaxProfileValidationResults(taxProfileValidation);
        setPeriodValidationResults(periodValidation);
        setTypeValidationResults(typeValidation);
        setTaxDueValidationResults(taxDueValidation);
        setPenaltyValidationResults(penaltyValidation);
        setInterestValidationResults(interestValidation);

        setInvalidFields(failedFields);

        const isValid = !!(
            !taxDueValidation &&
            !penaltyValidation &&
            !interestValidation &&
            type &&
            taxProfile &&
            taxPeriod
        );

        setIsFormValid(isValid);
        if (isValid) {
            setMessage("");
        } else {
            setMessage(invalidFormLabel);
        }
    }, [taxDue, interest, penalty, type, taxProfile, taxPeriod]);

    useEffect(() => {
        if (taxProfileOptions?.length === 1) {
            setTaxProfile(taxProfileOptions[0]);
        }
    }, [isShowing, taxProfileOptions]);

    useEffect(() => {
        if (mode === "upload") {
            reset();
        }
    }, [mode]);

    const reset = () => {
        setMessage("");
        counterRef.current = 0;
        setPenalty(undefined);
        setType(undefined);
        setTaxDue(undefined);
        setInterest(undefined);
        setTaxProfile(undefined);
        setTaxPeriodOptions(undefined);
        setHeldFile(undefined);
        setNoteFromMerchant(undefined);
        setTaxPeriod(undefined);
        setTaxPeriodOptions(undefined);
    };
    const show = () => {
        setMode("upload");
        setIsShowing(true);
        setIsBusy(false);
    };

    const doProcess = (event?: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event?.preventDefault();
        event?.stopPropagation();
        counterRef.current = counterRef.current + 1;
        if (!isFormValid) {
            setMessage(invalidFormLabel);
            formRef.current?.reportValidity();
            return;
        }
        setIsShowing(false);

        // this is checked in isFormValid, so ! should be fine
        uploadFile(heldFile!);
    };

    const holdFilePendingForm = useCallback((file: File) => {
        setHeldFile(file);
        setMode("form");
    }, []);

    const recordNoticeRecord = (filename: string, validationId: string) => {
        if (!isNil(validationId)) {
            addNoticeFile(filename, validationId)
                .then((f) => {
                    addNotice(
                        taxProfile!.id,
                        f.fileId,
                        taxPeriod!.start,
                        taxPeriod!.end,
                        taxDue,
                        penalty,
                        interest,
                        type,
                        noteFromMerchant
                    )
                        .then(() => {
                            d30Toast(`Notice added.`);
                            refresh();
                        })
                        .catch((e: any) => d30ToastError(e.message, e));
                })
                .catch((e: any) => d30ToastError(e.message, e))
                .finally(() => setIsBusy(false));
        }
        setIsBusy(false);
    };

    const uploadFile = (file: File) => {
        setIsBusy(true);
        let validationId: string;
        const correlationId = uuid();
        requestFVSInit(file.name, correlationId)
            .then(async (fvs) => {
                if (!fvs) throw new Error("Could not validate file, please try again.");
                const url = fvs.url;
                const fields = fvs.fields;
                validationId = fvs.validationId;
                const formData = new FormData();
                for (const x of Object.keys(fields)) {
                    formData.append(x, fields[x]);
                }
                formData.append("file", file);
                // send file to FVS here, this should 204
                await fetch(url, {
                    body: formData,
                    method: "POST",
                });

                d30Toast("File uploading: " + file.name);
            })
            .catch((e) => {
                setMode("upload");
                d30ToastError("Error uploading: " + file.name, e);
            })
            .finally(() => recordNoticeRecord(file.name, validationId));
    };

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (Array.isArray(acceptedFiles)) {
                if (acceptedFiles.length > 1 || acceptedFiles.length < 1) {
                    d30ToastError("Cannot accept multiple files at once, please try again.");
                } else {
                    holdFilePendingForm(acceptedFiles[0]);
                }
            }
        },
        [holdFilePendingForm]
    );

    if (!taxProfileOptions || taxProfileOptions.length < 1) {
        return null;
    }

    return (
        <>
            <Button
                variant="contained"
                disabled={isDisabled}
                onClick={show}
                size="small"
                style={{
                    padding: "7px 9px",
                    margin: "5px",
                }}
                color="primary">
                Add New Notice
            </Button>
            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                        Add Notice...
                    </DialogTitle>
                    <div style={{ padding: "20px" }}>
                        <Typography variant={"body1"}>
                            {mode === "upload"
                                ? "Please provide a pdf of the notice you received"
                                : "Tell us more about the notice"}
                        </Typography>
                        <br />
                        {isBusy && (
                            <>
                                <Loading />
                            </>
                        )}
                        {!isBusy && mode === "upload" && (
                            <>
                                <>
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                        }}>
                                        <Dropzone
                                            onDrop={onDrop}
                                            maxSize={maxSize}
                                            maxFiles={10}
                                            accept={acceptedFileTypes}
                                            onDropRejected={(files: FileRejection[]) => {
                                                const fileNames = files.map((f) => f.file.name).join(", ");
                                                d30ToastError(
                                                    `Unable to upload ${fileNames}. Ensure it's a PDF file and less than 25 MB.`
                                                );
                                            }}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <section>
                                                    <div
                                                        style={{
                                                            borderRadius: "50%",
                                                            border: "2px dashed blue",
                                                            width: "150px",
                                                            height: "150px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            textAlign: "center",
                                                        }}
                                                        {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>
                                                            {isDragActive
                                                                ? "Drop now!"
                                                                : "Drag 'n' drop pdf file here, or click to select it"}
                                                        </p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </>
                            </>
                        )}
                        {!isBusy && mode === "form" && (
                            <Box component={"form"} noValidate onSubmit={doProcess} id={"formBox"} ref={formRef}>
                                <div style={{ alignItems: "center" }}>
                                    {heldFile && (
                                        <div key={heldFile.name}>
                                            <CloudUploadTwoToneIcon
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                            {truncateString(heldFile.name, 30)}
                                        </div>
                                    )}
                                </div>
                                <Select<TaxProfileRecord>
                                    value={taxProfile}
                                    onChange={setTaxProfile}
                                    options={taxProfileOptions}
                                    label={(l) => l.name}
                                    showFullWidth={true}
                                    title={taxProfileLabel}
                                    validate={() => taxProfileValidationResults}
                                    isRequired={true}
                                />
                                <Select<TaxPeriod>
                                    value={taxPeriod}
                                    onChange={setTaxPeriod}
                                    options={taxPeriodOptions ?? []}
                                    label={(p) => `${toDisplayDateString(p.start)} - ${toDisplayDateString(p.end)}`}
                                    showFullWidth={true}
                                    title={periodLabel}
                                    isDisabled={(taxPeriodOptions ?? []).length < 1}
                                    validate={() => periodValidationResults}
                                    isRequired={true}
                                />
                                <Select<NoticeType>
                                    title={typeLabel}
                                    value={type}
                                    onChange={setType}
                                    options={NoticeTypeKeys}
                                    label={(typeId: NoticeType) => NoticeTypes[typeId]}
                                    validate={() => typeValidationResults}
                                    isRequired={true}
                                />
                                <Typography>
                                    These fields should be filled out as they appear on your notice.
                                </Typography>
                                <Typography>
                                    If they do not appear and/or are not applicable, leave them as zero.
                                </Typography>
                                <NumberField
                                    label={taxDueLabel}
                                    value={taxDue}
                                    onChange={(x) => setTaxDue(toPennies(x))}
                                    validate={() => taxDueValidationResults}
                                    isRequired={true}
                                />
                                <NumberField
                                    label={penaltiesLabel}
                                    value={penalty}
                                    onChange={(x) => setPenalty(toPennies(x))}
                                    validate={() => penaltyValidationResults}
                                    isRequired={true}
                                />
                                <NumberField
                                    label={interestLabel}
                                    value={interest}
                                    onChange={(x) => setInterest(toPennies(x))}
                                    validate={() => interestValidationResults}
                                    isRequired={true}
                                />
                                <TextField
                                    label={notesLabel}
                                    value={noteFromMerchant ?? ""}
                                    onChange={setNoteFromMerchant}
                                    isMultiline={true}
                                    rows={2}
                                />
                                {message && counterRef.current !== 0 && (
                                    <Alert
                                        data-testid={"validationAlertContainer"}
                                        icon={<Block />}
                                        color={"error"}
                                        style={{ marginTop: "6px" }}>
                                        <AlertTitle>{message}</AlertTitle>
                                        {Object.values(invalidFields)
                                            .map((fieldLabel) => fieldLabel)
                                            .join(", ")}
                                    </Alert>
                                )}
                            </Box>
                        )}
                    </div>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        {mode === "form" && (
                            <>
                                <Button variant="outlined" color="primary" onClick={() => setMode("upload")}>
                                    Previous
                                </Button>

                                <Button variant="contained" color="primary" type={"submit"} form={"formBox"}>
                                    Submit
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
