import { Paper, Typography } from "@mui/material";
import React from "react";

import isEmpty from "lodash/isEmpty";
import { AccountFilingsTable, MerchantFilingsFilter } from "./components";
import { MerchantFilingsFilterResultsContextProvider, useMerchantFilingFilterResultsContext } from "./context";

export function Filings() {
    const { filters, filingFilterResults, historicalFilingResults } = useMerchantFilingFilterResultsContext();

    return (
        <MerchantFilingsFilterResultsContextProvider>
            <>
                <div style={{ marginBottom: "20px" }}>
                    <MerchantFilingsFilter />
                </div>
                {filters && isEmpty(filingFilterResults) && isEmpty(historicalFilingResults) && (
                    <div>
                        <Typography style={{ marginBottom: "20px" }} variant="body1" data-testid={"no-results"}>
                            No Filings Matching Search Criteria
                        </Typography>
                    </div>
                )}
                <Paper style={{ padding: "24px", paddingBottom: "0px", boxShadow: "none" }}>
                    <AccountFilingsTable />
                </Paper>
            </>
        </MerchantFilingsFilterResultsContextProvider>
    );
}
