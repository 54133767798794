import { ReactTable8 } from "@davo/portal-common";
import { MerchantAccountHistory, toDisplayDateTimeString } from "@davo/types";
import { Paper, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";
import { getMerchantAccountHistory } from "./services/account";

const useStyles = makeStyles(() => ({
    reactTable: {
        display: "flex",
        width: "100%",
        flexFlow: "column nowrap",
    },
    historyContainer: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
    },
}));

const formatMerchantHistoryDate = (data: CellContext<MerchantAccountHistory, any>) => {
    return <>{toDisplayDateTimeString(data.row.original.created!)}</>;
};
const formatMerchantHistoryLocationName = (data: CellContext<MerchantAccountHistory, any>) => {
    return <>{data.row.original.location}</>;
};
const formatMerchantHistoryEvent = (data: CellContext<MerchantAccountHistory, any>) => {
    return <>{data.row.original.event}</>;
};
const formatMerchantHistoryEventDescription = (data: CellContext<MerchantAccountHistory, any>) => {
    return <>{data.row.original.eventDescription}</>;
};
const formatMerchantHistoryEventUpdatedBy = (data: CellContext<MerchantAccountHistory, any>) => {
    return <>{data.row.original.updatedBy}</>;
};

export const History = () => {
    const classes = useStyles();
    const accountContext = useAccountContext();

    const [displayUserEvents, setDisplayUserEvents] = useState<boolean>(true);
    const [displayTaxProfileEvents, setDisplayTaxProfileEvents] = useState<boolean>(true);
    const [displayBankAccountEvents, setDisplayBankAccountEvents] = useState<boolean>(true);
    const [displayLocationEvents, setDisplayLocationEvents] = useState<boolean>(true);
    const [displayFundingEvents, setDisplayFundingEvents] = useState<boolean>(true);

    const [merchantHistory, setMerchantHistory] = useState<MerchantAccountHistory[]>([]);
    const [filteredMerchantHistory, setFilteredMerchantHistory] = useState<MerchantAccountHistory[]>([]);

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        setMerchantHistory(accountContext.account.id ? await getMerchantAccountHistory(accountContext.account.id) : []);
    }, [accountContext.account]);

    useEffect(() => {
        let filteredHistory: MerchantAccountHistory[] = [];
        if (displayUserEvents) {
            filteredHistory = filteredHistory.concat(merchantHistory.filter((l) => l.eventType === "User"));
        }
        if (displayTaxProfileEvents) {
            filteredHistory = filteredHistory.concat(merchantHistory.filter((l) => l.eventType === "Tax Profile"));
        }
        if (displayBankAccountEvents) {
            filteredHistory = filteredHistory.concat(merchantHistory.filter((l) => l.eventType === "Bank Account"));
        }
        if (displayLocationEvents) {
            filteredHistory = filteredHistory.concat(merchantHistory.filter((l) => l.eventType === "Location"));
        }
        if (displayFundingEvents) {
            filteredHistory = filteredHistory.concat(merchantHistory.filter((l) => l.eventType === "Funding"));
        }

        if (merchantHistory.length) {
            const sortedHistory = filteredHistory
                .sort((a, b) => {
                    let result = 0;
                    if (a.created && b.created) {
                        if (a.created.toMillis() < b.created.toMillis()) {
                            result = -1;
                        } else if (a.created.toMillis() > b.created.toMillis()) {
                            result = 1;
                        }
                    }
                    return result;
                })
                .reverse();
            setFilteredMerchantHistory(sortedHistory);
        }
    }, [
        merchantHistory,
        displayBankAccountEvents,
        displayTaxProfileEvents,
        displayUserEvents,
        displayLocationEvents,
        displayFundingEvents,
    ]);

    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<MerchantAccountHistory>();

        return [
            columnHelper.accessor("created", {
                header: "Date",
                cell: formatMerchantHistoryDate,
            }),
            columnHelper.accessor("location", {
                header: "Location",
                cell: formatMerchantHistoryLocationName,
            }),
            columnHelper.accessor("event", {
                header: "Event",
                cell: formatMerchantHistoryEvent,
            }),
            columnHelper.accessor("eventDescription", {
                header: "Description",
                cell: formatMerchantHistoryEventDescription,
            }),
            columnHelper.accessor("updatedBy", {
                header: "Updated By",
                cell: formatMerchantHistoryEventUpdatedBy,
            }),
        ];
    }, []);

    if (!accountContext.account || !history) {
        return null;
    }

    return (
        <Paper style={{ boxShadow: "none", padding: "30px" }} data-testid="history-tab">
            <Typography variant={"h2"} style={{ display: "inline-block", fontSize: "20px" }}>
                Account History
            </Typography>
            <div className={classes.historyContainer}>
                <div className={classes.reactTable}>
                    <p>Filter Events</p>
                    <span>
                        <span>&nbsp;&nbsp;Users</span>
                        <Switch
                            checked={displayUserEvents}
                            onChange={() => {
                                setDisplayUserEvents(!displayUserEvents);
                            }}
                        />
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;Tax Profile</span>
                        <Switch
                            checked={displayTaxProfileEvents}
                            onChange={() => {
                                setDisplayTaxProfileEvents(!displayTaxProfileEvents);
                            }}
                        />
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;Bank Account</span>
                        <Switch
                            checked={displayBankAccountEvents}
                            onChange={() => {
                                setDisplayBankAccountEvents(!displayBankAccountEvents);
                            }}
                        />
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;Location</span>
                        <Switch
                            checked={displayLocationEvents}
                            onChange={() => {
                                setDisplayLocationEvents(!displayLocationEvents);
                            }}
                        />
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;Funding</span>
                        <Switch
                            checked={displayFundingEvents}
                            onChange={() => {
                                setDisplayFundingEvents(!displayFundingEvents);
                            }}
                        />
                    </span>
                    <ReactTable8<MerchantAccountHistory>
                        columns={columns as ColumnDef<MerchantAccountHistory>[]}
                        data={filteredMerchantHistory}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};
