import { HiddenConfirmation, useLoginContext, useModalEditor } from "@davo/portal-common";
import { BankAccount } from "@davo/types";
import { Alert, FormControlLabel, FormGroup, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ActiveBankAccountsTable, AddBankButton, BankAccountInfoPane, UnusedBankAccountsTable } from "./components";
import { useAccountContext } from "./context";
import { SettingsBankingEmptyState } from "./SettingsBankingEmptyState";

export function Banking() {
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [activeBankAccounts, setActiveBankAccounts] = useState<BankAccount[]>([]);
    const [unusedBankAccounts, setUnusedBankAccounts] = useState<BankAccount[]>([]);
    const [shouldRestrictMerchantEdit, setShouldRestrictMerchantEdit] = useState<boolean>(false);
    const [restrictMerchantEditMessage, setRestrictMerchantEditMessage] = useState<string>("");
    const [showUnused, setShowUnused] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);

    const [showBankAccountInfoPaneModal, bankAccountInfoPaneModalProps] = useModalEditor<string>(() =>
        accountContext.refresh()
    );
    const [showHiddenConfirmationModal, hiddenConfirmationModalProps] = useModalEditor<string>(() =>
        accountContext.refresh()
    );

    useEffect(() => {
        if (!activeBankAccounts.length && unusedBankAccounts.length) {
            setShowUnused(true);
        }
    }, [activeBankAccounts, unusedBankAccounts]);

    useEffect(() => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
            !accountContext.bankAccounts
        ) {
            return;
        }

        const active: BankAccount[] = [];
        const unused: BankAccount[] = [];

        accountContext.bankAccounts.forEach((acctBankAccount) => {
            accountContext.locations?.some((loc) => loc.bankAccountId === acctBankAccount.id && loc.active)
                ? active.push(acctBankAccount)
                : unused.push(acctBankAccount);
        });

        const unusedUnhidden = unused.filter((x) => !x.hidden);

        setActiveBankAccounts(active);
        setUnusedBankAccounts(unusedUnhidden);
    }, [accountContext.bankAccounts, accountContext.locations]);

    useEffect(() => {
        if (!accountContext.partner) {
            return;
        }
        if (accountContext.partner.restrictEditBankInfo) {
            setShouldRestrictMerchantEdit(true);
            setRestrictMerchantEditMessage("Our partner manages your banking information");
            if (loginContext.user && loginContext.user.type !== "Merchant") {
                setRestrictMerchantEditMessage("Merchant is restricted from editing this information");
                setShouldRestrictMerchantEdit(false);
            }
        }
    }, [accountContext.partner, loginContext.user]);

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
        !accountContext.bankAccounts
    ) {
        return null;
    }

    return (
        <div data-testid={"settings-banking"}>
            <>
                <Stack justifyContent={"space-between"} spacing={2} direction={"row"}>
                    <Typography
                        variant={"h2"}
                        style={{ display: "inline-block", fontSize: "20px", marginBottom: "16px" }}>
                        Banking Details
                    </Typography>

                    {accountContext.bankAccounts.filter((x) => !x.hidden).length !== 0 && (
                        <div>
                            <AddBankButton
                                isPrimary={!!accountContext.bankAccounts.filter((x) => !x.hidden).length}
                                showSuccessToast={true}
                            />
                        </div>
                    )}
                </Stack>

                {accountContext.bankAccounts.filter((x) => !x.hidden).length === 0 ? (
                    <SettingsBankingEmptyState
                        shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                        restrictMerchantEditMessage={restrictMerchantEditMessage}
                    />
                ) : (
                    <>
                        {activeBankAccounts.length > 0 ? (
                            <ActiveBankAccountsTable
                                activeBankAccounts={activeBankAccounts}
                                showBankAccountInfoPaneModal={showBankAccountInfoPaneModal}
                                restrictMerchantEditMessage={restrictMerchantEditMessage}
                                shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                            />
                        ) : (
                            <Stack direction={"row"}>
                                <Alert severity={"info"} sx={{ display: "inline-flex" }}>
                                    No active bank accounts
                                </Alert>
                            </Stack>
                        )}

                        {unusedBankAccounts.length !== 0 && (
                            <FormGroup
                                sx={{
                                    marginY: "24px",
                                }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            data-testid={"showUnusedBanksToggle"}
                                            checked={showUnused}
                                            onChange={() => setShowUnused(!showUnused)}
                                        />
                                    }
                                    label="Show unused bank accounts"
                                />
                            </FormGroup>
                        )}

                        {showUnused && unusedBankAccounts.length !== 0 && (
                            <UnusedBankAccountsTable
                                setSelected={setSelected}
                                unusedBankAccounts={unusedBankAccounts}
                                showHiddenConfirmationModal={showHiddenConfirmationModal}
                            />
                        )}
                    </>
                )}
            </>

            {bankAccountInfoPaneModalProps.isDialogOpen && (
                <BankAccountInfoPane accountId={accountContext.account.id} {...bankAccountInfoPaneModalProps} />
            )}
            {hiddenConfirmationModalProps.isDialogOpen && (
                <HiddenConfirmation
                    accountId={accountContext.account.id}
                    bankAccountId={selected}
                    label={"bank account"}
                    {...hiddenConfirmationModalProps}
                />
            )}
        </div>
    );
}
