import { BankAccount, LocationRecord } from "@davo/types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Button, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountContext } from "../context";
import { formatBankLabel } from "../util";
import { BankSummaryActions } from "./BankSummaryActions";

export interface IBankDetailsExpanded {
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage: string;
    bankAccount: BankAccount;
    associatedLocations: LocationRecord[];
}

export const BankDetailsExpanded: FunctionComponent<IBankDetailsExpanded> = ({
    shouldRestrictMerchantEdit,
    restrictMerchantEditMessage,
    bankAccount,
    associatedLocations,
}) => {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    const formattedBankName = formatBankLabel({
        ba: bankAccount,
        withStarredPrefix: false,
    });
    return (
        <div style={{ padding: "32px" }}>
            <Typography style={{ display: "inline-block", fontSize: "20px" }}>
                <Tooltip title={`Locations associated with ${formattedBankName}`} placement={"top"}>
                    <span>Locations associated with this bank account</span>
                </Tooltip>
            </Typography>

            <List sx={{ bgcolor: "background.paper" }} dense className={"da-row-expand-content"}>
                {associatedLocations.map((loc) => {
                    return (
                        <ListItem
                            sx={{
                                boxShadow: 1,
                                mb: "4px",
                            }}
                            key={loc.id}
                            data-testid={`associated-location-${loc.id}`}
                            secondaryAction={
                                <BankSummaryActions
                                    locationName={loc.name}
                                    locationId={loc.id}
                                    bankAccountName={formattedBankName}
                                    bankAccountId={bankAccount.id}
                                    shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                                    restrictMerchantEditMessage={restrictMerchantEditMessage}
                                />
                            }>
                            <ListItemAvatar>
                                <LocationOnIcon />
                            </ListItemAvatar>

                            <ListItemText
                                primary={
                                    <Tooltip title={`Manage ${loc.name}`} placement="top">
                                        <span>
                                            <Button
                                                data-testid={`manage-loc-${loc.id}-btn`}
                                                disabled={shouldRestrictMerchantEdit}
                                                variant={"text"}
                                                onClick={() =>
                                                    navigate(
                                                        `/${accountContext.account?.id}/settings/company/${loc.id}`
                                                    )
                                                }
                                                size="small">
                                                {loc.name}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                }
                                secondary={loc.address1}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};
