import { get, post } from "@davo/portal-common";
import { MerchantAccountHistory } from "@davo/types";

export async function getMerchantAccountHistory(accountId: string): Promise<MerchantAccountHistory[]> {
    return get<MerchantAccountHistory[]>(`api/common/account/history/${accountId}/`, true);
}

export const disconnectQuickbooksLocation = async (accountId: string, locationId: string) => {
    return post(`api/common/account/${accountId}/quickbooks/disconnect/${locationId}/`, { locationId }, true);
};
